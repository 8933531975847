import theme from '../../../../shared/styles/themes/default.theme'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    height: 'calc(100vh - 64px - 56px)',
    padding: theme.spacing(3)
  },

  offerItemContainer: {
    marginBottom: theme.spacing(2)
  },

  listContainerGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: theme.spacing(5),
    [theme.breakpoints.down(2000)]: {
      gridTemplateColumns: 'repeat(3, 1fr)'
    },
    [theme.breakpoints.down(1370)]: {
      gridTemplateColumns: 'repeat(2, 1fr)'
    },
    [theme.breakpoints.down(940)]: {
      gridTemplateColumns: 'repeat(1, 1fr)'
    }
  },

  rightContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(1, 1, 1, 1.5),
    borderRadius: theme.spacing(1)
  },

  rightContainerButton: {
    // marginLeft: theme.spacing(2)
  },

  rightContainerText: {
    paddingRight: theme.spacing(2)
  }
}

export default styles
