import { SxProps } from '@mui/material'

import theme from '../../../../../shared/styles/themes/default.theme'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
    borderRadius: theme.shape.borderRadius / 4,
    border: '1px solid transparent',
    backgroundColor: theme.palette.background.default,
    width: '100%',
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[5],
    cursor: 'pointer',
    '&:hover': {
      border: `1px solid ${theme.palette.secondary.dark}`
    }
  } as SxProps,

  cardTop: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  } as SxProps,

  details: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'space-between',
    width: '100%'
  } as SxProps,

  stopDurationContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1)
  } as SxProps,

  stopCount: {
    backgroundColor: '#E6F4EA',
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.shape.borderRadius / 10,
    marginRight: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.success.dark
  } as SxProps,

  stopText: {
    fontSize: '0.700rem',
    color: theme.palette.success.dark
  } as SxProps,

  duration: {
    color: theme.palette.text.secondary,
    fontWeight: 'bold',
    fontSize: '0.700rem'
  } as SxProps,

  airlineInfo: {
    display: 'flex',
    alignItems: 'center'
  } as SxProps,

  logo: {
    width: '40px',
    height: '40px',
    marginRight: theme.spacing(2)
  },

  airportInfo: {
    textAlign: 'left'
  } as SxProps,

  airportName: {
    color: theme.palette.text.primary
  } as SxProps,

  time: {
    color: '#5A5A5A',
    fontSize: '0.875rem',
    fontWeight: theme.typography.fontWeightMedium
  } as SxProps,

  info: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    marginRight: theme.spacing(2)
  } as SxProps,

  separator: {
    flex: 1,
    height: '1px',
    backgroundColor: theme.palette.divider,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(3.5)
  } as SxProps,

  bottomDivider: {
    width: '100%',
    backgroundColor: theme.palette.divider,
    marginTop: theme.spacing(3)
  } as SxProps,

  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2)
  } as SxProps,

  actionButton: {} as SxProps,

  verticalSeparator: {
    width: '1px',
    height: '80px',
    backgroundColor: theme.palette.divider,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  } as SxProps,

  departureContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  } as SxProps,

  arrivalContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  } as SxProps,

  priceInfo: {
    textAlign: 'left'
  } as SxProps,

  price: {
    fontSize: '1.5rem'
  } as SxProps,

  perPerson: {
    color: theme.palette.text.secondary,
    fontSize: '0.875rem',
    fontWeight: 400
  } as SxProps,

  cabinClass: {
    textAlign: 'left',
    color: theme.palette.grey[500]
  } as SxProps
}

export default styles
