import React from 'react'

import { Box, Divider, Typography } from '@mui/material'

import Button from '../../../../../shared/components/Button/Button.component'
import TripLocationsWidget from '../../../../../shared/components/TripLocationsWidget/TripLocationsWidget.component'

import styles from './FlightOffer.styles'
import { FlightOfferProps } from './FlightOffer.types'

const FlightOffer: React.FC<FlightOfferProps> = (props) => {
  const {
    duration,
    stopCount,
    airlineLogo,
    originAirportName,
    destinationAirportName,
    departureTime,
    arrivalTime,
    cost,
    travelClass,
    onSelect
  } = props

  return (
    <Box sx={styles.container}>
      <Box sx={styles.cardTop}>
        <Box sx={styles.stopDurationContainer}>
          <Box sx={styles.stopCount}>
            <Typography variant="body2" sx={styles.stopText}>
              {stopCount === 0 ? 'Non Stop' : `${stopCount} Stop${stopCount > 1 ? 's' : ''}`}
            </Typography>
          </Box>

          <Typography variant="body2" sx={styles.duration}>
            {duration}
          </Typography>
        </Box>

        <TripLocationsWidget
          airlineLogo={airlineLogo}
          origin={{ airportIataCode: originAirportName }}
          destination={{ airportIataCode: destinationAirportName }}
          departureTime={departureTime}
          arrivalTime={arrivalTime}
        />
      </Box>

      <Divider sx={styles.bottomDivider} />

      <Box sx={styles.actionContainer}>
        <Box sx={styles.priceInfo}>
          <Typography variant="h6" sx={styles.price}>
            ${cost}
          </Typography>

          <Typography variant="body2" sx={styles.cabinClass}>
            {travelClass}
          </Typography>
        </Box>

        <Button text="Select" buttonType="primary" onClick={onSelect} />
      </Box>
    </Box>
  )
}

export default FlightOffer
