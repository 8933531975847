import React, { useCallback, useEffect, useState } from 'react'

import { Box, Typography } from '@mui/material'

import Button from '../../../../shared/components/Button/Button.component'
import PaginatedPage from '../../../../shared/components/PaginatedPage/PaginatedPage.component'
import OfferItem from '../../../Offers/components/OfferItem/OfferItem.component'

import { isAuthenticated } from '../../../../networkRequests/apiClient/apiClient.functions'
import { getAllFlightBookings } from '../../../../redux/states/flights/getAllFlightBookings/getAllFlightBookings.slice'
import {
  FlightBooking,
  GetAllFlightBookingsRequest
} from '../../../../redux/states/flights/getAllFlightBookings/getAllFlightBookings.types'
import { getPublicOffers } from '../../../../redux/states/offerRequest/getPublicOffers/getPublicOffers.slice'
import { OfferRequest } from '../../../../redux/states/offerRequest/getPublicOffers/getPublicOffers.types'
import {
  PaginatedRenderProps,
  PaginatedResponse
} from '../../../../shared/components/PaginatedPage/PaginatedPage.types'
import { useAppDispatch } from '../../../../shared/hooks/redux.hooks'
import { RequiredCountries } from '../../../Offers/components/OfferItem/OfferItem.types'
import styles from './OffersMultiSelect.styles'

interface OffersMultiSelectProps {
  onGotoTripSummary: (selectedOffers: OfferRequest[]) => void
  onFetchedOffers?: (offers: OfferRequest[]) => void
  requiredCountries: RequiredCountries[]
  initialSelectedOffers: OfferRequest[]
  maxDate?: string
}

const OffersMultiSelect: React.FC<OffersMultiSelectProps> = ({
  onGotoTripSummary,
  requiredCountries,
  initialSelectedOffers,
  onFetchedOffers,
  maxDate
}) => {
  const [selectedOffers, setSelectedOffers] = useState<OfferRequest[]>(initialSelectedOffers)
  const [flightBookings, setFlightBookings] = useState<FlightBooking[]>([])
  const dispatch = useAppDispatch()

  const fetchOffers = useCallback(
    async (page: number): Promise<PaginatedResponse<OfferRequest>> => {
      return new Promise((resolve) => {
        const getPublicOffersPayload = {
          page,
          size: 20,
          searchQuery: '',
          countries: requiredCountries.map((country) => country.iataCode),
          maxDate
        }

        dispatch(
          getPublicOffers({
            getPublicOffersRequest: getPublicOffersPayload,
            onSuccess: (response) => {
              onFetchedOffers?.(response.data.results)
              resolve({
                currentPage: response.data.currentPage,
                totalPages: response.data.totalPages,
                results: response.data.results
              })
            }
          })
        )
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, requiredCountries, maxDate]
  )

  useEffect(() => {
    const fetchFlightBookings = async () => {
      if (!isAuthenticated()) return
      const getAllFlightBookingsPayload: GetAllFlightBookingsRequest = {}

      const response = await dispatch(
        getAllFlightBookings({
          request: getAllFlightBookingsPayload
        })
      ).unwrap()

      setFlightBookings(response.data.results)
    }

    fetchFlightBookings()
    fetchOffers(1)
  }, [dispatch, fetchOffers])

  const handleSelectOffer = (offer: OfferRequest) => {
    setSelectedOffers((prev) => [...prev, offer])
  }

  const handleDeselectOffer = (offer: OfferRequest) => {
    setSelectedOffers((prev) => prev.filter((o) => o.offerRequestId !== offer.offerRequestId))
  }

  const handleGotoTripSummary = () => {
    onGotoTripSummary(selectedOffers)
  }

  const OfferItemsView = (props: PaginatedRenderProps) => {
    const offer = props.item as OfferRequest
    const isSelected = selectedOffers.some((o) => o.offerRequestId === offer.offerRequestId)

    return (
      <Box sx={styles.offerItemContainer}>
        <OfferItem
          offer={offer}
          onSelect={isSelected ? handleDeselectOffer : handleSelectOffer}
          isSelected={isSelected}
          flightBookings={flightBookings}
        />
      </Box>
    )
  }

  return (
    <Box sx={styles.container}>
      <PaginatedPage
        fetchCall={fetchOffers}
        renderListData={OfferItemsView}
        layout="grid"
        gridSx={styles.listContainerGrid}
        title={{
          text: 'Select additional offers below.',
          id: 'offers'
        }}
        subtitle="You can accept additional offers to the same destination."
        initialLoading={false}
        emptyState={{
          title: 'No offers found',
          subtitle: 'Try changing your search criteria.'
        }}
        rightContainer={
          <Box sx={styles.rightContainer}>
            <Typography sx={styles.rightContainerText} variant="body2">
              Total Selected: {selectedOffers.length}
            </Typography>

            <Button
              sx={styles.rightContainerButton}
              buttonType="primary"
              text="Continue to Trip Summary"
              onClick={handleGotoTripSummary}
            />
          </Box>
        }
      />
    </Box>
  )
}

export default OffersMultiSelect
