import theme from '../../styles/themes/default.theme'

export const styles = {
  stepsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },

  stepContent: (isLast: boolean) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: { xs: 'center', sm: 'center', md: 'flex-start' },
    width: '100%',
    paddingRight: {
      xs: theme.spacing(0),
      sm: isLast ? theme.spacing(0) : theme.spacing(4),
      md: isLast ? theme.spacing(0) : theme.spacing(4),
      lg: isLast ? theme.spacing(0) : theme.spacing(4)
    },
    paddingBottom: { xs: theme.spacing(4), sm: theme.spacing(4), md: theme.spacing(0) }
  }),

  step: {
    display: 'flex',
    flexDirection: 'row'
  },

  icon: {
    background: `linear-gradient(30deg, transparent 0%, #F2E2F9 100%)`,
    color: '#421785',
    borderRadius: '50%',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: '90px',
    height: '90px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '70px',
      height: '70px'
    },
    '& svg': {
      fontSize: '40px',
      width: '40px',
      height: '40px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '30px',
        width: '30px',
        height: '30px'
      }
    }
  },

  stepTitle: {
    marginBottom: theme.spacing(1),
    fontSize: '24px',
    fontWeight: 'bold',
    textAlign: { sm: 'center', md: 'left' },
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
      textAlign: 'center'
    }
  },

  stepDescription: {
    color: theme.palette.text.secondary,
    fontSize: '16px',
    textAlign: { sm: 'center', md: 'left' },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },

  rotatedIcon: {
    transform: 'rotate(-45deg)'
  }
}
