import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import {
  GetAllFlightBookingsErrorResponse,
  GetAllFlightBookingsRequest,
  GetAllFlightBookingsSuccessResponse
} from './getAllFlightBookings.types'

export const getAllFlightBookingsApi = async (
  request: GetAllFlightBookingsRequest
): Promise<GetAllFlightBookingsSuccessResponse> => {
  const { page, size } = request

  const queryParams = new URLSearchParams()
  if (page !== undefined) queryParams.append('page', page.toString())
  if (size !== undefined) queryParams.append('size', size.toString())

  const response = await configureRequest({
    url: `${urls.flights.getAllFlightBookings}${queryParams.toString() ? `?${queryParams.toString()}` : ''}`,
    method: 'GET'
  })

  if (response.status >= 200 && response.status < 300) {
    return response as GetAllFlightBookingsSuccessResponse
  } else {
    throw response as GetAllFlightBookingsErrorResponse
  }
}

export default getAllFlightBookingsApi
