import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import { UserRole } from '../../../../shared/functions/UserRole/userRoleManagement.types'
import {
  ResetOfferRequestFlightBookingErrorResponse,
  ResetOfferRequestFlightBookingRequest,
  ResetOfferRequestFlightBookingSuccessResponse
} from './resetOfferRequestFlightBooking.types'

export const resetOfferRequestFlightBookingApi = async (
  request: ResetOfferRequestFlightBookingRequest
): Promise<ResetOfferRequestFlightBookingSuccessResponse> => {
  const response = await configureRequest({
    url: urls.offers.resetOfferRequestFlightBooking,
    method: 'POST',
    params: {
      offerRequestId: request.offerRequestId
    },
    headers: {
      'X-User-Role': UserRole.shopper
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as ResetOfferRequestFlightBookingSuccessResponse
  } else {
    throw response as ResetOfferRequestFlightBookingErrorResponse
  }
}

export default resetOfferRequestFlightBookingApi
