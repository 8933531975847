import React, { useEffect } from 'react'

import { ExpandMore } from '@mui/icons-material'
import { Box, Divider, Grid, Typography } from '@mui/material'

import Accordion from '../../../../shared/components/Accordion/Accordion.component'
import Avatar from '../../../../shared/components/Avatar/Avatar.component'
import Link from '../../../../shared/components/Link/Link.component'
import TrackingDetails from '../../../../shared/components/TrackingDetails/TrackingDetails.component'

import { getAllFlightBookings } from '../../../../redux/states/flights/getAllFlightBookings/getAllFlightBookings.slice'
import {
  FlightLeg,
  GetAllFlightBookingsRequest,
  GetAllFlightBookingsSuccessResponse,
  ItemRequestDetails,
  OfferRequestDetails
} from '../../../../redux/states/flights/getAllFlightBookings/getAllFlightBookings.types'
import { getItemChanges } from '../../../../redux/states/items/getItemChanges/getItemChanges.slice'
import {
  GetItemChangesData,
  GetItemChangesRequestPayload
} from '../../../../redux/states/items/getItemChanges/getItemChanges.types'
import { acceptItemRequest } from '../../../../redux/states/offers/acceptItemRequest/acceptItemRequest.slice'
import { AcceptItemRequestRequestPayload } from '../../../../redux/states/offers/acceptItemRequest/acceptItemRequest.types'
import { denyItemRequest } from '../../../../redux/states/offers/denyItemRequest/denyItemRequest.slice'
import {
  clearItemRequestTrackingDetailsSuccess,
  getItemRequestTrackingDetails
} from '../../../../redux/states/offers/getItemRequestTrackingDetails/getItemRequestTrackingDetails.slice'
import { GetItemRequestTrackingDetailsRequestPayload } from '../../../../redux/states/offers/getItemRequestTrackingDetails/getItemRequestTrackingDetails.types'
import { RootState } from '../../../../redux/store/store.types'
import { AccordionAlertProps, AccordionButtonProps } from '../../../../shared/components/Accordion/Accordion.types'
import { AvatarSize } from '../../../../shared/components/Avatar/Avatar.types'
import Snackbar from '../../../../shared/components/Snackbar/Snackbar.functions'
import status from '../../../../shared/dictionaries/status.dictionaries'
import date, { dateTimeFormats } from '../../../../shared/functions/Date/date.functions'
import { joinStringWithBullet } from '../../../../shared/functions/String/string.functions'
import { UserRole } from '../../../../shared/functions/UserRole/userRoleManagement.types'
import { getUserRole } from '../../../../shared/functions/UserRole/userRoleManagment.functions'
import { useAppDispatch, useAppSelector } from '../../../../shared/hooks/redux.hooks'
import styles from './ItemRequestAccordion.styles'
import { ItemRequestAccordionProps } from './ItemRequestAccordion.types'

const ItemRequestAccordion: React.FC<ItemRequestAccordionProps> = (props) => {
  const { flightLeg, flightBookingStatus } = props

  const [expandedItemId, setExpandedItemId] = React.useState<string | null>(null)
  const [loadingAcceptItemId, setLoadingAcceptItemId] = React.useState<string | null>(null)
  const [loadingDenyItemId, setLoadingDenyItemId] = React.useState<string | null>(null)
  const [updatedFlightLeg, setUpdatedFlightLeg] = React.useState<FlightLeg>(flightLeg)
  const [alerts, setAlerts] = React.useState<{ [key: string]: AccordionAlertProps | null }>({})

  const { loading: getAllFlightBookingsLoading } = useAppSelector((state: RootState) => state.getAllFlightBookings)
  const { loading: getItemChangesLoading } = useAppSelector((state: RootState) => state.getItemChanges)
  const { loading: acceptItemRequestLoading } = useAppSelector((state: RootState) => state.acceptItemRequest)
  const { loading: denyItemRequestLoading } = useAppSelector((state: RootState) => state.denyItemRequest)

  const dispatch = useAppDispatch()

  useEffect(() => {
    setUpdatedFlightLeg(flightLeg)
  }, [flightLeg])

  useEffect(() => {
    if (updatedFlightLeg.offerRequestsDetails.length > 0) {
      updatedFlightLeg.offerRequestsDetails.forEach((offerRequest) => {
        offerRequest.itemRequestDetails.forEach((itemRequest) => {
          const latestPriceChangeDetail = itemRequest.priceChangeDetails?.[0]
          const latestAvailabilityChangeDetail = itemRequest.availabilityChangeDetails?.[0]

          if (latestPriceChangeDetail) {
            const itemPriceIncreasedAndNotAcceptedByShopper =
              latestPriceChangeDetail.changed &&
              latestPriceChangeDetail.acceptedByShopper === false &&
              latestPriceChangeDetail.newValue > latestPriceChangeDetail.oldValue &&
              latestPriceChangeDetail.newValue !== parseFloat(itemRequest.acceptedItemPrice)

            if (itemPriceIncreasedAndNotAcceptedByShopper) {
              setAlerts((prev) => ({
                ...prev,
                [itemRequest.id]: {
                  message: `Price changed on Amazon from $${latestPriceChangeDetail.oldValue} to $${latestPriceChangeDetail.newValue}. The shopper needs to agree to the new price and has been notified.`,
                  type: 'warning',
                  tag: 'price-increased'
                }
              }))
            }
          }

          if (latestAvailabilityChangeDetail) {
            const itemOutOfStock =
              latestAvailabilityChangeDetail.changed &&
              latestAvailabilityChangeDetail.newValue === 'Out of Stock' &&
              latestAvailabilityChangeDetail.oldValue === 'In Stock'

            if (itemOutOfStock) {
              setAlerts((prev) => ({
                ...prev,
                [itemRequest.id]: {
                  message: 'The item is now out of stock.',
                  type: 'warning',
                  tag: 'out-of-stock'
                }
              }))
            }
          }
        })
      })
    }
  }, [updatedFlightLeg])

  const refreshFlightBookings = (onRefreshSuccess?: () => void) => {
    const request: GetAllFlightBookingsRequest = {
      page: 1,
      size: 20
    }

    const onSuccess = (response: GetAllFlightBookingsSuccessResponse) => {
      const flightBooking = response.data.results.find((flightBooking) =>
        flightBooking.flightLegs.some((leg) => leg.flightDetails.id === updatedFlightLeg.flightDetails.id)
      )

      const newFlightLeg = flightBooking?.flightLegs.find(
        (leg) => leg.flightDetails.id === updatedFlightLeg.flightDetails.id
      )

      setUpdatedFlightLeg(newFlightLeg ?? flightLeg)
      onRefreshSuccess?.()
    }

    dispatch(getAllFlightBookings({ request, onSuccess }))
  }

  const handleAcceptItemRequest = (itemRequest: ItemRequestDetails) => {
    const itemRequestId = itemRequest.id.toString()
    const itemId = itemRequest.items.id.toString()

    const acceptItemRequestRequestPayload: AcceptItemRequestRequestPayload = {
      request: {
        itemRequestId
      },

      onSuccess: () => {
        refreshFlightBookings(() => {
          setLoadingAcceptItemId(null)
        })
      }
    }

    const getItemChangesRequestPayload: GetItemChangesRequestPayload = {
      request: {
        id: itemId
      },

      onSuccess: (itemChanges: GetItemChangesData) => {
        if (itemChanges.price.changed) {
          const itemPriceIncreased = itemChanges.price.newValue > itemChanges.price.oldValue

          if (itemPriceIncreased) {
            setAlerts((prev) => ({
              ...prev,
              [itemRequestId]: {
                message: `Price changed on Amazon from $${itemChanges.price.oldValue} to $${itemChanges.price.newValue}. The shopper needs to agree to the new price and has been notified.`,
                type: 'warning',
                tag: 'price-increased'
              }
            }))

            // TODO: Remove this once the alert is integrated into the Accordion
            Snackbar.showSnackbar({
              message: `Price changed on Amazon from $${itemChanges.price.oldValue} to $${itemChanges.price.newValue}.`,
              severity: 'warning'
            })
          }
        } else if (itemChanges.availability.changed) {
          const itemNowOutOfStock =
            itemChanges.availability.oldValue === 'In Stock' &&
            itemChanges.availability.newValue !== itemChanges.availability.oldValue

          if (itemNowOutOfStock) {
            setAlerts((prev) => ({
              ...prev,
              [itemRequestId]: {
                message: 'The item is currently out of stock on Amazon.',
                type: 'warning',
                tag: 'out-of-stock'
              }
            }))

            // TODO: Remove this once the alert is integrated into the Accordion
            Snackbar.showSnackbar({
              message: 'The item is now out of stock.',
              severity: 'warning'
            })
          }
        } else {
          dispatch(acceptItemRequest(acceptItemRequestRequestPayload))
        }
      }
    }

    setLoadingAcceptItemId(itemRequestId)

    dispatch(getItemChanges(getItemChangesRequestPayload))
  }

  const handleDenyItemRequest = (itemRequestId: string) => {
    const request = {
      itemRequestId
    }

    setLoadingDenyItemId(itemRequestId)

    const onSuccess = () => {
      refreshFlightBookings(() => {
        setLoadingDenyItemId(null)
      })
    }

    dispatch(denyItemRequest({ request, onSuccess }))
  }

  const renderItemDetails = (itemRequest: ItemRequestDetails, offerRequest: OfferRequestDetails) => {
    const itemSourceCountry = itemRequest.items.country.iataCode
    const destinationCountry = updatedFlightLeg.flightDetails.destination.country.iataCode
    const itemRequestAccordionHeaderButtons: AccordionButtonProps[] = []

    // TODO: Resolve issue with tracking details not being closed properly when another tracking details is opened
    const handleTrackButtonPress = (id: number) => {
      const itemId = id.toString()

      if (expandedItemId === itemId) {
        setExpandedItemId(null)
        dispatch(clearItemRequestTrackingDetailsSuccess())
      } else {
        setExpandedItemId(itemId)

        const payload: GetItemRequestTrackingDetailsRequestPayload = {
          request: {
            itemRequestId: itemId
          }
        }

        dispatch(getItemRequestTrackingDetails(payload))
      }
    }

    const handleAccordionExpansion = (isExpanded: boolean, accordionKey: string | number | undefined) => {
      if (isExpanded) {
        setExpandedItemId(accordionKey?.toString() ?? null)
      } else {
        setExpandedItemId(null)
      }
    }

    if (itemSourceCountry === destinationCountry) {
      const hasFullWeightDetails = itemRequest.items.weight && itemRequest.items.weightUnit
      // const isBuyButtonVisible = trackingStatus !== status.deliveryStatus.purchased_by_traveler
      // const isTrackButtonVisible = flightBookingStatus === status.flightStatus.flight_booked
      const isTrackButtonVisible = true
      const isBuyButtonVisible = true

      const isAcceptButtonDisabled = () => {
        const latestPriceChangeDetail = itemRequest.priceChangeDetails?.[0]
        const latestAvailabilityChangeDetail = itemRequest.availabilityChangeDetails?.[0]
        let disabled = false
        let itemPriceIncreasedAndNotAcceptedByShopper = false
        let itemOutOfStock = false

        if (latestPriceChangeDetail) {
          itemPriceIncreasedAndNotAcceptedByShopper =
            latestPriceChangeDetail.changed &&
            latestPriceChangeDetail.acceptedByShopper === false &&
            latestPriceChangeDetail.newValue > latestPriceChangeDetail.oldValue &&
            latestPriceChangeDetail.newValue !== parseFloat(itemRequest.acceptedItemPrice)
        }

        if (latestAvailabilityChangeDetail) {
          itemOutOfStock =
            latestAvailabilityChangeDetail.changed &&
            latestAvailabilityChangeDetail.newValue === 'Out of Stock' &&
            latestAvailabilityChangeDetail.oldValue === 'In Stock'
        }

        if (alerts[itemRequest.id]?.tag === 'price-increased') {
          disabled = true
        } else if (alerts[itemRequest.id]?.tag === 'out-of-stock') {
          disabled = true
        } else if (itemPriceIncreasedAndNotAcceptedByShopper) {
          disabled = true
        } else if (itemOutOfStock) {
          disabled = true
        }

        if (loadingDenyItemId === itemRequest.id.toString()) {
          disabled = true
        }

        return disabled
      }

      switch (itemRequest?.status.name) {
        case status.itemRequestStatus.acceptedByTraveler:
        case status.deliveryStatus.purchasedByTraveler:
        case status.deliveryStatus.inTransitToTraveler:
        case status.deliveryStatus.deliveredToTravelerAddress:
        case status.deliveryStatus.confirmedReceivedByTraveler:
        case status.deliveryStatus.confirmedInDestinationCountry:
        case status.deliveryStatus.confirmedDeliveredToShopperByTraveler:
          if (isTrackButtonVisible) {
            itemRequestAccordionHeaderButtons.push({
              text: 'Track',
              icon: (
                <ExpandMore
                  style={{
                    transform: expandedItemId === itemRequest.id.toString() ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: 'transform 0.3s'
                  }}
                />
              ),
              iconPosition: 'end',
              onClick: () => handleTrackButtonPress(itemRequest.id),
              expandAccordion: true,
              buttonType: 'tertiary'
            })
          }

          if (isBuyButtonVisible) {
            itemRequestAccordionHeaderButtons.push({
              text: 'Buy on Amazon',
              href: itemRequest.items.internalUrl,
              target: '_blank',
              buttonType: 'primary',
              disabled: flightBookingStatus !== status.flightStatus.flightBooked,
              tooltipText:
                flightBookingStatus !== status.flightStatus.flightBooked
                  ? 'Must book flight before you can buy this item on Amazon.'
                  : 'As an Amazon Associate, we earn from qualifying purchases.'
            })
          }

          break

        case status.deliveryStatus.confirmedDeliveredToShopperByShopper:
          itemRequestAccordionHeaderButtons.push({
            text: getUserRole() === UserRole.shopper ? 'Received' : 'Delivered',
            buttonType: 'primary',
            disabled: true
          })

          break

        case status.itemRequestStatus.deniedByTraveler:
          itemRequestAccordionHeaderButtons.push({
            text: 'Denied',
            buttonType: 'primary',
            disabled: true
          })

          break

        case status.itemRequestStatus.cancelledByShopper:
          itemRequestAccordionHeaderButtons.push({
            text: 'Cancelled By Shopper',
            buttonType: 'primary',
            disabled: true,
            tooltipText: 'The shopper has cancelled this request.'
          })

          break

        default:
          itemRequestAccordionHeaderButtons.push({
            text: 'Deny',
            onClick: () => handleDenyItemRequest(itemRequest.id.toString()),
            buttonType: 'tertiary',
            loading:
              (denyItemRequestLoading || getAllFlightBookingsLoading) &&
              loadingDenyItemId === itemRequest.id.toString(),
            disabled: loadingAcceptItemId === itemRequest.id.toString()
          })

          itemRequestAccordionHeaderButtons.push({
            text: 'Accept',
            onClick: () => handleAcceptItemRequest(itemRequest),
            buttonType: 'primary',
            loading:
              (acceptItemRequestLoading || getAllFlightBookingsLoading || getItemChangesLoading) &&
              loadingAcceptItemId === itemRequest.id.toString(),
            disabled: isAcceptButtonDisabled(),
            tooltipText: isAcceptButtonDisabled() ? 'The item cannot currently be accepted.' : undefined
          })
          break
      }

      const itemRequestAccordionHeader = {
        mainContent: (
          <Grid item key={itemRequest.id} sx={styles.itemRequestContainer}>
            <Grid item md={5} sx={styles.itemDetailsContainer}>
              <Box sx={styles.itemImageContainer}>
                <img src={itemRequest.items.images[0]} alt={itemRequest.items.title} style={styles.itemImage} />
              </Box>

              <Box sx={styles.itemDetailsInfoContainer}>
                <Typography sx={styles.itemTitle}>{itemRequest.items.title}</Typography>

                <Typography sx={styles.itemDetailsText}>
                  {joinStringWithBullet([
                    `Qty: ${itemRequest.itemQuantity}`,
                    hasFullWeightDetails ? `Weighing ${itemRequest.items.weight} ${itemRequest.items.weightUnit}` : null
                  ])}

                  {!hasFullWeightDetails && (
                    <span>
                      {`Weighing: N/A (`}
                      <Link href={itemRequest.items.internalUrl} target="_blank" showColor>
                        View on Amazon
                      </Link>
                      {`)`}
                    </span>
                  )}
                </Typography>

                <Typography sx={styles.itemDetailsText}>
                  {joinStringWithBullet([
                    `$${parseFloat(itemRequest.items.price).toFixed(2)}`,
                    `Amazon price as of ${date(itemRequest.items.priceAt).format(
                      `${dateTimeFormats.date.medium} ${dateTimeFormats.time.short}`
                    )} EST`
                  ])}
                </Typography>
              </Box>
            </Grid>

            <Divider orientation="vertical" flexItem sx={styles.itemDetailsDivider} />

            <Grid item md={4} sx={styles.shopperInfoContainer}>
              <Avatar
                firstName={offerRequest.shopperDetails.firstName}
                lastName={offerRequest.shopperDetails.lastName}
                size={AvatarSize.small}
              />

              <Box>
                <Typography sx={styles.shopperNameText}>
                  {`${offerRequest.shopperDetails.firstName} ${offerRequest.shopperDetails.lastName.charAt(0)}.`}
                </Typography>

                <Typography sx={styles.itemDetailsText}>
                  {`${offerRequest.deliveryDetails.city.name}, ${offerRequest.deliveryDetails.country.name}`}
                </Typography>
              </Box>
            </Grid>

            <Divider orientation="vertical" flexItem sx={styles.itemDetailsDivider} />

            {/* TODO: Show flight discount for item instead of price */}
            <Grid item md={3}>
              <Typography sx={styles.itemPriceText}>{`$${parseFloat(itemRequest.items.price).toFixed(2)}`}</Typography>
              <Typography sx={styles.itemDetailsText}>Flight Discount</Typography>
            </Grid>
          </Grid>
        ),
        buttons: itemRequestAccordionHeaderButtons
      }

      const getTrackingDetails = () => {
        return (
          <TrackingDetails
            itemRequestId={itemRequest.id}
            destinationCity={offerRequest.deliveryDetails.city.name}
            key={itemRequest.id}
            isOpen={expandedItemId === itemRequest.id.toString()}
            refreshMainList={refreshFlightBookings}
          />
        )
      }

      // TODO: Add logic to check item tracking status
      const isTrackingDetailsAccessible =
        itemRequest.status.name === status.itemRequestStatus.acceptedByTraveler &&
        flightBookingStatus === status.flightStatus.flightBooked

      return (
        <Accordion
          header={itemRequestAccordionHeader}
          body={getTrackingDetails()}
          noExpandIcon
          noExpandBackgroundColor
          preventExpandChange={!isTrackingDetailsAccessible}
          handleExpansion={handleAccordionExpansion}
          accordionKey={itemRequest.id}
          key={itemRequest.id}
          alert={alerts[itemRequest.id] || undefined}
        />
      )
    }
  }

  const renderLegDetails = () => (
    <Box key={updatedFlightLeg.flightDetails.id}>
      {updatedFlightLeg.offerRequestsDetails.map((offerRequest) =>
        offerRequest.itemRequestDetails.map((itemRequest) => renderItemDetails(itemRequest, offerRequest))
      )}
    </Box>
  )

  return <Box sx={styles.itemRequestAccordionContainer}>{renderLegDetails()}</Box>
}

export default ItemRequestAccordion
