import React from 'react'

import ItemDetails from '../ItemDetails/ItemDetails.component'

import { CartItem } from '../../Cart.types'
import { CartStepProps } from './CartStep.types'

const CartStep: React.FC<CartStepProps> = ({ cartItems, updateItemQuantity }) => {
  return (
    <>
      {cartItems.map((item: CartItem) => (
        <ItemDetails
          key={item.id}
          item={item}
          updateQuantity={(newQuantity) => updateItemQuantity(item.id, newQuantity)}
        />
      ))}
    </>
  )
}

export default CartStep
