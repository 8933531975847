import React, { useCallback, useEffect } from 'react'

import { Box, Typography } from '@mui/material'
import _ from 'lodash'
import * as Yup from 'yup'

import Button from '../../shared/components/Button/Button.component'
import Form from '../../shared/components/Form/Form.component'

import images from '../../assets/images'
import { addDeliveryAddress } from '../../redux/states/profile/addDeliveryAddress/addDeliveryAddress.slice'
import { getProfile } from '../../redux/states/profile/getProfile/getProfile.slice'
import { GetProfileData } from '../../redux/states/profile/getProfile/getProfile.types'
import { updateContactInfo } from '../../redux/states/profile/updateContactInfo/updateContactInfo.slice'
import { updateDeliveryAddress } from '../../redux/states/profile/updateDeliveryAddress/updateDeliveryAddress.slice'
import { updatePersonalInfo } from '../../redux/states/profile/updatePersonalInfo/updatePersonalInfo.slice'
import router from '../../router/functions/router.functions'
import routes from '../../router/routes.dictionary'
import { FormField } from '../../shared/components/Form/Form.types'
import cities from '../../shared/dictionaries/cities.dictionaries.json'
import countries from '../../shared/dictionaries/countries.dictionaries.json'
import date, { dateTimeFormats } from '../../shared/functions/Date/date.functions'
import { useAppDispatch, useAppSelector } from '../../shared/hooks/redux.hooks'
import styles from './Profile.styles'
import { ContactDetailsFormValues, DeliveryAddressFormValues, PersonalDetailsFormValues } from './Profile.types'

const Profile: React.FC = () => {
  const { success: profileSuccess, loading: profileLoading } = useAppSelector((state) => state.getProfile)
  const { loading: updatePersonalInfoLoading } = useAppSelector((state) => state.updatePersonalInfo)
  const { loading: updateContactInfoLoading } = useAppSelector((state) => state.updateContactInfo)
  const { loading: addDeliveryAddressLoading } = useAppSelector((state) => state.addDeliveryAddress)
  const { loading: updateDeliveryAddressLoading } = useAppSelector((state) => state.updateDeliveryAddress)

  const [isPersonalDetailsDisabled, setIsPersonalDetailsDisabled] = React.useState<boolean>(false)
  const [isContactDetailsDisabled, setIsContactDetailsDisabled] = React.useState<boolean>(false)
  const [personalDetailsInitialValues, setPersonalDetailsInitialValues] = React.useState<PersonalDetailsFormValues>({
    firstName: '',
    middleName: '',
    lastName: '',
    dateOfBirth: ''
  })

  const [contactDetailsInitialValues, setContactDetailsInitialValues] = React.useState<ContactDetailsFormValues>({
    mobileNumber: '',
    email: ''
  })

  const [deliveryAddressInitialValues, setDeliveryAddressInitialValues] = React.useState<DeliveryAddressFormValues>({
    deliveryAddressId: 0,
    cityId: 83, // Default to the Kingston
    countryId: 83, // Default to the Jamaica
    streetAddress: ''
  })

  const dispatch = useAppDispatch()

  const updateInitialValues = useCallback((data: GetProfileData) => {
    setPersonalDetailsInitialValues({
      firstName: data.firstName || '',
      middleName: data.middleName || '',
      lastName: data.lastName || '',
      dateOfBirth: date(data.dateOfBirth).format(dateTimeFormats.date.default) || ''
    })

    setContactDetailsInitialValues({
      mobileNumber: data.mobileNumber || '',
      email: data.email || ''
    })

    /**
     * Defaulting to the first item in the array of delivery addresses
     * until we separate the delivery addresses from the profile data
     */
    setDeliveryAddressInitialValues({
      deliveryAddressId: data?.deliveryAddresses[0]?.id || 0,
      cityId: data?.deliveryAddresses[0]?.city_id || 83,
      countryId: data?.deliveryAddresses[0]?.country_id || 83,
      streetAddress: data?.deliveryAddresses[0]?.street_address || ''
    })
  }, [])

  useEffect(() => {
    const onSuccess = (data: GetProfileData) => {
      updateInitialValues(data)
    }

    dispatch(getProfile({ onSuccess }))
  }, [dispatch, updateInitialValues])

  const navigate = router.navigate()

  const personalDetailsFields: FormField[] = [
    {
      name: 'firstName',
      label: 'First Name',
      type: 'text',
      autoComplete: 'given-name',
      grid: { xs: 12, sm: 6 },
      required: true,
      fullWidth: true
    },
    {
      name: 'middleName',
      label: 'Middle Name',
      type: 'text',
      autoComplete: 'additional-name',
      grid: { xs: 12, sm: 6 },
      fullWidth: true
    },
    {
      name: 'lastName',
      label: 'Last Name',
      type: 'text',
      autoComplete: 'family-name',
      grid: { xs: 12, sm: 6 },
      required: true,
      fullWidth: true
    },
    {
      name: 'dateOfBirth',
      label: 'Date of Birth',
      type: 'date',
      autoComplete: 'bday',
      grid: { xs: 12, sm: 6 },
      fullWidth: true
    }
  ]

  const contactDetailsFields: FormField[] = [
    {
      name: 'mobileNumber',
      label: 'Mobile Number',
      type: 'text',
      autoComplete: 'tel',
      grid: { xs: 12, sm: 6 },
      required: true,
      fullWidth: true
    },
    {
      name: 'email',
      label: 'Email Address',
      type: 'email',
      autoComplete: 'email',
      grid: { xs: 12, sm: 6 },
      required: true,
      fullWidth: true
    },
    {
      name: 'cityId',
      label: 'City',
      type: 'select',
      options: cities.map((city) => ({ value: city.id, label: city.name })),
      autoComplete: 'address-level2',
      grid: { xs: 12, sm: 6 },
      fullWidth: true
      // onChange: (event: React.ChangeEvent<HTMLInputElement>) => handleCityChange(Number(event.target.value))
    },
    {
      name: 'countryId',
      label: 'Country',
      type: 'select',
      options: countries.map((country) => ({ value: country.id, label: country.name })),
      autoComplete: 'country',
      grid: { xs: 12, sm: 6 },
      fullWidth: true
    },
    {
      name: 'streetAddress',
      label: 'Street Address',
      type: 'text',
      autoComplete: 'street-address',
      grid: { xs: 12 },
      fullWidth: true
    }
  ]

  const personalDetailsValidationSchema = Yup.object({
    firstName: Yup.string().min(2, 'First name must be at least 2 characters long').required('Required'),
    middleName: Yup.string().min(2, 'Middle name must be at least 2 characters long'),
    lastName: Yup.string().min(2, 'Last name must be at least 2 characters long').required('Required'),
    dateOfBirth: Yup.date()
  })

  const contactDetailsValidationSchema = Yup.object({
    mobileNumber: Yup.string().min(10, 'Mobile number must be at least 10 characters long').required('Required'),
    email: Yup.string().email('Invalid email address').required('Required'),
    cityId: Yup.number().required('Required'),
    countryId: Yup.number().required('Required'),
    streetAddress: Yup.string().min(2, 'Street address must be at least 2 characters long')
  })

  const handlePersonalDetailsSubmit = (values: PersonalDetailsFormValues) => {
    const onSuccess = () => {
      setIsPersonalDetailsDisabled(false)
      dispatch(getProfile({ onSuccess: updateInitialValues }))
    }

    dispatch(updatePersonalInfo({ updatePersonalInfoRequest: values, onSuccess }))
  }

  const handleContactDetailsSubmit = (values: ContactDetailsFormValues & DeliveryAddressFormValues) => {
    const onSuccess = () => {
      setIsContactDetailsDisabled(false)
      dispatch(getProfile({ onSuccess: updateInitialValues }))
    }

    const contactDetailsValues: ContactDetailsFormValues = {
      mobileNumber: values.mobileNumber,
      email: values.email
    }

    const deliveryAddressValues: DeliveryAddressFormValues = {
      deliveryAddressId: values.deliveryAddressId,
      cityId: values.cityId,
      countryId: values.countryId,
      streetAddress: values.streetAddress
    }

    const deliveryValues = {
      ...deliveryAddressValues,
      postalCode: '00000'
    }

    const isDeliveryAddressEmpty = Object.values(deliveryAddressValues).every((value) => value === '')

    if (!isDeliveryAddressEmpty) {
      if (_.isEmpty(profileSuccess?.data?.deliveryAddresses)) {
        dispatch(addDeliveryAddress({ addDeliveryAddressRequest: deliveryValues, onSuccess }))
      } else {
        dispatch(updateDeliveryAddress({ updateDeliveryAddressRequest: deliveryValues, onSuccess }))
      }
    }

    dispatch(updateContactInfo({ updateContactInfoRequest: contactDetailsValues, onSuccess }))
  }

  // const handleCityChange = (cityId: number) => {
  //   const selectedCity = cities.find((city) => city.id === cityId)

  //   if (selectedCity) {
  //     const countryId = selectedCity.country_id

  //     setDeliveryAddressInitialValues((prevValues) => ({
  //       ...prevValues,
  //       cityId,
  //       countryId
  //     }))
  //   }
  // }

  const profileHeader = () => {
    return (
      <Box sx={styles.profileHeader}>
        <img src={images.profileAvatar} alt="Profile" style={styles.profilePicture} />
        <Button buttonType="primary" text="Basic Information" />
      </Box>
    )
  }

  return (
    <Box sx={styles.container}>
      <Typography variant="h4" sx={styles.titleText}>
        Profile
      </Typography>

      <Box sx={styles.contentContainer}>
        {profileHeader()}

        <Box sx={styles.profileFormsContainer}>
          <Form
            title="Personal Details"
            initialValues={{ ...personalDetailsInitialValues }}
            onSubmit={(values: unknown) => handlePersonalDetailsSubmit(values as PersonalDetailsFormValues)}
            formFields={personalDetailsFields}
            validationSchema={personalDetailsValidationSchema}
            buttonText="Save"
            isContained
            isEditable={isPersonalDetailsDisabled}
            setIsEditable={setIsPersonalDetailsDisabled}
            loading={profileLoading || updatePersonalInfoLoading}
            enableReinitialize
          />

          <Form
            title="Contact Details"
            initialValues={{ ...contactDetailsInitialValues, ...deliveryAddressInitialValues }}
            onSubmit={(values: unknown) =>
              handleContactDetailsSubmit(values as ContactDetailsFormValues & DeliveryAddressFormValues)
            }
            formFields={contactDetailsFields}
            validationSchema={contactDetailsValidationSchema}
            buttonText="Save"
            isContained
            isEditable={isContactDetailsDisabled}
            setIsEditable={setIsContactDetailsDisabled}
            loading={
              profileLoading || addDeliveryAddressLoading || updateDeliveryAddressLoading || updateContactInfoLoading
            }
            enableReinitialize
          />

          <Box sx={styles.changePasswordContainer}>
            <Typography sx={styles.changePasswordText}>
              If you want to change your password please click the option. A email with a link to change your password
              will be sent to the email attached to this account.
            </Typography>

            <Button
              buttonType="primary"
              outline
              text="Change Password"
              onClick={() => navigate(routes.changePassword.path)}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Profile
