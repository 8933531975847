import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../shared/types/api.types'
import { searchFlightsApi } from './searchFlights.api'
import searchFlightsInitialState from './searchFlights.initialState'
import { SearchFlightsErrorResponse, SearchFlightsRequestPayload } from './searchFlights.types'

export const searchFlights = createAsyncThunk(
  urls.flights.search,
  async (payload: SearchFlightsRequestPayload, { rejectWithValue }) => {
    const { request, onSuccess } = payload

    try {
      const response = await searchFlightsApi(request)

      onSuccess && onSuccess()

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse)

      return rejectWithValue(error)
    }
  }
)

const searchFlightsSlice = createSlice({
  name: urls.flights.search,
  initialState: searchFlightsInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(searchFlights.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(searchFlights.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(searchFlights.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as SearchFlightsErrorResponse
        state.success = null
      })
  }
})

export const { actions: searchFlightsActions, reducer: searchFlightsReducer } = searchFlightsSlice
export default searchFlightsSlice.reducer
