import React, { useCallback, useEffect } from 'react'

import { Box } from '@mui/material'

import PaginatedPage from '../../shared/components/PaginatedPage/PaginatedPage.component'
import TripDetailsAccordion from './components/TripDetailsAccordion/TripDetailsAccordion.component'

import { getAllFlightBookings } from '../../redux/states/flights/getAllFlightBookings/getAllFlightBookings.slice'
import {
  FlightBooking,
  GetAllFlightBookingsRequest
} from '../../redux/states/flights/getAllFlightBookings/getAllFlightBookings.types'
import { RootState } from '../../redux/store/store.types'
import router from '../../router/functions/router.functions'
import routes from '../../router/routes.dictionary'
import { EmptyStateProps } from '../../shared/components/EmptyState/EmptyState.types'
import { FilterTab, PaginatedResponse } from '../../shared/components/PaginatedPage/PaginatedPage.types'
import date from '../../shared/functions/Date/date.functions'
import { useAppDispatch, useAppSelector } from '../../shared/hooks/redux.hooks'
import styles from './Trips.styles'

const Trips: React.FC = () => {
  const { loading } = useAppSelector((state: RootState) => state.getAllFlightBookings)

  const dispatch = useAppDispatch()
  const navigate = router.navigate()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const fetchFlightBookings = useCallback(
    async (page: number): Promise<PaginatedResponse<FlightBooking>> => {
      return new Promise((resolve) => {
        const getAllFlightBookingsPayload: GetAllFlightBookingsRequest = {
          page,
          size: 20
        }

        dispatch(
          getAllFlightBookings({
            request: getAllFlightBookingsPayload,
            onSuccess: (response) => {
              resolve({
                currentPage: response.data.currentPage,
                totalPages: response.data.totalPages,
                results: response.data.results
              })
            }
          })
        )
      })
    },
    [dispatch]
  )

  const filterTabs: FilterTab<unknown>[] = [
    {
      id: 'upcoming',
      label: 'Upcoming',
      filterDef: (flightBookings: unknown[]) =>
        (flightBookings as FlightBooking[]).filter((flightBooking) => {
          const lastLeg = flightBooking.flightLegs[flightBooking.flightLegs.length - 1]

          return date(date().currentDate).isSameOrBefore(date(lastLeg.flightDetails.arrivalTime).dateTime, 'day')
        })
    },

    {
      id: 'completed',
      label: 'Completed',
      filterDef: (flightBookings: unknown[]) =>
        (flightBookings as FlightBooking[]).filter((flightBooking) => {
          const lastLeg = flightBooking.flightLegs[flightBooking.flightLegs.length - 1]

          return date(lastLeg.flightDetails.arrivalTime).isBefore(date().currentDate)
        })
    }
  ]

  const emptyState: EmptyStateProps = {
    title: 'You have no trips available',
    subtitle: 'Book a trip and share your link with friends and family to get started.',
    button: {
      text: 'Book Trip',
      onClick: () => {
        navigate(routes.home.path)
      }
    }
  }

  const filterEmptyState: EmptyStateProps = {
    title: 'No trips available in this category',
    subtitle: 'Book a trip and share your link with friends and family to get started.',
    button: {
      text: 'Book Flight',
      onClick: () => {
        navigate(routes.home.path)
      }
    }
  }

  return (
    <Box sx={styles.container}>
      <PaginatedPage
        title={{ text: 'My Trips', id: 'Trips' }}
        subtitle="View your past and upcoming trips."
        initialLoading={loading}
        emptyState={emptyState}
        fetchCall={fetchFlightBookings}
        renderListData={TripDetailsAccordion}
        horizontalFilterTabs={filterTabs}
        filterEmptyState={filterEmptyState}
      />
    </Box>
  )
}

export default Trips
