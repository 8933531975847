import React from 'react'

import { Box, Divider, Typography } from '@mui/material'

import date, { dateTimeFormats, formatDuration } from '../../../../shared/functions/Date/date.functions'
import styles from './TripOverview.styles'
import { TripOverviewProps } from './TripOverview.types'

const TripOverview: React.FC<TripOverviewProps> = (props) => {
  const { offerData } = props

  return (
    <Box sx={styles.container}>
      <Box sx={styles.cardTop}>
        <Typography variant="h6" sx={styles.title}>
          Overview
        </Typography>
      </Box>

      <Divider sx={styles.divider} />

      {offerData.slices.map((slice) => (
        <Box key={slice.id} sx={styles.section}>
          <Box sx={styles.sectionContent}>
            <Typography variant="body2" sx={styles.sectionTitle}>
              {slice.origin.city_name} to {slice.destination.city_name}
            </Typography>

            {slice.segments.map((segment) => (
              <Box key={segment.id}>
                <Typography sx={styles.segmentValueText} variant="body2">
                  {date(segment.departing_at).format(dateTimeFormats.date.long)}
                </Typography>

                <Box sx={styles.gap} />

                <Typography sx={styles.segmentValueText} variant="body2">
                  <span style={styles.timeSpan}>
                    {date(segment.departing_at).format(dateTimeFormats.time.short)} –{' '}
                    {date(segment.arriving_at).format(dateTimeFormats.time.short)}
                  </span>{' '}
                  ({formatDuration(segment.duration)})
                </Typography>

                <Typography sx={styles.segmentValueText} variant="body2">
                  {segment.origin.iata_code} – {segment.destination.iata_code}
                </Typography>
              </Box>
            ))}
          </Box>

          <img src={offerData.owner.logo_symbol_url} alt="Airline Logo" style={styles.logo} />
        </Box>
      ))}

      <Divider sx={styles.divider} />

      <Box sx={styles.amountTopContainer}>
        <Typography variant="body2" sx={styles.sectionTitle}>
          Total amount
        </Typography>

        <Box sx={styles.subTotalContainer}>
          <Typography sx={styles.subtotalTitleText} variant="body2">
            Subtotal
          </Typography>

          <Typography sx={styles.subtotalValue} variant="body1">
            US ${offerData.total_amount}
          </Typography>
        </Box>
      </Box>

      <Divider sx={styles.divider} />

      <Box sx={styles.amountToPayContainer}>
        <Typography variant="body2" sx={styles.amountToPayTitle}>
          Amount to pay
        </Typography>

        <Typography variant="h6" sx={styles.amount}>
          US ${offerData.total_amount}
        </Typography>
      </Box>
    </Box>
  )
}

export default TripOverview
