import React, { useEffect } from 'react'

import { Box, Typography } from '@mui/material'

import { EmptyState } from '../../shared/components/EmptyState/EmptyState.component'
import Loader from '../../shared/components/Loader/Loader.component'
import { MarketplaceLinksInput } from '../Shop/components/MarketplaceLinksInput/MarketplaceLinksInput.component'

import { getFlightBooking } from '../../redux/states/flights/getFlightBooking/getFlightBooking.slice'
import { GetFlightBookingRequestPayload } from '../../redux/states/flights/getFlightBooking/getFlightBooking.types'
import { RootState } from '../../redux/store/store.types'
import router from '../../router/functions/router.functions'
import status from '../../shared/dictionaries/status.dictionaries'
import { UserRole } from '../../shared/functions/UserRole/userRoleManagement.types'
import { setUserRole } from '../../shared/functions/UserRole/userRoleManagment.functions'
import { useAppDispatch, useAppSelector } from '../../shared/hooks/redux.hooks'
import styles from './SharedTrip.styles'

export const SharedTrip: React.FC = () => {
  const { success: flightBookingResponse, loading: flightBookingLoading } = useAppSelector(
    (state: RootState) => state.getFlightBooking
  )

  const dispatch = useAppDispatch()
  const flightBookingId = router.getUrlParam('trip-id')

  useEffect(() => {
    setUserRole(UserRole.shopper)
  }, [])

  useEffect(() => {
    const getAllFlightBookingsPayload: GetFlightBookingRequestPayload = {
      request: {
        id: flightBookingId
      }
    }

    dispatch(getFlightBooking(getAllFlightBookingsPayload))
  }, [flightBookingId, dispatch])

  const renderMainContent = () => {
    const isFlightValid = flightBookingResponse?.data?.status.name === status.flightStatus.interestedInFlight

    if (flightBookingLoading) {
      return <Loader loading={flightBookingLoading} text="Getting the trip details" size="large" marginTop={10} />
    } else if (!flightBookingLoading && !flightBookingResponse?.data) {
      return (
        <Box sx={styles.sharedTripContainer}>
          <EmptyState title="No trip found" subtitle="Please check the link and try again" />
        </Box>
      )
    } else if (!isFlightValid) {
      return (
        <Box sx={styles.sharedTripContainer}>
          <EmptyState title="Trip unavailable" subtitle="This trip is no longer accepting orders" />
        </Box>
      )
    } else if (flightBookingResponse?.data) {
      return (
        <Box sx={styles.sharedTripContainer}>
          <Box sx={styles.pageTextContainer}>
            <Typography variant="h4" sx={styles.title}>
              {flightBookingResponse?.data.traveler.firstName}'s Trip
            </Typography>

            <Typography variant="subtitle1" sx={styles.subtitle}>
              Please copy and place your Amazon url orders below. Select Item destination on the payment page.
            </Typography>
          </Box>

          <MarketplaceLinksInput flightBooking={flightBookingResponse?.data} />
        </Box>
      )
    }
  }

  return <Box sx={styles.container}>{renderMainContent()}</Box>
}

export default SharedTrip
