import React from 'react'

import { Dialog, DialogActions, DialogContent, Typography } from '@mui/material'

import Button from '../Button/Button.component'

import styles from './Modal.styles'
import { ModalProps } from './Modal.types'

const Modal: React.FC<ModalProps> = (props) => {
  const { open, onClose, title, subtitle, primaryButton, secondaryButton, body, loading } = props

  const primaryButtonAction = () => (
    <Button
      buttonType="primary"
      text={primaryButton?.label?.toUpperCase() || ''}
      fullWidth={!secondaryButton}
      onClick={primaryButton?.onClick}
      disabled={primaryButton?.disabled || loading}
      loading={loading}
    />
  )

  const secondaryButtonAction = () => (
    <Button
      buttonType="tertiary"
      text={secondaryButton?.label?.toUpperCase() || ''}
      onClick={secondaryButton?.onClick}
      outline
    />
  )

  return (
    <Dialog open={open} onClose={onClose} sx={styles.dialog}>
      <DialogContent>
        {title && (
          <Typography variant="h5" sx={styles.title}>
            {title}
          </Typography>
        )}

        {subtitle && (
          <Typography variant="subtitle1" sx={styles.subtitle}>
            {subtitle}
          </Typography>
        )}

        {body && body}

        <DialogActions>
          {secondaryButton && secondaryButtonAction()}
          {primaryButton && primaryButtonAction()}
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

export default Modal
