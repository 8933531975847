import * as Sentry from '@sentry/react'

import env from '../../../networkRequests/apiClient/env.config'

/**
 * Initializes Sentry for error tracking and performance monitoring.
 *
 * This function configures Sentry with the provided DSN and integrations.
 * It also sets up performance monitoring and session replay settings.
 *
 * @example
 * initializeSentry()
 */
const initializeSentry = (): void => {
  Sentry.init({
    dsn: env.SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],

    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions

    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],

    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 1000% when sampling sessions where errors occur.
  })
}

/**
 * Logs an event message in Sentry.
 *
 * This function captures a message in Sentry with optional parameters.
 * If an error occurs during the logging process, it captures the error in Sentry.
 *
 * @param {string} eventName - The name of the event to log.
 * @param {Record<string, string | number | boolean>} [parameters] - Optional parameters to log with the event.
 *
 * @example
 * logEvent('User_Signup', { userId: 123, success: true })
 */
const logEvent = (eventName: string, parameters?: Record<string, string | number | boolean>): void => {
  try {
    Sentry.captureMessage(eventName, {
      extra: parameters
    })
  } catch (error) {
    logError(error, eventName, 'logEvent', parameters)
  }
}

/**
 * Captures an exception in Sentry.
 *
 * This function captures an exception in Sentry with additional context.
 * It logs the error along with the function name, event name, and optional parameters.
 *
 * @param {Error | unknown} error - The error to capture.
 * @param {string} eventName - The name of the event to log.
 * @param {string} functionName - The name of the function where the error occurred.
 * @param {Record<string, string | number | boolean>} [parameters] - Optional parameters to log with the event.
 *
 * @example
 * logError(new Error('Something went wrong'), 'User_Login', 'loginFunction', { userId: 123 })
 */
const logError = (
  error: Error | unknown,
  eventName: string,
  functionName: string,
  parameters?: Record<string, string | number | boolean>
): void => {
  Sentry.captureException(error, {
    extra: {
      function: functionName,
      eventName,
      parameters
    }
  })
}

const sentry = {
  initialize: initializeSentry,
  logEvent,
  logError
}

export default sentry
