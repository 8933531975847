import { Cart } from '../pages/Cart/Cart.container'
import ChangePassword from '../pages/ChangePassword/ChangePassword.container'
import Contact from '../pages/Contact/Contact.container'
import FlightSearchResults from '../pages/FlightSearchResults/FlightSearchResults.container'
import ForgetPassword from '../pages/ForgetPassword/ForgetPassword.container'
import ForgetPasswordSuccess from '../pages/ForgetPasswordSuccess/ForgetPasswordSuccess.container'
import Login from '../pages/Login/Login.container'
import Offers from '../pages/Offers/Offers.container'
import Orders from '../pages/Orders/Orders.container'
import Profile from '../pages/Profile/Profile.container'
import Requests from '../pages/Requests/Requests.container'
import ResetForgottenPassword from '../pages/ResetForgottenPassword/ResetForgottenPassword.container'
import { SharedTrip } from '../pages/SharedTrip/SharedTrip.container'
import Shop from '../pages/Shop/Shop.container'
import SignUp from '../pages/SignUp/SignUp.container'
import SignUpSuccess from '../pages/SignUpSuccess/SignUpSuccess.container'
import SignUpVerification from '../pages/SignUpVerification/SignUpVerification.container'
import Travel from '../pages/Travel/Travel.container'
import TripSummary from '../pages/TripSummary/TripSummary.container'
import Trips from '../pages/Trips/Trips.container'

import { UserRole } from '../shared/functions/UserRole/userRoleManagement.types'

const routes = {
  aboutUs: {
    name: 'About Us',
    path: 'about-us',
    isAuthenticationRequired: false,
    component: null
  },

  adminDashboard: {
    name: 'Admin Dashboard',
    path: 'admin-dashboard',
    isAuthenticationRequired: true,
    userRole: [UserRole.admin],
    component: null
  },

  cart: {
    name: 'My Cart',
    path: 'cart',
    isAuthenticationRequired: false,
    userRole: [UserRole.shopper],
    component: Cart,
    isGreyBackground: true
  },

  orders: {
    name: 'My Orders',
    path: 'orders',
    isAuthenticationRequired: true,
    userRole: [UserRole.shopper],
    component: Orders,
    isGreyBackground: true
  },

  changePassword: {
    name: 'Change Password',
    path: 'change-password',
    isAuthenticationRequired: true,
    component: ChangePassword
  },

  contactUs: {
    name: 'Contact Us',
    path: 'contact-us',
    isAuthenticationRequired: false,
    component: Contact
  },

  cookiePolicy: {
    name: 'Cookie Policy',
    path: 'cookie-policy',
    isAuthenticationRequired: false,
    component: null
  },

  customerSupport: {
    name: 'Customer Support',
    path: 'customer-support',
    isAuthenticationRequired: false,
    component: null
  },

  deliveryPolicy: {
    name: 'Delivery Policy',
    path: 'delivery-policy',
    isAuthenticationRequired: false,
    component: null
  },

  flightDeals: {
    name: 'Flight Deals',
    path: 'flight-deals',
    isAuthenticationRequired: false,
    component: null
  },

  flightSearchResults: {
    name: 'Flight Search Results',
    path: 'flight-search-results',
    isAuthenticationRequired: false,
    userRole: [UserRole.traveler],
    component: FlightSearchResults
  },

  tripSummary: {
    name: 'Trip Summary',
    path: 'trip-summary',
    isAuthenticationRequired: false,
    userRole: [UserRole.traveler],
    component: TripSummary
  },

  forgetPassword: {
    name: 'Forget Password',
    path: 'forget-password',
    isAuthenticationRequired: false,
    component: ForgetPassword
  },

  forgetPasswordSuccess: {
    name: 'Forget Password Success',
    path: 'forget-password-success',
    isAuthenticationRequired: false,
    component: ForgetPasswordSuccess
  },

  helpCenter: {
    name: 'Help Center',
    path: 'help-center',
    isAuthenticationRequired: false,
    component: null
  },

  home: {
    name: 'Travel',
    path: '/',
    isAuthenticationRequired: false,
    userRole: [UserRole.traveler],
    component: Travel
  },

  hourrierExperience: {
    name: 'Hourrier Experience',
    path: 'hourrier-experience',
    isAuthenticationRequired: false,
    component: null
  },

  howHourrierWorks: {
    name: 'How Hourrier Works',
    path: 'how-hourrier-works',
    isAuthenticationRequired: false,
    component: null
  },

  login: {
    name: 'Login',
    path: 'login',
    isAuthenticationRequired: false,
    component: Login
  },

  notFound: {
    name: 'Not Found',
    path: 'not-found',
    isAuthenticationRequired: false,
    component: null
  },

  myTrips: {
    name: 'My Trips',
    path: 'my-trips',
    isAuthenticationRequired: true,
    userRole: [UserRole.traveler],
    component: Trips,
    isGreyBackground: true
  },

  offers: {
    name: 'Offers',
    path: 'offers',
    isAuthenticationRequired: false,
    userRole: [UserRole.traveler],
    component: Offers,
    isGreyBackground: true
  },

  privacyPolicy: {
    name: 'Privacy Policy',
    path: 'privacy-policy',
    isAuthenticationRequired: false,
    component: null
  },

  profile: {
    name: 'Profile',
    path: 'profile',
    isAuthenticationRequired: true,
    component: Profile,
    isGreyBackground: true
  },

  requests: {
    name: 'Requests',
    path: 'requests',
    isAuthenticationRequired: true,
    userRole: [UserRole.traveler],
    component: Requests,
    isGreyBackground: true
  },

  resetPassword: {
    name: 'Reset Password',
    path: 'reset-password',
    isAuthenticationRequired: false,
    component: null
  },

  resetForgottenPassword: {
    name: 'Reset Forgotten Password',
    path: 'reset-forgotten-password',
    isAuthenticationRequired: false,
    component: ResetForgottenPassword
  },

  sharedTrip: {
    name: 'Shared Trip',
    path: 'shared-trip',
    isAuthenticationRequired: false,
    userRole: [UserRole.shopper],
    component: SharedTrip,
    isGreyBackground: true
  },

  shop: {
    name: 'Shop',
    path: 'shop',
    isAuthenticationRequired: false,
    userRole: [UserRole.shopper],
    component: Shop
  },

  signUp: {
    name: 'Sign Up',
    path: 'sign-up',
    isAuthenticationRequired: false,
    component: SignUp
  },

  signUpSuccess: {
    name: 'Sign Up Success',
    path: 'sign-up-success',
    isAuthenticationRequired: false,
    component: SignUpSuccess
  },

  signUpVerification: {
    name: 'Sign Up Verification',
    path: 'sign-up-verification',
    isAuthenticationRequired: false,
    component: SignUpVerification
  },

  termsOfServices: {
    name: 'Terms of Services',
    path: 'terms-of-services',
    isAuthenticationRequired: false,
    component: null
  },

  trustAndSafety: {
    name: 'Trust and Safety',
    path: 'trust-and-safety',
    isAuthenticationRequired: false,
    component: null
  }
}

export default routes
