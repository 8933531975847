import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import { UserRole } from '../../../../shared/functions/UserRole/userRoleManagement.types'
import {
  GetFlightBookingShareLinkErrorResponse,
  GetFlightBookingShareLinkRequest,
  GetFlightBookingShareLinkSuccessResponse
} from './getFlightBookingShareLink.types'

export const getFlightBookingShareLinkApi = async (
  request: GetFlightBookingShareLinkRequest
): Promise<GetFlightBookingShareLinkSuccessResponse> => {
  const response = await configureRequest({
    url: urls.flights.getFlightBookingShareLink,
    method: 'GET',
    params: {
      id: request.id
    },
    headers: {
      'X-User-Role': UserRole.traveler
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as GetFlightBookingShareLinkSuccessResponse
  } else {
    throw response as GetFlightBookingShareLinkErrorResponse
  }
}

export default getFlightBookingShareLinkApi
