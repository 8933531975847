import React, { useEffect, useMemo, useState } from 'react'

import { Alert, Box, Typography } from '@mui/material'
import { FormikProps } from 'formik'
import * as Yup from 'yup'

import Form from '../../../../shared/components/Form/Form.component'

import { CityAndAirports } from '../../../../redux/states/flights/getCountriesAndCities/getCountriesAndCities.types'
import { getProfile } from '../../../../redux/states/profile/getProfile/getProfile.slice'
import { GetProfileData } from '../../../../redux/states/profile/getProfile/getProfile.types'
import { FormSection } from '../../../../shared/components/Form/Form.types'
import { useAppDispatch } from '../../../../shared/hooks/redux.hooks'
import styles from './DeliveryDetails.styles'
import { DeliveryDetailsProps, DeliveryDetailsValues } from './DeliveryDetails.types'

const useProfileData = () => {
  const [profileData, setProfileData] = useState<GetProfileData | null>(null)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(
      getProfile({
        onSuccess: (data: GetProfileData) => {
          setProfileData(data)
        }
      })
    )
  }, [dispatch])

  return profileData
}

const DeliveryDetails: React.FC<DeliveryDetailsProps> = (props) => {
  const { orderCountries, countriesAndCities, onFormChange, flightBooking } = props
  const profileData = useProfileData()
  const [cities, setCities] = useState<CityAndAirports[]>([])

  const countries = Array.from(new Set(orderCountries.map((country) => country.name))).join(', ')

  const handleCountryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const countryIataCode = event.target.value
    const country = countriesAndCities.find((country) => country.iataCountryCode === countryIataCode)
    let uniqueCities: CityAndAirports[] | undefined = []

    uniqueCities = country?.cities.reduce((accumulator: CityAndAirports[], city) => {
      if (!accumulator.some((existingCity) => existingCity.cityName === city.cityName)) {
        accumulator.push(city)
      }

      return accumulator
    }, [])

    if (flightBooking && country) {
      let deliveryToCity: CityAndAirports | undefined = undefined

      flightBooking.flightLegs.forEach((leg) => {
        const destinationCountryMatch = leg.flightDetails.destinationCountry?.iataCode === country?.iataCountryCode
        const originCountryMatch = leg.flightDetails.originCountry?.iataCode === country?.iataCountryCode
        let airportIataCode: string | undefined = undefined

        if (destinationCountryMatch) {
          airportIataCode = leg.flightDetails.destination
        } else if (originCountryMatch) {
          airportIataCode = leg.flightDetails.origin
        }

        if (airportIataCode) {
          const city = country.cities.find((city) =>
            city.airports.find((airport) => airport.iataCode === airportIataCode)
          )

          if (city) {
            deliveryToCity = city
          }
        }
      })

      if (deliveryToCity) {
        uniqueCities = [deliveryToCity]
      }
    }

    setCities(uniqueCities ?? [])
  }

  const initialValues: DeliveryDetailsValues = {
    firstName: profileData?.firstName || '',
    lastName: profileData?.lastName || '',
    phoneNumber: profileData?.mobileNumber || '',

    deliveringFrom: {
      countries: countries
    },

    deliveryTo: {
      country: '',
      city: ''
    },

    timePeriod: flightBooking ? 'Anytime' : null,
    deliveryInstructions: ''
  }

  const validationSchema = Yup.object({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    phoneNumber: Yup.string().required('Required'),

    deliveringFrom: Yup.object({
      countries: Yup.string().required('Required')
    }),

    deliveryTo: Yup.object({
      country: Yup.string().required('Required'),
      city: Yup.string().required('Required')
    }),

    timePeriod: Yup.string().required('Required'),
    deliveryInstructions: Yup.string()
  })

  const countryOptions = useMemo(() => {
    if (countriesAndCities) {
      const countryOptions = countriesAndCities?.map((country) => ({
        value: country.iataCountryCode,
        label: country.countryName !== 'Unknown' ? country.countryName : country.iataCountryCode
      }))

      if (flightBooking) {
        const flightBookingCountries = flightBooking.flightLegs.reduce((countryCodes, leg) => {
          if (
            leg.flightDetails.originCountry?.iataCode &&
            !countryCodes.includes(leg.flightDetails.originCountry?.iataCode)
          ) {
            countryCodes.push(leg.flightDetails.originCountry?.iataCode)
          }

          if (
            leg.flightDetails.destinationCountry?.iataCode &&
            !countryCodes.includes(leg.flightDetails.destinationCountry?.iataCode)
          ) {
            countryCodes.push(leg.flightDetails.destinationCountry?.iataCode)
          }

          return countryCodes
        }, [] as string[])

        return countryOptions.filter((option) => flightBookingCountries.includes(option.value))
      }

      return countryOptions
    } else {
      return []
    }
  }, [countriesAndCities, flightBooking])

  let formSections: FormSection[] = [
    {
      title: 'Personal Information',
      alignment: 'left',
      fields: [
        {
          name: 'firstName',
          label: 'First Name',
          type: 'text',
          grid: { xs: 12, sm: 6 },
          required: true,
          fullWidth: true
        },
        {
          name: 'lastName',
          label: 'Last Name',
          type: 'text',
          grid: { xs: 12, sm: 6 },
          required: true,
          fullWidth: true
        },
        {
          name: 'phoneNumber',
          label: 'Phone Number',
          type: 'tel',
          grid: { xs: 12, sm: 6 },
          required: true,
          fullWidth: true
        }
      ]
    },

    {
      title: 'Delivering From',
      alignment: 'left',
      fields: [
        {
          name: 'deliveringFrom.countries',
          label: 'Country',
          type: 'text',
          grid: { xs: 12, sm: 12 },
          required: true,
          fullWidth: true,
          readonly: true
        }
      ]
    },

    {
      title: 'Delivery To',
      alignment: 'left',
      fields: [
        {
          name: 'deliveryTo.country',
          label: 'Country',
          type: 'select',
          options: countryOptions,
          grid: { xs: 12, sm: 6 },
          required: true,
          fullWidth: true,
          onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
            handleCountryChange(event)
          }
        },

        {
          name: 'deliveryTo.city',
          label: 'City',
          type: 'select',
          options: cities.map((city) => ({
            value: city.iataCityCode,
            label: city.cityName
          })),
          grid: { xs: 12, sm: 6 },
          required: true,
          fullWidth: true,
          readonly: !cities
        }
      ]
    },

    {
      title: 'Delivery Details',
      alignment: 'left',
      fields: [
        {
          name: 'timePeriod',
          label: 'How long can you wait?',
          type: 'select',
          options: [
            { value: '1 week', label: '1 Week' },
            { value: '2 weeks', label: '2 Weeks' },
            { value: '3 weeks', label: '3 Weeks' },
            { value: 'Anytime', label: 'Anytime' }
          ],
          grid: { xs: 12, sm: 6 },
          required: true,
          fullWidth: true
        }
      ]
    },

    {
      title: 'Delivery Instructions',
      alignment: 'left',
      fields: [
        {
          label: '',
          name: 'deliveryInstructions',
          type: 'textarea',
          rows: 4,
          grid: { xs: 12 },
          placeholder: 'Enter the address for meet up location',
          fullWidth: true,
          preventAutoFocus: true
        }
      ]
    }
  ]

  if (flightBooking) {
    formSections = formSections.filter((section) => section.title !== 'Delivering From')
    formSections = formSections.filter((section) => section.title !== 'Delivery Details')
  }

  const handleSubmit = (values: unknown) => {
    const deliveryValues = values as DeliveryDetailsValues
    // eslint-disable-next-line no-console
    console.log(deliveryValues)
    // TODO: Handle form submission
  }

  const handleFormChange = (formikProps: Partial<FormikProps<unknown>>) => {
    if (formikProps.values && formikProps.isValid !== undefined) {
      onFormChange(formikProps.values as DeliveryDetailsValues, formikProps.isValid)
    }
  }

  return (
    <Box sx={styles.container}>
      <Typography variant="h5" sx={styles.title}>
        Recipient Details
      </Typography>

      <Alert severity="warning" sx={styles.warningBox}>
        <Typography sx={styles.warningText}>Please ensure the details entered below are correct.</Typography>
      </Alert>

      <Form
        key={profileData ? 'loaded' : 'loading'}
        hideButtons={true}
        buttonText=""
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        formSections={formSections}
        onFormChange={handleFormChange}
      />
    </Box>
  )
}

export default DeliveryDetails
