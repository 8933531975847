import theme from '../../../../shared/styles/themes/default.theme'

const styles = {
  container: {
    display: 'inline-flex',
    alignItems: 'flex-start',
    lineHeight: 1,
    fontSize: '32px'
  },

  currency: {
    marginRight: '0.1em',
    color: theme.palette.priceColor,
    fontWeight: 'bold'
  },

  dollarSign: {
    color: theme.palette.priceColor,
    fontWeight: 'bold'
  },

  dollarSignSuper: {
    position: 'relative',
    top: '-0.1em',
    fontSize: '60%',
    marginRight: '0.1em',
    color: theme.palette.priceColor,
    fontWeight: 'bold'
  },

  dollars: {
    fontWeight: 'bold',
    lineHeight: 1,
    color: theme.palette.priceColor
  },

  cents: {
    color: theme.palette.priceColor,
    fontWeight: 'bold'
  },

  centsSuper: {
    position: 'relative',
    top: '-0.1em',
    fontSize: '60%',
    color: theme.palette.priceColor,
    fontWeight: 'bold'
  },

  separator: {
    color: theme.palette.priceColor,
    fontWeight: 'bold'
  },

  noLeftMargin: {
    marginLeft: 0
  }
}

export default styles
