export const convertToPounds = function (weight: string, weightUnit: string): number {
  const value = parseFloat(weight)
  const unit = weightUnit

  switch (unit) {
    case 'oz':
    case 'ounce':
    case 'ounces':
      return value / 16
    case 'kg':
    case 'kgs':
    case 'kilogram':
    case 'kilograms':
      return value * 2.20462
    case 'g':
    case 'gram':
    case 'grams':
      return value * 0.00220462
    case 'lb':
    case 'lbs':
    case 'pound':
    case 'pounds':
    default:
      return value
  }
}
