import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { Box, Grid, Typography } from '@mui/material'

import Button from '../../shared/components/Button/Button.component'
import { EmptyState } from '../../shared/components/EmptyState/EmptyState.component'
import Stepper from '../../shared/components/Stepper/Stepper.component'
import HourrierCard from '../SharedTrip/components/HourrierCard/HourrierCard.component'
import { MarketplaceLinksInput } from '../Shop/components/MarketplaceLinksInput/MarketplaceLinksInput.component'
import CartStep from './components/CartStep/CartStep.component'
import DeliveryDetails from './components/DeliveryDetails/DeliveryDetails.component'
import { OrderSubmittedSuccessfully } from './components/OrderSubmittedSuccessfully/OrderSubmittedSuccessfully.component'
import Overview from './components/Overview/Overview.component'

import { isAuthenticated } from '../../networkRequests/apiClient/apiClient.functions'
import { Country } from '../../redux/states/flights/getAllFlightBookings/getAllFlightBookings.types'
import { getCountriesAndCities } from '../../redux/states/flights/getCountriesAndCities/getCountriesAndCities.slice'
import { CountryAndCities } from '../../redux/states/flights/getCountriesAndCities/getCountriesAndCities.types'
import {
  clearFlightBookingSuccess,
  getFlightBooking
} from '../../redux/states/flights/getFlightBooking/getFlightBooking.slice'
import { GetFlightBookingRequest } from '../../redux/states/flights/getFlightBooking/getFlightBooking.types'
import { clearItemDetailsSuccess } from '../../redux/states/items/getItemDetails/getItemDetails.slice'
import { Item } from '../../redux/states/items/getItemDetails/getItemDetails.types'
import { createOfferRequest } from '../../redux/states/offers/createOfferRequest/createOfferRequest.slice'
import {
  CreateOfferRequestRequest,
  DeliveryTimePeriod
} from '../../redux/states/offers/createOfferRequest/createOfferRequest.types'
import { RootState } from '../../redux/store/store.types'
import router from '../../router/functions/router.functions'
import routes from '../../router/routes.dictionary'
import { Step } from '../../shared/components/Stepper/Stepper.types'
import { useAppDispatch, useAppSelector } from '../../shared/hooks/redux.hooks'
import { CART_STORAGE_KEY, FLIGHT_BOOKING_ID_STORAGE_KEY, NAVIGATE_TO_CART_DELIVERY_KEY } from './Cart.dictionary'
import styles from './Cart.styles'
import { CartItem } from './Cart.types'
import { DeliveryDetailsValues } from './components/DeliveryDetails/DeliveryDetails.types'

const steps: Step[] = [{ label: 'Select Items' }, { label: 'Delivery Details' }, { label: 'Submit Order' }]

export const Cart: React.FC = () => {
  const [cartItems, setCartItems] = useState<CartItem[]>([])
  const [validCartItems, setValidCartItems] = useState<CartItem[]>([])
  const [activeStep, setActiveStep] = useState(0)
  const [deliveryDetails, setDeliveryDetails] = useState<DeliveryDetailsValues | null>(null)
  const [isDeliveryFormValid, setIsDeliveryFormValid] = useState(false)

  const itemData = useAppSelector((state: RootState) => state.getItemDetails.success)
  const countriesAndCities = useAppSelector((state: RootState) => state.getCountriesAndCities.success)
  const offerRequestLoading = useAppSelector((state: RootState) => state.createOfferRequest.loading)
  const flightBooking = useAppSelector((state: RootState) => state.getFlightBooking.success)

  const isInitialized = useRef(false)
  const navigationProps = router.getNavigationProps()
  const flightBookingId: number | null = navigationProps?.state?.flightBookingId
  const cart = navigationProps?.state?.cart
  const shouldNavigateToDelivery = navigationProps?.state?.shouldNavigateToDelivery

  const dispatch = useAppDispatch()
  const navigate = router.navigate()

  useEffect(() => {
    if (!flightBookingId || flightBooking?.data) return

    const getAllFlightBookingsPayload: GetFlightBookingRequest = {
      id: flightBookingId.toString()
    }

    dispatch(
      getFlightBooking({
        request: getAllFlightBookingsPayload
      })
    )
  }, [flightBookingId, dispatch, flightBooking?.data])

  useEffect(() => {
    dispatch(getCountriesAndCities({}))

    return () => {
      clearCartFromLocalStorage()
      dispatch(clearFlightBookingSuccess())
      dispatch(clearItemDetailsSuccess())
    }
  }, [dispatch, cart])

  useEffect(() => {
    if (cart?.length > 0) {
      setCartItems(cart)
    }
  }, [cart])

  const orderCountries = useMemo(() => {
    const countrySet = new Set<Country>()

    cartItems.forEach((item) => {
      if (item.country) {
        countrySet.add(item.country)
      }
    })

    setValidCartItems(cartItems.filter((item) => item.stockStatus === 'In Stock'))

    return Array.from(countrySet)
  }, [cartItems])

  const saveCartToLocalStorage = useCallback(
    (items: CartItem[]) => {
      localStorage.setItem(CART_STORAGE_KEY, JSON.stringify(items))
      localStorage.setItem(NAVIGATE_TO_CART_DELIVERY_KEY, 'true')

      if (flightBookingId) {
        localStorage.setItem(FLIGHT_BOOKING_ID_STORAGE_KEY, flightBookingId.toString())
      }
    },
    [flightBookingId]
  )

  const clearCartFromLocalStorage = () => {
    localStorage.removeItem(CART_STORAGE_KEY)
  }

  // const getCartFromLocalStorage = (): CartItem[] | null => {
  //   const storedCart = localStorage.getItem(CART_STORAGE_KEY)

  //   return storedCart ? JSON.parse(storedCart) : null
  // }

  // const initializeCartFromStorage = useCallback(() => {
  //   const storedCart = getCartFromLocalStorage()

  //   if (storedCart) {
  //     setCartItems(storedCart)
  //     clearCartFromLocalStorage()
  //   }
  // }, [])

  const initializeCartFromItemData = (items: Item[]) => {
    const newCartItems = items.map((item) => ({
      ...item,
      quantity: 1
    }))

    setCartItems(newCartItems)
  }

  const mergeNewItemsIntoCart = useCallback((newItems: Item[]) => {
    setCartItems((prevCartItems) => {
      const updatedCartItems = [...prevCartItems]

      newItems.forEach((newItem) => {
        const existingItemIndex = updatedCartItems.findIndex((item) => item.id === newItem.id)
        if (existingItemIndex === -1) {
          updatedCartItems.push({ ...newItem, quantity: 1 })
        }
      })

      return updatedCartItems
    })
  }, [])

  useEffect(() => {
    if (!isInitialized.current) {
      const initializedFromStorage = cart?.length > 0

      if (initializedFromStorage && shouldNavigateToDelivery) {
        setActiveStep(1)
      }

      if (!initializedFromStorage && itemData?.data?.processedItems) {
        initializeCartFromItemData(itemData.data.processedItems)
      }

      isInitialized.current = true
    } else if (itemData?.data?.processedItems) {
      mergeNewItemsIntoCart(itemData.data.processedItems)
    }

    // TODO: Determine if we need to save the cart to local storage when the user navigates away from the cart page
    // return () => {
    //   saveCartToLocalStorage(cartItems)
    // }
  }, [itemData, cart, shouldNavigateToDelivery, mergeNewItemsIntoCart])

  const updateItemQuantity = (itemId: number, newQuantity: number) => {
    setCartItems((prevItems) =>
      prevItems.map((item) => (item.id === itemId ? { ...item, quantity: newQuantity } : item))
    )
  }

  const handleContinueClick = () => {
    if (isAuthenticated()) {
      setActiveStep(1)
    } else {
      saveCartToLocalStorage(cartItems)
      navigate(routes.login.path)
    }
  }

  const handleDeliveryDetailsChange = (values: DeliveryDetailsValues, isValid: boolean) => {
    setDeliveryDetails(values)
    /*
      isValid is true when the form initially loads for some reason and becomes invalid as soon as
      the user clicks off the initial input
      so we check if the form is valid and the country is not empty to set the form to valid
    */
    setIsDeliveryFormValid(isValid && values.deliveryTo.country !== '')
  }

  const handleSubmitOrder = () => {
    if (deliveryDetails && isDeliveryFormValid) {
      const orderSubmission: CreateOfferRequestRequest = {
        offerDetails: validCartItems.map((item) => ({
          itemId: Number(item.id),
          itemQuantity: item.quantity
        })),

        shopperDetails: {
          firstName: deliveryDetails.firstName,
          lastName: deliveryDetails.lastName,
          phoneNumber: deliveryDetails.phoneNumber,

          deliveryTo: {
            country: deliveryDetails.deliveryTo.country,
            city: deliveryDetails.deliveryTo.city
          },

          timePeriod: deliveryDetails.timePeriod as DeliveryTimePeriod,
          deliveryInstructions: deliveryDetails.deliveryInstructions
        },

        flightBookingId: flightBookingId ?? flightBooking?.data?.id
      }

      const onSuccess = () => {
        setActiveStep(2)
        window.scrollTo(0, 0)
      }

      dispatch(
        createOfferRequest({
          request: orderSubmission,
          onSuccess
        })
      )
    }
  }

  const handleStepClick = (step: number) => {
    setActiveStep(step)
  }

  const handleViewOrders = useCallback(() => {
    navigate(routes.orders.path)
  }, [navigate])

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <CartStep
            cartItems={cartItems}
            updateItemQuantity={updateItemQuantity}
            isAuthenticated={isAuthenticated}
            handleContinueClick={handleContinueClick}
          />
        )

      case 1:
        return (
          <DeliveryDetails
            flightBooking={flightBooking?.data}
            onFormChange={handleDeliveryDetailsChange}
            orderCountries={orderCountries}
            countriesAndCities={countriesAndCities?.data as CountryAndCities[]}
          />
        )

      case 2:
        return <OrderSubmittedSuccessfully onViewOrders={handleViewOrders} />

      default:
        return null
    }
  }

  const renderStepHeader = (step: number) => {
    let headerTitle = ''
    let headerSubtitle = ''

    switch (step) {
      case 0:
        headerTitle = 'My Cart'
        break

      case 1:
        headerTitle = 'Delivery Details'
        headerSubtitle = 'Provide your delivery details and payment information.'
        break

      default:
        return null
    }

    return (
      <Box sx={styles.stepHeader}>
        <Typography variant="h4" sx={styles.title}>
          {headerTitle}
        </Typography>

        {
          <Typography variant="subtitle1" sx={styles.subtitle}>
            {headerSubtitle}
          </Typography>
        }
      </Box>
    )
  }

  const renderContent = () => {
    if (cartItems.length === 0) {
      return (
        <Box sx={styles.emptyStateContainer}>
          <EmptyState title="Your cart is empty" subtitle="Add items to your cart to get started." />
        </Box>
      )
    } else {
      return (
        <Stepper
          activeStep={activeStep}
          steps={steps}
          showStepper={activeStep !== 0}
          onStepClick={handleStepClick}
          stepperWidth="50%"
        >
          {renderStepHeader(activeStep)}

          <Grid container spacing={2} sx={styles.cartContent}>
            <Grid item xs={12} md={activeStep !== 2 ? 8 : 12} sx={styles.stepContent}>
              {renderStepContent(activeStep)}
            </Grid>

            {activeStep !== 2 && (
              <Grid item xs={12} md={4}>
                {flightBooking?.data && (
                  <Box sx={styles.hourrierCardContainer}>
                    <HourrierCard flightBooking={flightBooking?.data} />
                  </Box>
                )}

                <Overview items={cartItems} showTax={activeStep === 1} orderCountries={orderCountries} />

                {activeStep === 0 && (
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={styles.loginButton}
                    buttonType="primary"
                    text={isAuthenticated() ? 'Continue' : 'Login to continue'}
                    onClick={handleContinueClick}
                    disabled={validCartItems.length === 0}
                  />
                )}

                {activeStep === 1 && (
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={styles.loginButton}
                    buttonType="primary"
                    text={'Submit Order'}
                    loading={offerRequestLoading}
                    onClick={handleSubmitOrder}
                    disabled={!isDeliveryFormValid}
                    tooltipText={
                      !isDeliveryFormValid ? 'Please ensure all required fields are filled correctly.' : undefined
                    }
                  />
                )}
              </Grid>
            )}
          </Grid>
        </Stepper>
      )
    }
  }

  return (
    <Box sx={styles.container}>
      {activeStep < 1 && <MarketplaceLinksInput isCompact />}
      {renderContent()}
    </Box>
  )
}
export default Cart
