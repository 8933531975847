import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Box, Grid, Typography } from '@mui/material'

import Button from '../../shared/components/Button/Button.component'
import ShareFlightBookingLink from '../../shared/components/ShareFlightBookingLink/ShareFlightBookingLink.component'
import ShareLinkModal from '../../shared/components/ShareFlightBookingLinkModal/ShareFlightBookingLinkModal.component'
import FlightSummaryCard from './components/FlightSummaryCard/FlightSummaryCard.component'
import TripOverview from './components/TripOverview/TripOverview.component'

import { isAuthenticated } from '../../networkRequests/apiClient/apiClient.functions'
import { bookFlight, clearFlightBookingSuccess } from '../../redux/states/flights/bookFlight/bookFlight.slice'
import { FareOffer } from '../../redux/states/flights/getFlightFares/types/FareDetailsResponse.types'
import router from '../../router/functions/router.functions'
import routes from '../../router/routes.dictionary'
import date, { dateTimeFormats, formatDuration, formatTime } from '../../shared/functions/Date/date.functions'
import localStorage from '../../shared/functions/LocalStorage/localStorage'
import { useAppDispatch, useAppSelector } from '../../shared/hooks/redux.hooks'
import styles from './TripSummary.styles'
import { TripSummaryProps } from './TripSummary.types'

const TripSummary: React.FC<TripSummaryProps> = ({ tripFare, selectedOffers }) => {
  const [shareLinkModalOpen, setShareLinkModalOpen] = useState(false)
  const [loadingButton, setLoadingButton] = useState<string | null>(null)

  const fareOffer = useMemo(() => {
    return (router.getNavigationProps().state.fare as FareOffer) || tripFare
  }, [tripFare])

  const navigate = router.navigate()

  const { success: bookFlightSuccess, loading: bookFlightLoading } = useAppSelector((state) => state.bookFlight)
  const dispatch = useAppDispatch()

  useEffect(() => {
    return () => {
      dispatch(clearFlightBookingSuccess())
    }
  }, [dispatch])

  useEffect(() => {
    if (!fareOffer) return
  }, [fareOffer])

  const createFlightBooking = useCallback(
    (onSuccess: () => void) => {
      const requestPayload = {
        bookFlightRequest: {
          duffel_offer_ids: [fareOffer?.id],
          selectedOffersIds: selectedOffers?.map((offer) => offer.offerRequestId) ?? []
        },

        onSuccess: onSuccess
      }

      dispatch(bookFlight(requestPayload))
    },
    [fareOffer?.id, dispatch, selectedOffers]
  )

  const onBookFlight = useCallback(() => {
    setLoadingButton('bookFlight')

    if (fareOffer?.id) {
      if (bookFlightSuccess?.data?.shareLink) {
        setShareLinkModalOpen(true)
        setLoadingButton(null)
      } else {
        createFlightBooking(() => {
          setShareLinkModalOpen(true)
          setLoadingButton(null)
        })
      }
    }
  }, [fareOffer?.id, bookFlightSuccess?.data?.shareLink, createFlightBooking, setShareLinkModalOpen])

  const onContinueToRequests = useCallback(() => {
    setLoadingButton('continueToRequests')

    if (bookFlightSuccess?.data?.shareLink) {
      navigate(routes.requests.path)
    } else {
      createFlightBooking(() => {
        navigate(routes.requests.path)
        setLoadingButton(null)
      })
    }
  }, [navigate, createFlightBooking, bookFlightSuccess?.data?.shareLink])

  const onSkipToPayment = useCallback(() => {
    setLoadingButton('skipToPayment')

    if (bookFlightSuccess?.data?.shareLink) {
      navigate(routes.requests.path)
    } else {
      createFlightBooking(() => {
        navigate(routes.requests.path)
        setLoadingButton(null)
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, createFlightBooking])

  const onLogin = useCallback(() => {
    localStorage.setItem('fareOffer', fareOffer)
    localStorage.setItem('shouldRedirectToTripSummary', true)

    navigate(routes.login.path)
  }, [fareOffer, navigate])

  return (
    <Grid container sx={styles.gridContainer}>
      <Grid item xs={12} sm={9}>
        <Box sx={styles.container}>
          <Typography sx={styles.summaryTitle} variant="h5">
            Trip Summary
          </Typography>

          <Typography sx={styles.summarySubtitle} variant="body2">
            Prices are shown in US dollars and are per person inclusive of taxes and fees with the exception of baggage
            fees.
          </Typography>

          <Box sx={styles.tripDetailsContainer}>
            {fareOffer.slices.map((slice) => {
              return (
                <Box key={slice.id}>
                  <Typography variant="h6">
                    {slice.origin.iata_code} to {slice.destination.iata_code}
                  </Typography>

                  {slice.segments.map((segment) => (
                    <FlightSummaryCard
                      key={segment.id}
                      flightNumber={segment.operating_carrier_flight_number}
                      departingAt={date(segment.departing_at).format(dateTimeFormats.date.medium) ?? ''}
                      duration={formatDuration(segment.duration)}
                      airlineLogo={segment.operating_carrier.logo_symbol_url}
                      originAirportName={segment.origin.iata_code}
                      destinationAirportName={segment.destination.iata_code}
                      departureTime={formatTime(segment.departing_at)}
                      arrivalTime={formatTime(segment.arriving_at)}
                      travelClass={fareOffer.slices[0].fare_brand_name}
                    />
                  ))}
                </Box>
              )
            })}
          </Box>

          <ShareLinkModal
            shareLink={bookFlightSuccess?.data?.shareLink ?? ''}
            open={shareLinkModalOpen}
            onClose={() => setShareLinkModalOpen(false)}
          />
        </Box>
      </Grid>

      <Grid item xs={12} sm={3}>
        {!selectedOffers && (
          <ShareFlightBookingLink
            onPress={onBookFlight}
            showButton={isAuthenticated()}
            loading={loadingButton === 'bookFlight' && bookFlightLoading}
          />
        )}

        <Box sx={styles.tripOverviewContainer}>
          <TripOverview offerData={fareOffer} />
        </Box>

        {isAuthenticated() ? (
          <>
            <Button
              text="Continue to Requests"
              fullWidth
              variant={'outlined'}
              buttonType="primary"
              loading={loadingButton === 'continueToRequests' && bookFlightLoading}
              onClick={onContinueToRequests}
            />

            <Button
              text="Skip to Payment"
              fullWidth
              variant={'text'}
              buttonType="primary"
              loading={loadingButton === 'skipToPayment' && bookFlightLoading}
              onClick={onSkipToPayment}
            />
          </>
        ) : (
          <Button text="Login to continue" fullWidth variant={'contained'} buttonType="primary" onClick={onLogin} />
        )}
      </Grid>
    </Grid>
  )
}

export default TripSummary
