import { SxProps } from '@mui/material'

import theme from '../../shared/styles/themes/default.theme'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: theme.spacing(4),
    padding: theme.spacing(5, 10)
  },

  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between'
  },

  titleText: {
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '32px',
    textAlign: 'left',
    marginBottom: theme.spacing(1)
  } as SxProps,

  profileHeader: {
    display: { xs: 'none', md: 'flex' },
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    padding: theme.spacing(4),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    backgroundColor: theme.palette.background.default,
    borderRadius: '8px',
    boxShadow: theme.shadows[3],
    marginRight: theme.spacing(4)
  },

  profilePicture: {
    width: 170,
    height: 170,
    borderRadius: '50%',
    marginBottom: theme.spacing(6)
  },

  profileFormsContainer: {
    gap: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column'
  },

  changePasswordContainer: {
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderRadius: '8px',
    boxShadow: theme.shadows[3],
    padding: theme.spacing(4)
  },

  changePasswordText: {
    width: '70%',
    color: theme.palette.text.secondary,
    paddingRight: theme.spacing(2)
  }
}

export default styles
