import React from 'react'

import { Box, Typography } from '@mui/material'

import Carousel from '../Carousel/Carousel.component'
import Link from '../Link/Link.component'

import { PageBannerImage } from '../../../redux/states/firebaseRemoteConfig/firebaseRemoteConfig.types'
import { AutoCycleDuration } from '../../../shared/components/Carousel/Carousel.types'
import styles from './PageBanner.styles'
import { PageBannerProps } from './PageBanner.types'

const PageBanner: React.FC<PageBannerProps> = (props) => {
  const { images, title, subtitle, link } = props

  const carouselItems = images?.map((image: PageBannerImage, index: number) => (
    <Box key={index} sx={styles.imageContainer}>
      <img src={image.src} alt={image.alt} style={{ ...styles.image, objectFit: 'cover' }} />
    </Box>
  ))

  return (
    <Box sx={styles.bannerContainer}>
      <Box sx={styles.textContainer}>
        <Typography variant="h4" sx={styles.title}>
          {title}
        </Typography>

        <Typography variant="h6" sx={styles.subtitle}>
          {subtitle}
        </Typography>

        <Typography sx={styles.link}>
          <Link href={link.url} isUnderlined>
            {link.text}
          </Link>
        </Typography>
      </Box>

      <Box sx={styles.carouselContainer}>
        <Carousel items={carouselItems} autoCycle={true} autoCycleDuration={AutoCycleDuration.LONG} />
      </Box>
    </Box>
  )
}

export default PageBanner
