import { ReactNode } from 'react'

export function truncateString(str: string, maxLength: number = 50): string {
  if (!str) {
    return ''
  } else if (str.length <= maxLength) {
    return str
  } else {
    return str.slice(0, maxLength - 3) + '...'
  }
}

export function joinStringWithBullet(strings: (string | ReactNode)[]): ReactNode | string {
  return strings.reduce((prev, curr) => [prev, '\u00A0 • \u00A0', curr])
}

export function joinStringWithDash(strings: (string | ReactNode)[]): ReactNode | string {
  return strings.reduce((prev, curr) => [prev, '\u00A0 - \u00A0', curr])
}
