import React, { useCallback, useEffect, useState } from 'react'

import { Box, MenuItem, Select, SelectChangeEvent } from '@mui/material'

import { FlightBooking } from '../../../../redux/states/flights/getAllFlightBookings/getAllFlightBookings.types'
import date, { dateTimeFormats } from '../../../../shared/functions/Date/date.functions'
import { getFormattedTripDetails } from '../../../Requests/Requests.functions'
import styles from './AcceptOffer.styles'
import { AcceptOfferProps } from './AcceptOffer.types'

const AcceptOffer: React.FC<AcceptOfferProps> = ({ response, onConfirm }) => {
  const [selectedFlightBooking, setSelectedFlightBooking] = useState<number>(response.flightBookings[0].id)

  const handleSelectChange = useCallback(
    (event: SelectChangeEvent<number>) => {
      setSelectedFlightBooking(event.target.value as number)
      onConfirm(event.target.value as number)
    },
    [onConfirm]
  )

  useEffect(() => {
    handleSelectChange({ target: { value: response.flightBookings[0].id } } as SelectChangeEvent<number>)
  }, [handleSelectChange, response.flightBookings])

  return (
    <Box sx={styles.container}>
      <Select value={selectedFlightBooking} onChange={handleSelectChange} displayEmpty sx={styles.select}>
        <MenuItem value="" disabled>
          Select a flight booking
        </MenuItem>

        {response.flightBookings.map((booking: FlightBooking) => (
          <MenuItem key={booking.id} value={booking.id}>
            {getFormattedTripDetails(booking)}
            {`\u00A0 (${date(booking.flightLegs[0].flightDetails.departureTime).format(dateTimeFormats.date.medium)})`}
          </MenuItem>
        ))}
      </Select>
    </Box>
  )
}

export default AcceptOffer
