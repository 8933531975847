import React, { useCallback, useEffect, useState } from 'react'

import { Box, Button, Grid, Typography } from '@mui/material'

import { EmptyState } from '../EmptyState/EmptyState.component'
import Loader from '../Loader/Loader.component'
import OfferDealItem from './components/OfferDealItem.component'

import { isAuthenticated } from '../../../networkRequests/apiClient/apiClient.functions'
import { getAllFlightBookings } from '../../../redux/states/flights/getAllFlightBookings/getAllFlightBookings.slice'
import {
  FlightBooking,
  GetAllFlightBookingsRequest
} from '../../../redux/states/flights/getAllFlightBookings/getAllFlightBookings.types'
import router from '../../../router/functions/router.functions'
import routes from '../../../router/routes.dictionary'
import { useAppDispatch } from '../../../shared/hooks/redux.hooks'
import styles from './FlightDealsCarousel.styles'
import { FlightDealsCarouselProps } from './FlightDealsCarousel.types'

const FlightDealsCarousel: React.FC<FlightDealsCarouselProps> = ({ deals, onRefresh, isLoadingDeals }) => {
  const navigate = router.navigate()
  const dispatch = useAppDispatch()
  const [flightBookings, setFlightBookings] = useState<FlightBooking[]>([])

  useEffect(() => {
    const fetchFlightBookings = async () => {
      if (!isAuthenticated()) return
      const getAllFlightBookingsPayload: GetAllFlightBookingsRequest = {}

      const response = await dispatch(
        getAllFlightBookings({
          request: getAllFlightBookingsPayload
        })
      ).unwrap()

      setFlightBookings(response.data.results)
    }

    fetchFlightBookings()
  }, [dispatch])

  const handleExplore = useCallback(() => {
    navigate(routes.offers.path)
    window.scrollTo(0, 0)
  }, [navigate])

  return (
    <Box sx={styles.container}>
      <Box sx={styles.header}>
        <Box sx={styles.headerContentLeft}>
          <Typography sx={styles.title('left')} variant="h3">
            Flight Deals
          </Typography>
          <Typography sx={styles.subtitle('left')} variant="subtitle1">
            Browse orders from these cities to see where you will fly next.
          </Typography>
        </Box>
        <Box sx={styles.headerContentRight}>
          <Button variant="outlined" sx={styles.exploreButton} onClick={handleExplore}>
            Explore
          </Button>
        </Box>
      </Box>
      {isLoadingDeals ? (
        <Box sx={styles.loaderContainer}>
          <Loader text="Getting deals for you..." loading={isLoadingDeals} />
        </Box>
      ) : (
        <>
          {deals.length > 0 ? (
            <Grid container spacing={4} sx={styles.dealsContainer}>
              {deals.map((deal) => (
                <Grid item xs={12} sm={6} md={4} key={deal.offerRequestId}>
                  <OfferDealItem deal={deal} flightBookings={flightBookings} />
                </Grid>
              ))}
            </Grid>
          ) : (
            <EmptyState
              title="No deals found"
              subtitle="There are no deals available at the moment. Please check back later."
              button={{
                text: 'Refresh',
                onClick: onRefresh
              }}
            />
          )}
        </>
      )}
    </Box>
  )
}

export default FlightDealsCarousel
