import React, { useCallback, useEffect } from 'react'

import { Box } from '@mui/material'

import PaginatedPage from '../../shared/components/PaginatedPage/PaginatedPage.component'
import FlightBookingAccordion from './components/FlightBookingAccordion/FlightBookingAccordion.component'

import { getAllFlightBookings } from '../../redux/states/flights/getAllFlightBookings/getAllFlightBookings.slice'
import {
  FlightBooking,
  GetAllFlightBookingsRequest,
  OfferRequestDetails
} from '../../redux/states/flights/getAllFlightBookings/getAllFlightBookings.types'
import { RootState } from '../../redux/store/store.types'
import router from '../../router/functions/router.functions'
import routes from '../../router/routes.dictionary'
import { EmptyStateProps } from '../../shared/components/EmptyState/EmptyState.types'
import { FilterTab, PaginatedResponse, SearchBarProps } from '../../shared/components/PaginatedPage/PaginatedPage.types'
import {
  FilterSectionConfig,
  Filters
} from '../../shared/components/UniversalFilterSideBar/UniversalFilterSideBar.types'
import status from '../../shared/dictionaries/status.dictionaries'
import { useAppDispatch, useAppSelector } from '../../shared/hooks/redux.hooks'
import { convertToPounds } from '../Offers/Offers.functions'
import styles from './Requests.styles'

const Requests: React.FC = () => {
  const { loading } = useAppSelector((state: RootState) => state.getAllFlightBookings)
  const dispatch = useAppDispatch()
  const navigate = router.navigate()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const fetchFlightBookings = useCallback(
    async (page: number): Promise<PaginatedResponse<FlightBooking>> => {
      return new Promise((resolve) => {
        const getAllFlightBookingsPayload: GetAllFlightBookingsRequest = {
          page,
          size: 20
        }

        dispatch(
          getAllFlightBookings({
            request: getAllFlightBookingsPayload,
            onSuccess: (response) => {
              resolve({
                currentPage: response.data.currentPage,
                totalPages: response.data.totalPages,
                results: response.data.results
              })
            }
          })
        )
      })
    },
    [dispatch]
  )

  const filterTabs: FilterTab<unknown>[] = [
    {
      id: 'all',
      label: 'All',
      filterDef: (items: unknown[]) => items as FlightBooking[]
    },

    {
      id: 'accepted',
      label: 'Accepted',
      filterDef: (items: unknown[]) =>
        (items as FlightBooking[]).filter((item) =>
          item.flightLegs.some((leg) =>
            leg.offerRequestsDetails.some((offerRequest) =>
              offerRequest.itemRequestDetails.some(
                (detail) => detail.status.name === status.itemRequestStatus.acceptedByTraveler
              )
            )
          )
        )
    },

    {
      id: 'pending',
      label: 'Pending',
      filterDef: (items: unknown[]) =>
        (items as FlightBooking[]).filter((item) =>
          item.flightLegs.some((leg) =>
            leg.offerRequestsDetails.some((offerRequest) =>
              offerRequest.itemRequestDetails.some(
                (detail) => detail.status.name === status.itemRequestStatus.pendingAcceptanceByTraveler
              )
            )
          )
        )
    }

    // Todo: For Item tracking endpoint
    // {
    //   id: 'delivered',
    //   label: 'Delivered',
    //   filterDef: (items: unknown[]) =>
    //     (items as FlightBooking[]).filter((item) =>
    //       item.flightLegs.some((leg) =>
    //         leg.offerRequestsDetails.some((offerRequest) =>
    //           offerRequest.itemRequestDetails.some((detail) => detail.status.name === 'delivered')
    //         )
    //       )
    //     )
    // }
  ]

  const searchBar: SearchBarProps = {
    placeholder: 'Type any destination or item name',
    onSearchFilter: (items, query) => {
      const lowerQuery = query.toLowerCase()

      return (items as FlightBooking[]).filter((flightBooking) =>
        flightBooking.flightLegs.some(
          (leg) =>
            leg.flightDetails.destination.airport.name.toLowerCase().includes(lowerQuery) ||
            leg.flightDetails.destination.airport.iataCode.toLowerCase().includes(lowerQuery) ||
            leg.flightDetails.destination.city.name.toLowerCase().includes(lowerQuery) ||
            leg.flightDetails.destination.city.iataCode.toLowerCase().includes(lowerQuery) ||
            leg.flightDetails.destination.country.name.toLowerCase().includes(lowerQuery) ||
            leg.flightDetails.destination.country.iataCode.toLowerCase().includes(lowerQuery) ||
            leg.flightDetails.origin.airport.name.toLowerCase().includes(lowerQuery) ||
            leg.flightDetails.origin.airport.iataCode.toLowerCase().includes(lowerQuery) ||
            leg.flightDetails.origin.city.name.toLowerCase().includes(lowerQuery) ||
            leg.flightDetails.origin.city.iataCode.toLowerCase().includes(lowerQuery) ||
            leg.flightDetails.origin.country.name.toLowerCase().includes(lowerQuery) ||
            leg.flightDetails.origin.country.iataCode.toLowerCase().includes(lowerQuery) ||
            leg.offerRequestsDetails.some((offerRequest) =>
              offerRequest.itemRequestDetails.some(
                (detail) =>
                  detail.items.productName.toLowerCase().includes(lowerQuery) ||
                  detail.items.description.toLowerCase().includes(lowerQuery) ||
                  detail.items.title.toLowerCase().includes(lowerQuery)
              )
            )
        )
      )
    }
  }

  const emptyState: EmptyStateProps = {
    title: 'You have no trips available',
    subtitle: 'Book a trip and share your link with friends and family to get started.',
    button: {
      text: 'Book Trip',
      onClick: () => {
        navigate(routes.home.path)
      }
    }
  }

  const filterSections: (items: unknown[], filters: Filters) => FilterSectionConfig[] = useCallback(
    (items: unknown[]) => {
      const flightBookings = items as FlightBooking[]

      // Extract unique shoppers
      const shoppers = Array.from(
        new Set(
          flightBookings.flatMap((booking) =>
            booking.flightLegs.flatMap((leg) =>
              leg.offerRequestsDetails.map(
                (offerRequest) => `${offerRequest.shopperDetails.firstName} ${offerRequest.shopperDetails.lastName}`
              )
            )
          )
        )
      )

      // Extract weights and prices
      const weights = flightBookings.flatMap((booking) =>
        booking.flightLegs.flatMap((leg) =>
          leg.offerRequestsDetails.flatMap((offerRequest) =>
            offerRequest.itemRequestDetails.map((detail) =>
              convertToPounds(detail.items.weight, detail.items.weightUnit)
            )
          )
        )
      )

      let minWeight = Math.floor(Math.min(...weights))
      const maxWeight = Math.ceil(Math.max(...weights))

      if (minWeight <= 0) {
        minWeight = 1
      }

      const prices = flightBookings.flatMap((booking) =>
        booking.flightLegs.flatMap((leg) =>
          leg.offerRequestsDetails.flatMap((offerRequest) =>
            offerRequest.itemRequestDetails.map((detail) => +detail.items.price)
          )
        )
      )

      const minPrice = Math.min(...prices)
      const maxPrice = Math.max(...prices)

      const sections: FilterSectionConfig[] = [
        {
          id: 'shopper',
          title: 'Shopper',
          type: 'multi-select',
          options: [{ primaryValue: 'All' }, ...shoppers.map((shopper) => ({ primaryValue: shopper }))],
          defaultValue: ['All'],
          filterDef: (item, filters) => {
            if (filters.shopper && Array.isArray(filters.shopper) && !filters.shopper.includes('All')) {
              return (item as FlightBooking).flightLegs.some((leg) =>
                leg.offerRequestsDetails.some((offerRequest) => {
                  return (filters.shopper as string[]).includes(
                    `${offerRequest.shopperDetails.firstName} ${offerRequest.shopperDetails.lastName}`
                  )
                })
              )
            }

            return true
          }
        },
        {
          id: 'weight',
          title: 'Item Weight',
          type: 'slider',
          options: [{ primaryValue: minWeight }, { primaryValue: maxWeight }],
          unit: { symbol: '', label: 'lbs' },
          defaultValue: [minWeight, maxWeight],
          filterDef: (item, filters) => {
            const [minWeight, maxWeight] = filters.weight as number[]

            return (item as FlightBooking).flightLegs.some((leg) =>
              leg.offerRequestsDetails.some((offerRequest) =>
                offerRequest.itemRequestDetails.some((detail) => {
                  let itemWeight = convertToPounds(detail.items.weight, detail.items.weightUnit)
                  if (itemWeight < 1) {
                    itemWeight = 1
                  }

                  return itemWeight >= minWeight && itemWeight <= maxWeight
                })
              )
            )
          }
        },
        {
          id: 'priceRange',
          title: 'Flight Discount',
          type: 'slider',
          options: [{ primaryValue: minPrice }, { primaryValue: maxPrice }],
          unit: { symbol: '$', label: 'USD' },
          defaultValue: [minPrice, maxPrice],
          filterDef: (item, filters) => {
            const [minPrice, maxPrice] = filters.priceRange as number[]

            return (item as FlightBooking).flightLegs.some((leg) =>
              leg.offerRequestsDetails.some((offerRequest) =>
                offerRequest.itemRequestDetails.some(
                  (detail) => +detail.items.price >= minPrice && +detail.items.price <= maxPrice
                )
              )
            )
          }
        }
      ]

      return sections
    },
    []
  )

  const hideAllFilters = (items: unknown[]) => {
    const flightBookings = items as FlightBooking[]

    return flightBookings.every((booking) => booking.flightLegs.every((leg) => leg.offerRequestsDetails.length === 0))
  }

  // const renderFilteredList = (items: unknown[], filters: Filters) => {
  //   const flightBookings = items as FlightBooking[]

  //   return flightBookings.map((booking) => {
  //     const filteredLegs = booking.flightLegs.map((leg) => {
  //       const filteredOfferRequests = leg.offerRequestsDetails.filter((offerRequest) =>
  //         filters.shopper && Array.isArray(filters.shopper) && !filters.shopper.includes('All')
  //           ? (filters.shopper as string[]).includes(
  //               `${offerRequest.shopperDetails.firstName} ${offerRequest.shopperDetails.lastName}`
  //             )
  //           : true
  //       )

  //       return { ...leg, offerRequestsDetails: filteredOfferRequests }
  //     })

  //     return { ...booking, flightLegs: filteredLegs }
  //   })
  // }

  const filterMutateDef = (itemsToFilter: unknown[], filters: Filters): unknown[] => {
    const items = itemsToFilter as FlightBooking[]

    return items.map((booking) => ({
      ...booking,
      flightLegs: booking.flightLegs.map((leg) => ({
        ...leg,
        offerRequestsDetails: leg.offerRequestsDetails
          .map((offerRequest) => {
            const shopperFilter =
              filters.shopper && Array.isArray(filters.shopper) && !filters.shopper.includes('All')
                ? (filters.shopper as string[]).includes(
                    `${offerRequest.shopperDetails.firstName} ${offerRequest.shopperDetails.lastName}`
                  )
                : true

            if (!shopperFilter) {
              return null
            }

            const filteredItemRequestDetails = offerRequest.itemRequestDetails.filter((detail) => {
              let weightFilter = true
              if (filters.weight && Array.isArray(filters.weight)) {
                const [minWeight, maxWeight] = filters.weight as number[]
                let itemWeight = convertToPounds(detail.items.weight, detail.items.weightUnit)
                if (itemWeight < 1) {
                  itemWeight = 1
                }
                weightFilter = itemWeight >= (minWeight < 1 ? 1 : minWeight) && itemWeight <= maxWeight
              }

              let priceFilter = true
              if (filters.priceRange && Array.isArray(filters.priceRange)) {
                const [minPrice, maxPrice] = filters.priceRange as number[]
                const itemPrice = +detail.items.price
                priceFilter = itemPrice >= minPrice && itemPrice <= maxPrice
              }

              return weightFilter && priceFilter
            })

            return filteredItemRequestDetails.length > 0
              ? { ...offerRequest, itemRequestDetails: filteredItemRequestDetails }
              : null
          })
          .filter((offerRequest): offerRequest is OfferRequestDetails => offerRequest !== null)
      }))
    }))
  }

  return (
    <Box sx={styles.container}>
      <PaginatedPage
        title={{ text: 'Requests', id: 'Requests' }}
        subtitle="Select offers you want to carry and get a discount towards your flight."
        initialLoading={loading}
        emptyState={emptyState}
        hideFilters={hideAllFilters}
        filterMutateDef={filterMutateDef}
        fetchCall={fetchFlightBookings}
        renderListData={FlightBookingAccordion}
        horizontalFilterTabs={filterTabs}
        verticalFilters={filterSections}
        searchBar={searchBar}
      />
    </Box>
  )
}

export default Requests
