const styles = {
  container: {
    backgroundColor: '#fff',
    borderRadius: '8px',
    padding: '24px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
  },

  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '16px',
    textAlign: 'left'
  },

  subtitle: {
    fontSize: '16px',
    color: '#666',
    marginBottom: '24px'
  },

  sectionTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginTop: '24px',
    marginBottom: '16px'
  },

  warningBox: {
    backgroundColor: '#fff3e0',
    borderRadius: '4px',
    padding: '12px'
  },

  warningText: {
    color: '#e65100',
    fontSize: '14px'
  },

  formContainer: {
    '& .MuiFormControl-root': {
      marginBottom: '16px'
    },

    '& .MuiInputBase-root': {
      borderRadius: '4px'
    },

    '& .MuiInputLabel-root': {
      fontSize: '14px'
    },

    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#e0e0e0'
      },

      '&:hover fieldset': {
        borderColor: '#bdbdbd'
      },

      '&.Mui-focused fieldset': {
        borderColor: '#1976d2'
      }
    }
  }
}

export default styles
