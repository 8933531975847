import { useCallback, useEffect, useState } from 'react'

import { ShareOutlined } from '@mui/icons-material'
import { Box, Divider, Typography } from '@mui/material'

import Accordion from '../../../../shared/components/Accordion/Accordion.component'
import { EmptyState } from '../../../../shared/components/EmptyState/EmptyState.component'
import ShareFlightBookingLinkModal from '../../../../shared/components/ShareFlightBookingLinkModal/ShareFlightBookingLinkModal.component'
import ItemRequestAccordion from '../ItemRequestAccordion/ItemRequestAccordion.component'

import {
  FlightBooking,
  FlightLeg
} from '../../../../redux/states/flights/getAllFlightBookings/getAllFlightBookings.types'
import { getFlightBookingShareLink } from '../../../../redux/states/flights/getFlightBookingShareLink/getFlightBookingShareLink.slice'
import { GetFlightBookingShareLinkRequest } from '../../../../redux/states/flights/getFlightBookingShareLink/getFlightBookingShareLink.types'
import { RootState } from '../../../../redux/store/store.types'
import { ButtonProps } from '../../../../shared/components/Button/Button.types'
import { PaginatedRenderProps } from '../../../../shared/components/PaginatedPage/PaginatedPage.types'
import status from '../../../../shared/dictionaries/status.dictionaries'
import { useAppDispatch, useAppSelector } from '../../../../shared/hooks/redux.hooks'
import { getFormattedTripDetails } from '../../Requests.functions'
import styles from './FlightBookingAccordion.styles'

const FlightBookingAccordion = (props: PaginatedRenderProps) => {
  const flightBooking = props.item as FlightBooking
  const index = props.index
  const listLength = props.listLength

  const [updatedFlightBooking, setUpdatedFlightBooking] = useState<FlightBooking>(flightBooking)
  const [shareLinkModalOpen, setShareLinkModalOpen] = useState(false)
  const [loadingButton, setLoadingButton] = useState<string | null>(null)
  const [isFirstAccordionExpanded, setIsFirstAccordionExpanded] = useState(index === 0 && listLength === 1)

  const { success: getAllFlightBookingsSuccess } = useAppSelector((state: RootState) => state.getAllFlightBookings)
  const { success: getFlightBookingShareLinkSuccess, loading: getFlightBookingShareLinkLoading } = useAppSelector(
    (state: RootState) => state.getFlightBookingShareLink
  )

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (index === 0 && listLength !== 1) {
      setIsFirstAccordionExpanded(false)
    }
  }, [index, listLength])

  const refreshFlightBookings = useCallback(() => {
    const relatedFlightBooking = getAllFlightBookingsSuccess?.data.results.find(
      (_flightBooking) => _flightBooking.id === flightBooking.id
    )

    setUpdatedFlightBooking(relatedFlightBooking ?? flightBooking)
  }, [getAllFlightBookingsSuccess, flightBooking])

  useEffect(() => {
    if (getAllFlightBookingsSuccess) {
      refreshFlightBookings()
    }
  }, [getAllFlightBookingsSuccess, refreshFlightBookings])

  const getShareLink = () => {
    setLoadingButton(updatedFlightBooking.id.toString())

    const request: GetFlightBookingShareLinkRequest = {
      id: updatedFlightBooking.id.toString()
    }

    const onSuccess = () => {
      setShareLinkModalOpen(true)
      setLoadingButton(null)
    }

    dispatch(getFlightBookingShareLink({ request, onSuccess }))
  }

  const hasOfferRequestDetails = updatedFlightBooking.flightLegs.some(
    (leg) => leg.offerRequestsDetails && leg.offerRequestsDetails.length > 0
  )

  const getAccordionHeader = () => {
    const tripDetails = getFormattedTripDetails(updatedFlightBooking)

    const isBookFlightDisabled = updatedFlightBooking.flightLegs.some((leg) =>
      leg.offerRequestsDetails.some((offerRequest) =>
        offerRequest.itemRequestDetails.some(
          (detail) =>
            detail.status.name === status.itemRequestStatus.pendingAcceptanceByTraveler ||
            detail.status.name === status.itemRequestStatus.reassignedByShopper
        )
      )
    )

    const accordionButtons: ButtonProps[] = [
      {
        text: 'Share Link',
        onClick: getShareLink,
        buttonType: 'secondary',
        loading: loadingButton === updatedFlightBooking.id.toString() && getFlightBookingShareLinkLoading,
        icon: <ShareOutlined />
      },
      {
        text: 'Book Flight',
        onClick: () => {
          // TODO: Book Flight
        },
        buttonType: 'primary',
        disabled: isBookFlightDisabled,
        tooltipText: isBookFlightDisabled
          ? 'Must action all item requests before you can book the flight.'
          : 'Must book flight before you can buy the items on Amazon.'
      }
    ]

    const accordionHeader = {
      mainContent: (
        <Typography variant="h6" sx={styles.accordionHeaderMainContent}>
          {tripDetails}
        </Typography>
      ),
      buttons: accordionButtons
    }

    return accordionHeader
  }

  const renderFlightLegHeader = (leg: FlightLeg) => (
    <Box key={leg.flightDetails.id} style={styles.legHeader} my={2}>
      <Typography sx={styles.legHeaderTitle}>
        {`${leg.flightDetails.origin.airport.iataCode} → ${leg.flightDetails.destination.airport.iataCode}`}
      </Typography>
    </Box>
  )

  const renderFlightLegDetails = (leg: FlightLeg) => (
    <Box key={leg.flightDetails.id} sx={styles.legDetails}>
      {renderFlightLegHeader(leg)}
      <ItemRequestAccordion flightLeg={leg} flightBookingStatus={updatedFlightBooking.status.name} />
      {updatedFlightBooking.flightLegs.indexOf(leg) !== updatedFlightBooking.flightLegs.length - 1 && (
        <Divider sx={styles.divider} />
      )}
    </Box>
  )

  const renderDetails = () => {
    if (!hasOfferRequestDetails) {
      return (
        <Box style={styles.emptyStateContainer}>
          <EmptyState
            title={'No Offers Available'}
            subtitle={
              'Share your flight itinerary to your friends and receive offers and earn discounts towards your flight.'
            }
            button={{
              text: 'Share Link',
              icon: <ShareOutlined />,
              loading: loadingButton === updatedFlightBooking.id.toString() && getFlightBookingShareLinkLoading,
              onClick: getShareLink
            }}
          />
        </Box>
      )
    } else {
      return <Box>{updatedFlightBooking.flightLegs.map(renderFlightLegDetails)}</Box>
    }
  }

  return (
    <>
      <Accordion header={getAccordionHeader()} body={renderDetails()} isExpanded={isFirstAccordionExpanded} />

      <ShareFlightBookingLinkModal
        shareLink={getFlightBookingShareLinkSuccess?.data?.shareLink ?? ''}
        open={shareLinkModalOpen}
        onClose={() => setShareLinkModalOpen(false)}
      />
    </>
  )
}

export default FlightBookingAccordion
