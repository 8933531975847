import React from 'react'

import { Box, Divider, Typography } from '@mui/material'

import date, { dateTimeFormats } from '../../../../shared/functions/Date/date.functions'
import styles from './MiniTripDetails.styles'
import { MiniTripDetailsProps } from './MiniTripDetails.types'

const MiniTripDetails: React.FC<MiniTripDetailsProps> = ({ flightBooking }) => {
  return (
    <Box sx={styles.container}>
      <Typography variant="h6" sx={styles.title}>
        Trip Details
      </Typography>

      <Box sx={styles.tripDetails}>
        {flightBooking.flightLegs.map((leg, index) => (
          <Box key={index} sx={styles.legContainer}>
            <Box sx={styles.legDetails}>
              <Typography variant="h6" sx={styles.route}>
                {leg.flightDetails.origin} → {leg.flightDetails.destination}
              </Typography>

              <Typography variant="body2" sx={styles.arrivalLabel}>
                Arrives on&nbsp;
                <Typography variant="body2" sx={styles.arrivalDate}>
                  {date(leg.flightDetails.arrivalTime).format(dateTimeFormats.date.medium)}
                </Typography>
              </Typography>
            </Box>

            {index < flightBooking.flightLegs.length - 1 && <Divider sx={styles.verticalSeparator} />}
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default MiniTripDetails
