import React, { useState } from 'react'

import { Box, Container, Divider, Typography } from '@mui/material'
import { FormikProps } from 'formik'
import * as Yup from 'yup'

import Form from '../../shared/components/Form/Form.component'
import Link from '../../shared/components/Link/Link.component'

import { signUp } from '../../redux/states/auth/signUp/signUp.slice'
import { SignUpRequest } from '../../redux/states/auth/signUp/signUp.types'
import router from '../../router/functions/router.functions'
import routes from '../../router/routes.dictionary'
import { FormField } from '../../shared/components/Form/Form.types'
import { logEvent } from '../../shared/functions/Logger/logger.functions'
import { useAppDispatch, useAppSelector } from '../../shared/hooks/redux.hooks'
import styles from './SignUp.styles'
import { SignUpFormValues } from './SignUp.types'

const SignUp: React.FC = () => {
  const dispatch = useAppDispatch()
  const { loading } = useAppSelector((state) => state.signUp)
  const [formikInstance, setFormikInstance] = useState<FormikProps<SignUpFormValues>>(
    {} as FormikProps<SignUpFormValues>
  )
  const navigate = router.navigate()

  const initialValues: SignUpFormValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    wantsDealsAndDiscounts: false
  }

  const validationSchema = Yup.object({
    firstName: Yup.string().required('Required').min(2, 'Must be at least 3 characters'),
    lastName: Yup.string().required('Required').min(2, 'Must be at least 3 characters'),
    email: Yup.string().email('Invalid email address').required('Required'),
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters long')
      .matches(/[A-Z]/, 'Password must contain one uppercase letter')
      .matches(/[a-z]/, 'Password must contain one lowercase letter')
      .matches(/[0-9]/, 'Password must contain one number')
      .required('Required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), ''], 'Passwords must match')
      .required('Required')
  })

  const onSubmit = (values: SignUpFormValues) => {
    const signUpRequest: SignUpRequest = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      password: values.password,
      wantsDealsAndDiscounts: values.wantsDealsAndDiscounts
    }

    const onSuccess = () => {
      navigate(routes.signUpSuccess.path, { replace: true })
    }

    dispatch(signUp({ signUpRequest, onSuccess }))
    logEvent('sign_up_button_clicked', {
      email: values.email
    })
  }

  const formFields: FormField[] = [
    {
      name: 'firstName',
      label: 'First Name',
      type: 'text',
      autoComplete: 'given-name',
      placeholder: 'John',
      grid: { xs: 12, sm: 6 },
      required: true,
      fullWidth: true
    },
    {
      name: 'lastName',
      label: 'Last Name',
      type: 'text',
      autoComplete: 'family-name',
      placeholder: 'Doe',
      grid: { xs: 12, sm: 6 },
      required: true,
      fullWidth: true
    },
    {
      name: 'email',
      label: 'Email Address',
      type: 'email',
      autoComplete: 'email',
      placeholder: 'john.doe@example.com',
      grid: { xs: 12 },
      required: true,
      fullWidth: true
    },
    {
      name: 'password',
      label: 'Password',
      type: 'password',
      autoComplete: 'new-password',
      grid: { xs: 12 },
      required: true,
      fullWidth: true,
      helperText:
        (formikInstance?.touched?.password && !!formikInstance?.errors?.password) ||
        (!!formikInstance?.values?.password && formikInstance?.errors?.password)
          ? 'Password must be at least 8 characters long, contain one uppercase letter, one lowercase letter, and one number.'
          : ''
    },
    {
      name: 'confirmPassword',
      label: 'Confirm Password',
      type: 'password',
      autoComplete: 'new-password',
      grid: { xs: 12 },
      required: true,
      fullWidth: true
    },
    {
      name: 'wantsDealsAndDiscounts',
      label: 'I would like to hear about Hourrier deals and discounts',
      type: 'switch',
      grid: { xs: 12 },
      required: false,
      fullWidth: true
    }
  ]

  return (
    <Container component="main" maxWidth="sm" sx={styles.container}>
      <Form
        title="Create your Hourrier Account."
        subtitle="Start earning dollars while you travel. Get more out of your trips with Hourrier."
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values: unknown) => onSubmit(values as SignUpFormValues)}
        onFormChange={(formik: Partial<FormikProps<unknown>>) =>
          setFormikInstance(formik as FormikProps<SignUpFormValues>)
        }
        formFields={formFields}
        loading={loading}
        buttonText="Sign Up"
      />

      <Typography sx={styles.loginTextContainer}>
        <Typography sx={styles.loginText}>Already have an account?</Typography>

        <Typography sx={styles.loginText}>
          <Link href={routes.login.path} ml={0.5} showColor>
            Log In
          </Link>
        </Typography>
      </Typography>

      <Box sx={styles.termsContainer}>
        <Divider sx={styles.divider} />

        <Typography sx={styles.termsTextContainer}>
          <Typography sx={styles.termsText}>By signing up, you agree to our </Typography>

          <Typography sx={styles.termsText}>
            <Link href={routes.termsOfServices.path} ml={0.5} mr={0.5} showColor isUnderlined>
              Terms of Service
            </Link>
          </Typography>

          <Typography sx={styles.termsText}>and</Typography>

          <Typography sx={styles.termsText}>
            <Link href={routes.privacyPolicy.path} ml={0.5} mr={0.5} showColor isUnderlined>
              Privacy Policy.
            </Link>
          </Typography>
        </Typography>
      </Box>
    </Container>
  )
}

export default SignUp
