import React, { useEffect } from 'react'

import { ExpandMore } from '@mui/icons-material'
import { Box, Divider, Grid, Typography } from '@mui/material'

import Accordion from '../../../../shared/components/Accordion/Accordion.component'
import Link from '../../../../shared/components/Link/Link.component'
import TrackingDetails from '../../../../shared/components/TrackingDetails/TrackingDetails.component'

import { acceptItemPriceChange } from '../../../../redux/states/items/acceptItemPriceChange/acceptItemPriceChange.slice'
import { AcceptItemPriceChangeRequestPayload } from '../../../../redux/states/items/acceptItemPriceChange/acceptItemPriceChange.types'
import { cancelItemRequest } from '../../../../redux/states/offers/cancelItemRequest/cancelItemRequest.slice'
import { CancelItemRequestRequestPayload } from '../../../../redux/states/offers/cancelItemRequest/cancelItemRequest.types'
import {
  clearItemRequestTrackingDetailsSuccess,
  getItemRequestTrackingDetails
} from '../../../../redux/states/offers/getItemRequestTrackingDetails/getItemRequestTrackingDetails.slice'
import { GetItemRequestTrackingDetailsRequestPayload } from '../../../../redux/states/offers/getItemRequestTrackingDetails/getItemRequestTrackingDetails.types'
import { reassignItemRequest } from '../../../../redux/states/offers/reassignItemRequest/reassignItemRequest.slice'
import { ReassignItemRequestRequestPayload } from '../../../../redux/states/offers/reassignItemRequest/reassignItemRequest.types'
import { RootState } from '../../../../redux/store/store.types'
import { AccordionAlertProps, AccordionButtonProps } from '../../../../shared/components/Accordion/Accordion.types'
import status from '../../../../shared/dictionaries/status.dictionaries'
import date, { dateTimeFormats } from '../../../../shared/functions/Date/date.functions'
import { UserRole } from '../../../../shared/functions/UserRole/userRoleManagement.types'
import { getUserRole } from '../../../../shared/functions/UserRole/userRoleManagment.functions'
import { useAppDispatch, useAppSelector } from '../../../../shared/hooks/redux.hooks'
import styles from './ItemRequestAccordion.styles'
import { ItemRequestAccordionProps } from './ItemRequestAccordion.types'

const ItemRequestAccordion: React.FC<ItemRequestAccordionProps> = (props) => {
  const { itemRequest, deliveryDetails, refreshOfferRequests } = props

  const [expandedItemId, setExpandedItemId] = React.useState<string | null>(null)
  const [loadingItemId, setLoadingItemId] = React.useState<string | null>(null)
  const [updatedItemRequest, setUpdatedItemRequest] = React.useState(itemRequest)
  const [alerts, setAlerts] = React.useState<{ [key: string]: AccordionAlertProps | null }>({})

  const { loading: cancelItemRequestLoading } = useAppSelector((state: RootState) => state.cancelItemRequest)
  const { loading: reassignItemRequestLoading } = useAppSelector((state: RootState) => state.reassignItemRequest)

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (updatedItemRequest.itemDetails.priceChangeDetails.length > 0) {
      const latestPriceChangeDetail = updatedItemRequest.itemDetails.priceChangeDetails[0]

      if (latestPriceChangeDetail) {
        const itemPriceIncreased =
          latestPriceChangeDetail.changed &&
          latestPriceChangeDetail.newValue > latestPriceChangeDetail.oldValue &&
          latestPriceChangeDetail.newValue !== parseFloat(updatedItemRequest.itemDetails.price) // TODO: Change to acceptedItemPrice

        if (itemPriceIncreased) {
          if (latestPriceChangeDetail.acceptedByShopper === false) {
            setAlerts((prev) => ({
              ...prev,
              [updatedItemRequest.itemRequestId]: {
                message: `Price changed on Amazon from $${latestPriceChangeDetail.oldValue} to $${latestPriceChangeDetail.newValue}.`,
                type: 'warning',
                tag: 'price-increased'
              }
            }))
          } else {
            setAlerts((prev) => {
              const newAlerts = { ...prev }
              delete newAlerts[updatedItemRequest.itemRequestId]

              return newAlerts
            })
          }
        }
      }

      const latestAvailabilityChangeDetail = updatedItemRequest.itemDetails.availabilityChangeDetails[0]

      if (latestAvailabilityChangeDetail) {
        const itemOutOfStock =
          latestAvailabilityChangeDetail.changed &&
          latestAvailabilityChangeDetail.newValue === 'Out of Stock' &&
          latestAvailabilityChangeDetail.oldValue === 'In Stock'

        if (itemOutOfStock) {
          setAlerts((prev) => ({
            ...prev,
            [updatedItemRequest.itemRequestId]: {
              message: 'The item is currently out of stock on Amazon.',
              type: 'warning',
              tag: 'out-of-stock'
            }
          }))
        }
      }
    }
  }, [updatedItemRequest])

  const handleAccordionExpansion = (isExpanded: boolean, accordionKey: string | number | undefined) => {
    if (isExpanded) {
      setExpandedItemId(accordionKey?.toString() ?? null)
    } else {
      setExpandedItemId(null)
    }
  }

  const handleCancel = () => {
    const payload: CancelItemRequestRequestPayload = {
      request: {
        itemRequestId: itemRequest.itemRequestId.toString()
      },

      onSuccess: () => {
        setUpdatedItemRequest({
          ...itemRequest,
          itemDetails: {
            ...itemRequest.itemDetails,
            status: {
              ...itemRequest.itemDetails.status,
              name: status.itemRequestStatus.cancelledByShopper
            }
          }
        })

        setLoadingItemId(null)
      }
    }

    setLoadingItemId(itemRequest.itemRequestId.toString())
    dispatch(cancelItemRequest(payload))
  }

  const handleFindTraveler = () => {
    const payload: ReassignItemRequestRequestPayload = {
      request: {
        itemRequestId: itemRequest.itemRequestId.toString()
      },

      onSuccess: () => {
        refreshOfferRequests(() => {
          setUpdatedItemRequest({
            ...itemRequest,
            itemDetails: {
              ...itemRequest.itemDetails,
              status: {
                ...itemRequest.itemDetails.status,
                name: status.itemRequestStatus.reassignedByShopper
              }
            }
          })

          setLoadingItemId(null)
        })
      }
    }

    setLoadingItemId(itemRequest.itemRequestId.toString())
    dispatch(reassignItemRequest(payload))
  }

  const handleAcceptPriceChange = () => {
    const payload: AcceptItemPriceChangeRequestPayload = {
      request: {
        itemRequestId: itemRequest.itemRequestId.toString()
      },

      onSuccess: () => {
        setUpdatedItemRequest({
          ...itemRequest,
          itemDetails: {
            ...itemRequest.itemDetails,
            price: updatedItemRequest.itemDetails.priceChangeDetails[0].newValue.toString()
          }
        })
      }
    }

    dispatch(acceptItemPriceChange(payload))
  }

  const handleTrackButtonPress = (id: number) => {
    const itemId = id.toString()

    if (expandedItemId === itemId) {
      setExpandedItemId(null)
      dispatch(clearItemRequestTrackingDetailsSuccess())
    } else {
      setExpandedItemId(itemId)

      const payload: GetItemRequestTrackingDetailsRequestPayload = {
        request: {
          itemRequestId: itemId
        }
      }

      dispatch(getItemRequestTrackingDetails(payload))
    }
  }

  const renderItemDetails = () => {
    const itemRequestAccordionHeaderButtons: AccordionButtonProps[] = []
    const priceIncreaseDetected = alerts[updatedItemRequest.itemRequestId]?.tag === 'price-increased'

    const cancelItemRequestButton: AccordionButtonProps = {
      text: 'Cancel',
      buttonType: 'tertiary',
      onClick: handleCancel,
      loading: cancelItemRequestLoading && loadingItemId === updatedItemRequest.itemRequestId.toString()
    }

    switch (updatedItemRequest?.itemDetails.status?.name) {
      // case status.itemRequestStatus.acceptedByTraveler:
      case status.deliveryStatus.purchasedByTraveler:
      case status.deliveryStatus.inTransitToTraveler:
      case status.deliveryStatus.deliveredToTravelerAddress:
      case status.deliveryStatus.confirmedReceivedByTraveler:
      case status.deliveryStatus.confirmedInDestinationCountry:
      case status.deliveryStatus.confirmedDeliveredToShopperByTraveler:
        itemRequestAccordionHeaderButtons.push({
          text: 'Track',
          icon: (
            <ExpandMore
              style={{
                transform:
                  expandedItemId === updatedItemRequest.itemRequestId.toString() ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 0.3s'
              }}
            />
          ),
          iconPosition: 'end',
          onClick: () => handleTrackButtonPress(updatedItemRequest.itemRequestId),
          expandAccordion: true,
          buttonType: 'primary'
        })

        break

      case status.itemRequestStatus.deniedByTraveler:
        itemRequestAccordionHeaderButtons.push(cancelItemRequestButton)

        itemRequestAccordionHeaderButtons.push({
          text: 'Find Traveler',
          buttonType: 'primary',
          onClick: handleFindTraveler,
          loading: reassignItemRequestLoading && loadingItemId === updatedItemRequest.itemRequestId.toString()
        })
        break

      case status.itemRequestStatus.cancelledByShopper:
        itemRequestAccordionHeaderButtons.push({
          text: 'Canceled',
          buttonType: 'tertiary',
          disabled: true
        })
        break

      case status.deliveryStatus.confirmedDeliveredToShopperByShopper:
        itemRequestAccordionHeaderButtons.push({
          text: getUserRole() === UserRole.shopper ? 'Received' : 'Delivered',
          buttonType: 'primary',
          disabled: true
        })

        break

      default:
        itemRequestAccordionHeaderButtons.push(cancelItemRequestButton)
        break
    }

    if (priceIncreaseDetected) {
      itemRequestAccordionHeaderButtons.push({
        text: 'Accept Change',
        buttonType: 'primary',
        onClick: handleAcceptPriceChange
      })
    }

    const itemRequestAccordionHeader = {
      mainContent: (
        <Grid item key={updatedItemRequest.itemRequestId} sx={styles.itemRequestContainer}>
          <Grid item md={6} sx={styles.itemDetailsContainer}>
            <Box sx={styles.itemImageContainer}>
              <img
                src={updatedItemRequest.itemDetails.images[0]}
                alt={updatedItemRequest.itemDetails.title}
                style={styles.itemImage}
              />
            </Box>

            <Box sx={styles.itemDetailsInfoContainer}>
              <Typography sx={styles.itemTitle}>{updatedItemRequest.itemDetails.title}</Typography>

              <Link sx={styles.itemDetailsLinkText} href={updatedItemRequest.itemDetails.internal_url} target="_blank">
                View on Amazon
              </Link>
            </Box>
          </Grid>

          <Divider orientation="vertical" flexItem sx={styles.itemDetailsDivider} />

          <Grid item md={2} sx={styles.shopperInfoContainer}>
            <Typography sx={styles.rowTitleText}>Quantity</Typography>

            <Typography sx={styles.rowValueText}>
              {updatedItemRequest.itemDetails.quantity} item{updatedItemRequest.itemDetails.quantity > 1 ? 's' : ''}
            </Typography>
          </Grid>

          <Divider orientation="vertical" flexItem sx={styles.itemDetailsDivider} />

          <Grid item md={4}>
            <Typography sx={styles.rowTitleText}>Price</Typography>

            <Typography sx={styles.rowValueText}>
              {`$${parseFloat(updatedItemRequest.itemDetails.price).toFixed(2)}`}
            </Typography>

            <Typography sx={styles.amazonPriceText}>
              Amazon price as of{' '}
              {date(updatedItemRequest.itemDetails.priceAt).format(
                `${dateTimeFormats.date.medium} ${dateTimeFormats.time.short}`
              )}{' '}
              EST
            </Typography>
          </Grid>
        </Grid>
      ),
      buttons: itemRequestAccordionHeaderButtons
    }

    const getTrackingDetails = () => (
      <TrackingDetails
        itemRequestId={updatedItemRequest.itemRequestId}
        isOpen={expandedItemId === updatedItemRequest.itemRequestId.toString()}
        key={updatedItemRequest.itemRequestId}
        refreshMainList={refreshOfferRequests}
        destinationCity={deliveryDetails.city.name}
      />
    )

    // TODO: Add logic to check item tracking status
    const isTrackingDetailsAccessible =
      updatedItemRequest.itemDetails.status.name === status.itemRequestStatus.acceptedByTraveler

    return (
      <Accordion
        header={itemRequestAccordionHeader}
        body={getTrackingDetails()}
        handleExpansion={handleAccordionExpansion}
        noExpandIcon
        noExpandBackgroundColor
        preventExpandChange={!isTrackingDetailsAccessible}
        alert={alerts[updatedItemRequest.itemRequestId] || undefined}
        accordionKey={updatedItemRequest.itemRequestId}
      />
    )
  }

  return <Box>{renderItemDetails()}</Box>
}

export default ItemRequestAccordion
