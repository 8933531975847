import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../shared/types/api.types'
import { bookFlightApi } from './bookFlight.api'
import bookFlightInitialState from './bookFlight.initialState'
import { BookFlightErrorResponse, BookFlightRequest } from './bookFlight.types'

export const bookFlight = createAsyncThunk(
  urls.flights.bookFlight,
  async (
    { bookFlightRequest, onSuccess }: { bookFlightRequest: BookFlightRequest; onSuccess: () => void },
    { rejectWithValue }
  ) => {
    try {
      const response = await bookFlightApi(bookFlightRequest)

      onSuccess()

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse)

      return rejectWithValue(error)
    }
  }
)

const bookFlightSlice = createSlice({
  name: urls.flights.search,
  initialState: bookFlightInitialState,
  reducers: {
    clearSuccess(state) {
      state.success = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(bookFlight.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(bookFlight.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(bookFlight.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as BookFlightErrorResponse
        state.success = null
      })
  }
})

export const { actions: bookFlightActions, reducer: bookFlightReducer } = bookFlightSlice
export const { clearSuccess: clearFlightBookingSuccess } = bookFlightActions
export default bookFlightSlice.reducer
