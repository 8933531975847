import { CSSProperties } from 'react'

import theme from '../../shared/styles/themes/default.theme'

export default {
  container: {
    display: 'flex',
    gap: theme.spacing(2),
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    minHeight: '100vh',
    paddingBottom: theme.spacing(5)
  },

  stepHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(10)
  },

  title: {
    textAlign: 'left',
    fontWeight: theme.typography.fontWeightBold
  } as CSSProperties,

  subtitle: {
    textAlign: 'left',
    color: theme.palette.text.secondary
  },

  cartContent: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10)
  },

  stepContent: {
    // display: 'flex',
    // justifyContent: 'center'
  },

  emptyStateContainer: {
    padding: theme.spacing(5, 10),
    width: '100%'
  },

  loginButton: {
    marginTop: theme.spacing(2)
  },

  sharedTripContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(3)
  },
  tripDetailsContainer: {
    width: '100%',
    paddingBottom: theme.spacing(2)
  },

  sharedTripSubtitle: {
    textAlign: 'left',
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.grey[500],
    paddingBottom: theme.spacing(2)
  },
  hourrierCardContainer: {
    width: '100%',
    paddingBottom: theme.spacing(2)
  }
}
