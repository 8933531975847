import React, { useState } from 'react'

import { Box, SvgIcon, Typography, useMediaQuery, useTheme } from '@mui/material'

import Carousel from '../Carousel/Carousel.component'
import MainPageSection from '../MainPageSection/MainPageSection.component'

import images from '../../../assets/images'
import { HourrierTestimonial } from '../../../redux/states/firebaseRemoteConfig/firebaseRemoteConfig.types'
import { RootState } from '../../../redux/store/store.types'
import { useAppSelector } from '../../hooks/redux.hooks'
import styles from './TestimonialCarousel.styles'

const { PlaneIcon, TruckIcon, CashIcon } = images

const TravellerStats: React.FC<{ testimonial: HourrierTestimonial }> = ({ testimonial }) => (
  <>
    <Box sx={styles.statItem}>
      <Box sx={styles.statIconText}>
        <SvgIcon component={PlaneIcon} sx={styles.icon} />

        <Typography variant="h6" sx={styles.statValue}>
          {testimonial.tripsTaken}
        </Typography>
      </Box>

      <Typography variant="caption" sx={styles.statLabel}>
        Trips Taken
      </Typography>
    </Box>

    <Box sx={styles.statItem}>
      <Box sx={styles.statIconText}>
        <SvgIcon component={TruckIcon} sx={styles.icon} />

        <Typography variant="h6" sx={styles.statValue}>
          {testimonial.successfulDeliveries}
        </Typography>
      </Box>

      <Typography variant="caption" sx={styles.statLabel}>
        Successful Deliveries
      </Typography>
    </Box>

    <Box sx={styles.statItem}>
      <Box sx={styles.statIconText}>
        <SvgIcon component={CashIcon} sx={styles.icon} />

        <Typography variant="h6" sx={styles.statValue}>
          ${testimonial.earned}
        </Typography>
      </Box>

      <Typography variant="caption" sx={styles.statLabel}>
        Earned
      </Typography>
    </Box>
  </>
)

const ShopperStats: React.FC<{ testimonial: HourrierTestimonial }> = ({ testimonial }) => (
  <>
    <Box sx={styles.statItem}>
      <Box sx={styles.statIconText}>
        <SvgIcon component={TruckIcon} sx={styles.icon} />

        <Typography variant="h6" sx={styles.statValue}>
          {testimonial.ordersReceived}
        </Typography>
      </Box>

      <Typography variant="caption" sx={styles.statLabel}>
        Orders Received
      </Typography>
    </Box>

    <Box sx={styles.statItem}>
      <Box sx={styles.statIconText}>
        <SvgIcon component={CashIcon} sx={styles.icon} />

        <Typography variant="h6" sx={styles.statValue}>
          ${testimonial.spent}
        </Typography>
      </Box>

      <Typography variant="caption" sx={styles.statLabel}>
        Spent
      </Typography>
    </Box>
  </>
)

const TestimonialStats: React.FC<{ testimonial: HourrierTestimonial }> = ({ testimonial }) => (
  <Box sx={styles.statsContainer}>
    {testimonial.userType === 'traveller' ? (
      <TravellerStats testimonial={testimonial} />
    ) : (
      <ShopperStats testimonial={testimonial} />
    )}
  </Box>
)

const TestimonialCarousel: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState(0)
  const { values } = useAppSelector((state: RootState) => state.firebaseRemoteConfig)
  const testimonials = (values?.testimonials as HourrierTestimonial[]) ?? []
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const renderTestimonialContent = (testimonial: HourrierTestimonial) => (
    <Box sx={styles.testimonialContent}>
      <Typography variant="h4" sx={styles.quote}>
        {testimonial.quote}
      </Typography>

      <TestimonialStats testimonial={testimonial} />

      <Box sx={styles.userInfo}>
        <Typography variant="subtitle1" sx={styles.name}>
          {testimonial.userName}
        </Typography>

        <Typography variant="body2" sx={styles.userTitle}>
          {testimonial.userTitle}
        </Typography>
      </Box>
    </Box>
  )

  const carouselItems = testimonials.map((testimonial, index) => (
    <Box key={index} sx={styles.testimonialImage}>
      <Box component={'img'} src={testimonial.imageUrl} sx={styles.image} alt={testimonial.userName} />
    </Box>
  ))

  return (
    <MainPageSection
      title="The Hourrier Experience"
      subtitle="See how we've helped your fellow Travellers and Shoppers."
    >
      <Box sx={styles.carouselWrapperContainer}>
        <Box sx={styles.carouselWrapper}>
          <Carousel
            items={carouselItems}
            showDotStepper={true}
            showStepper={true}
            autoCycle={true}
            autoCycleDuration={5000}
            onStepChange={setActiveIndex}
          />

          {isMobile && (
            <Box sx={styles.contentWrapper}>
              {testimonials.length > 0 && renderTestimonialContent(testimonials[activeIndex])}
            </Box>
          )}
        </Box>

        {!isMobile && (
          <Box sx={styles.contentWrapper}>
            {testimonials.length > 0 && renderTestimonialContent(testimonials[activeIndex])}
          </Box>
        )}
      </Box>
    </MainPageSection>
  )
}

export default TestimonialCarousel
