import theme from '../../../../shared/styles/themes/default.theme'

export default {
  container: {
    padding: theme.spacing(3),
    border: '1px solid',
    borderColor: theme.palette.divider,
    borderRadius: theme.shape.borderRadius / 4,
    backgroundColor: theme.palette.background.default
  },

  row: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1)
  },

  divider: {
    margin: '16px 0'
  },

  title: {
    fontWeight: 600,
    textAlign: 'left'
  },

  fieldText: {
    color: theme.palette.grey[500],
    fontWeight: 500
  },

  amountToPay: {
    fontWeight: 700,
    textAlign: 'left',
    paddingRight: theme.spacing(2)
  }
}
