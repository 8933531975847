import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../shared/types/api.types'
import { getAllFlightBookingsApi } from './getAllFlightBookings.api'
import getAllFlightBookingsInitialState from './getAllFlightBookings.initialState'
import { GetAllFlightBookingsErrorResponse, GetAllFlightBookingsRequestPayload } from './getAllFlightBookings.types'

export const getAllFlightBookings = createAsyncThunk(
  urls.flights.getAllFlightBookings,
  async (payload: GetAllFlightBookingsRequestPayload, { rejectWithValue }) => {
    const { request, onSuccess } = payload

    try {
      const response = await getAllFlightBookingsApi(request)

      onSuccess && onSuccess(response)

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse)

      return rejectWithValue(error)
    }
  }
)

const getAllFlightBookingsSlice = createSlice({
  name: urls.flights.getAllFlightBookings,
  initialState: getAllFlightBookingsInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllFlightBookings.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(getAllFlightBookings.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(getAllFlightBookings.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as GetAllFlightBookingsErrorResponse
        state.success = null
      })
  }
})

export const { actions: getAllFlightBookingsActions, reducer: getAllFlightBookingsReducer } = getAllFlightBookingsSlice
export default getAllFlightBookingsSlice.reducer
