import { SxProps } from '@mui/material'

import theme from '../../shared/styles/themes/default.theme'

const styles = {
  container: {
    marginTop: theme.spacing(-30)
  } as SxProps,

  forgetPasswordTextContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(1.5)
  },

  forgetPasswordText: {
    color: theme.palette.grey[700],
    fontSize: '16px'
  },

  forgetPasswordLink: {
    color: theme.palette.primary.main,
    fontSize: '16px',
    marginLeft: theme.spacing(0.5)
  },

  termsContainer: {
    marginTop: theme.spacing(10)
  },

  termsTextContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}

export default styles
