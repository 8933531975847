import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../networkRequests/apiClient/endpoints'
import Snackbar from '../../../../shared/components/Snackbar/Snackbar.functions'
import errorHandler from '../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../shared/types/api.types'
import { updateContactInfoApi } from './updateContactInfo.api'
import updateContactInfoInitialState from './updateContactInfo.initialState'
import { UpdateContactInfoErrorResponse, UpdateContactInfoRequest } from './updateContactInfo.types'

export const updateContactInfo = createAsyncThunk(
  urls.profile.updateContactInfo,
  async (
    {
      updateContactInfoRequest,
      onSuccess
    }: { updateContactInfoRequest: UpdateContactInfoRequest; onSuccess: () => void },
    { rejectWithValue }
  ) => {
    try {
      const response = await updateContactInfoApi(updateContactInfoRequest)

      onSuccess()

      Snackbar.showSnackbar({
        message: response?.userFriendlyMessage,
        severity: 'success'
      })

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse)

      return rejectWithValue(error)
    }
  }
)

const updateContactInfoSlice = createSlice({
  name: urls.profile.updateContactInfo,
  initialState: updateContactInfoInitialState,
  reducers: {
    // Add any additional reducers here
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateContactInfo.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(updateContactInfo.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(updateContactInfo.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as UpdateContactInfoErrorResponse
        state.success = null
      })
  }
})

export const { actions: updateContactInfoActions, reducer: updateContactInfoReducer } = updateContactInfoSlice
export default updateContactInfoSlice.reducer
