import { setAuthenticationStatus } from '../../../networkRequests/apiClient/apiClient.functions'
import Snackbar from '../../components/Snackbar/Snackbar.functions'
import { ApiErrorResponse } from '../../types/api.types'

export const handleApiError = (error: ApiErrorResponse, onError?: (data?: ApiErrorResponse) => void): void => {
  const errorMessage = error?.userFriendlyMessage || 'An error occurred processing your request. Please try again.'

  if (
    error?.status === 401 &&
    (error?.message.includes('Token is required') || error?.message.includes('Invalid or expired token'))
  ) {
    setAuthenticationStatus(false)
    Snackbar.showSnackbar({ message: 'Your session has expired. Please login again.', severity: 'warning' })
  } else {
    Snackbar.showSnackbar({ message: errorMessage, severity: 'error' })
  }

  onError && onError()
}

const isErrorCode = (error: unknown, ...codes: number[]): boolean => codes.includes((error as ApiErrorResponse).status)

export const handleDefaultError = (error: unknown) => {
  errorHandler.handleApiError(error as ApiErrorResponse)
}

const errorHandler = {
  handleDefaultError,
  handleApiError,
  isErrorCode
}

export default errorHandler
