import { FlightBooking } from '../../../../redux/states/flights/getAllFlightBookings/getAllFlightBookings.types'
import { OfferRequest } from '../../../../redux/states/offerRequest/getPublicOffers/getPublicOffers.types'
import date from '../../../../shared/functions/Date/date.functions'
import { AccepOfferValidationResult } from './OfferItem.types'

export function formatArrayWithAnd(arr: string[]): string {
  if (arr.length === 0) return ''
  if (arr.length === 1) return arr[0]
  if (arr.length === 2) return arr.join(' and ')

  return `${arr.slice(0, -1).join(', ')} and ${arr[arr.length - 1]}`
}

export const validateOfferAcceptance = (
  flightBookings: FlightBooking[],
  items: { country: { iataCode: string; name: string } }[],
  deliveryLocation: { countryCode: string; country: string; city: string },
  offer: OfferRequest
): AccepOfferValidationResult => {
  const item = items[0] // Since all items have the same country, we can use the first item's country
  const itemCountry = item.country
  const matchingFlightBookings: FlightBooking[] = []

  flightBookings = flightBookings.filter((flightBooking) =>
    date(flightBooking.flightLegs[0].flightDetails.departureTime).isSameOrAfter(date().currentDate)
  )

  for (const flightBooking of flightBookings) {
    const visitedCountryCodes = new Set<string>()
    const visitedCityNames = new Set<string>()

    for (let i = 0; i < flightBooking.flightLegs.length; i++) {
      const flight = flightBooking.flightLegs[i]

      const destinationCountryCode = flight.flightDetails.destination.country.iataCode
      const destinationCityName = flight.flightDetails.destination.city.name
      const originCountryCode = flight.flightDetails.origin.country.iataCode
      const originCityName = flight.flightDetails.origin.city.name

      if (i > 0 && originCountryCode) visitedCountryCodes.add(originCountryCode)
      if (destinationCountryCode) visitedCountryCodes.add(destinationCountryCode)
      if (destinationCityName) visitedCityNames.add(destinationCityName)
      if (originCityName) visitedCityNames.add(originCityName)
    }

    const isMatch =
      itemCountry.iataCode &&
      visitedCountryCodes.has(itemCountry.iataCode) &&
      visitedCountryCodes.has(deliveryLocation.countryCode) &&
      visitedCityNames.has(deliveryLocation.city)

    if (isMatch) {
      matchingFlightBookings.push(flightBooking)
    }
  }

  const requiredCountries = Array.from(
    new Set(
      [
        ...items.map((item) => ({
          iataCode: item.country.iataCode,
          name: item.country.name,
          neededByDate: null,
          city: null
        })),
        {
          iataCode: deliveryLocation.countryCode,
          name: deliveryLocation.country,
          neededByDate: offer.neededByDate,
          city: deliveryLocation.city
        }
      ].map((country) => JSON.stringify(country))
    )
  ).map((country) => JSON.parse(country))

  if (matchingFlightBookings.length === 0) {
    return {
      multipleOptions: false,
      noMatchingFlightBookings: true,
      requiredCountries,
      flightBookings: []
    }
  } else if (matchingFlightBookings.length > 1) {
    return {
      multipleOptions: true,
      noMatchingFlightBookings: false,
      flightBookings: matchingFlightBookings,
      requiredCountries
    }
  } else {
    return {
      multipleOptions: false,
      noMatchingFlightBookings: false,
      flightBookings: matchingFlightBookings,
      requiredCountries
    }
  }
}
