import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import { BookFlightErrorResponse, BookFlightRequest, BookFlightSuccessResponse } from './bookFlight.types'

export const bookFlightApi = async (request: BookFlightRequest): Promise<BookFlightSuccessResponse> => {
  const response = await configureRequest({
    url: urls.flights.bookFlight,
    method: 'POST',
    data: request
  })

  if (response.status >= 200 && response.status < 300) {
    return response as BookFlightSuccessResponse
  } else {
    throw response as BookFlightErrorResponse
  }
}

export default bookFlightApi
