import { createTheme } from '@mui/material/styles'

declare module '@mui/material/styles' {
  interface Palette {
    tertiary: Palette['primary']
    priceColor: string
    starColor: {
      gold: string
      grey: string
    }
    paleBlue: string
  }

  interface PaletteOptions {
    tertiary?: PaletteOptions['primary']
    priceColor: string
    starColor?: {
      gold: string
      grey: string
    }
    paleBlue: string
  }
}

const theme = createTheme({
  typography: {
    fontFamily: 'DM Sans, sans-serif'
  },
  palette: {
    primary: {
      main: '#6D28D9',
      light: '#9F7AEA',
      dark: '#381E72',
      contrastText: '#fff'
    },

    secondary: {
      main: '#339FFF',
      light: '#66C2FF',
      dark: '#0077C8',
      contrastText: '#fff'
    },

    tertiary: {
      main: '#F5F3FF',
      contrastText: '#6D28D9'
    },

    grey: {
      50: '#F9FAFB',
      100: '#F3F4F6',
      200: '#E5E7EB',
      300: '#D1D5DB',
      400: '#9CA3AF',
      500: '#6B7280',
      600: '#4B5563',
      700: '#374151',
      800: '#1F2937'
    },

    error: {
      main: '#FF0000'
    },

    success: {
      main: '#10B981',
      light: '#3FC79A',
      dark: '#0B815A',
      contrastText: '#fff'
    },

    warning: {
      main: '#F59E0B',
      light: '#FDE68A',
      dark: '#EAB308',
      contrastText: '#9A3412'
    },

    priceColor: '#1F6AAD',

    starColor: {
      gold: '#F59E0B',
      grey: '#D1D5DB'
    },

    paleBlue: '#CDDEED'
  },

  shape: {
    borderRadius: 8
  },

  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#D1D5DB'
            },
            '&:hover fieldset': {
              borderColor: '#9CA3AF'
            },
            '&.Mui-focused fieldset': {
              borderColor: '#6D28D9'
            }
          }
        }
      }
    },

    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#1F2937'
        }
      }
    }
  }
})

export default theme
