import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import {
  GetFlightBookingErrorResponse,
  GetFlightBookingRequest,
  GetFlightBookingSuccessResponse
} from './getFlightBooking.types'

export const getFlightBookingApi = async (
  request: GetFlightBookingRequest
): Promise<GetFlightBookingSuccessResponse> => {
  const { id } = request

  const response = await configureRequest({
    url: urls.flights.getFlightBooking,
    method: 'GET',
    params: { id }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as GetFlightBookingSuccessResponse
  } else {
    throw response as GetFlightBookingErrorResponse
  }
}

export default getFlightBookingApi
