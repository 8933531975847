import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { Box, Grid, Tab, Tabs, Typography } from '@mui/material'

import { EmptyState } from '../EmptyState/EmptyState.component'
import Loader from '../Loader/Loader.component'
import SearchBar from '../SearchBar/SearchBar.component'
import UniversalFilterSideBar from '../UniversalFilterSideBar/UniversalFilterSideBar.component'
import InfiniteScroll from 'react-infinite-scroll-component'

import { SearchBarRef } from '../SearchBar/SearchBar.types'
import { FilterSectionConfig, Filters } from '../UniversalFilterSideBar/UniversalFilterSideBar.types'
import { styles } from './PaginatedPage.styles'
import { PaginatedPageProps, PaginatedResponse } from './PaginatedPage.types'

const PaginatedPage: React.FC<PaginatedPageProps> = (props) => {
  const {
    title,
    subtitle,
    initialLoading,
    fetchCall,
    renderListData: RenderListData,
    emptyState,
    hideFilters,
    filterEmptyState,
    onResetSearch,
    horizontalFilterTabs,
    verticalFilters,
    searchBar,
    layout,
    gridSx,
    filterMutateDef,
    rightContainer
  } = props

  const [listData, setListData] = useState<unknown[]>([])
  const [visibleListData, setVisibleListData] = useState<unknown[]>([])
  const [page, setPage] = useState(1)
  const [, setTotalPages] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const [loading, setLoading] = useState(false)
  const [activeTab, setActiveTab] = useState(horizontalFilterTabs ? horizontalFilterTabs[0].id : '')
  const [, setSearchQuery] = useState<string>('')
  const initialFetchRef = useRef(false)

  const filterSidebarRef = useRef<{ resetFilters: () => void }>(null)
  const searchBarRef = useRef<SearchBarRef>(null)
  const [lastFilters, setLastFilters] = useState<Filters>({})

  const [builtVerticalFilters, setBuiltVerticalFilters] = useState<FilterSectionConfig[]>([])

  const fetchData = useCallback(
    async (onSuccess?: (data?: unknown[]) => void) => {
      const response: PaginatedResponse<unknown> = await fetchCall(1)

      setListData(response.results)
      setVisibleListData(response.results)
      setTotalPages(response.totalPages)
      setPage(response.currentPage + 1)
      setHasMore(response.currentPage < response.totalPages)

      onSuccess?.()
    },
    [fetchCall]
  )

  const fetchMoreData = useCallback(async () => {
    if (loading) return

    setLoading(true)

    try {
      const response: PaginatedResponse<unknown> = await fetchCall(page)
      const newListData = [...listData, ...response.results]

      setListData(newListData)
      setBuiltVerticalFilters(verticalFilters?.(newListData, lastFilters) || [])

      if (Object.keys(lastFilters).length > 0) {
        // Apply filters to the new data
        const filteredData = newListData.filter((item) => {
          return verticalFilters?.(newListData, lastFilters).every((section) => {
            return section.filterDef ? section.filterDef(item, lastFilters) : true
          })
        })

        if (filterMutateDef) {
          const mutatedData = filterMutateDef(filteredData, lastFilters)
          setVisibleListData(mutatedData)
        } else {
          setVisibleListData(filteredData)
        }
      } else {
        setVisibleListData((prev) => [...prev, ...response.results])
      }

      setTotalPages(response.totalPages)
      setPage(response.currentPage + 1)
      setHasMore(response.currentPage < response.totalPages)
    } finally {
      setLoading(false)
    }
  }, [fetchCall, page, loading, verticalFilters, lastFilters, listData, filterMutateDef])

  useEffect(() => {
    if (!initialFetchRef.current) {
      fetchMoreData()
      initialFetchRef.current = true
    }
  }, [fetchMoreData])

  const filteredListData = horizontalFilterTabs
    ? horizontalFilterTabs.find((tab) => tab.id === activeTab)?.filterDef(visibleListData) || visibleListData
    : visibleListData

  const handleTabChange = async (_event: React.SyntheticEvent, newValue: string) => {
    const selectedTab = horizontalFilterTabs?.find((tab) => tab.id === newValue)

    setActiveTab(newValue)
    setPage(1)
    setVisibleListData([])

    handleResetSearch()

    if (selectedTab?.isApiFilter) {
      initialFetchRef.current = false
      await fetchMoreData()
    } else {
      initialFetchRef.current = true
      setVisibleListData(listData)
    }
  }

  const handleSearch = (query: string) => {
    setSearchQuery(query)

    if (searchBar) {
      const filteredData = searchBar.onSearchFilter(listData, query)
      setVisibleListData(filteredData)
    }
  }

  const hideAllFilters = useMemo(() => {
    return hideFilters ? hideFilters(listData) : false
  }, [listData, hideFilters])

  const noData = useMemo(() => {
    return listData.length === 0
  }, [listData])

  const handleResetSearch = () => {
    setSearchQuery('')
    setVisibleListData(listData)
    onResetSearch?.()
    filterSidebarRef.current?.resetFilters()
    searchBarRef.current?.clear()
  }

  const handleFilterChange = (newFilters: Filters) => {
    setLastFilters(newFilters)

    const filteredData = listData.filter((item) => {
      return verticalFilters?.(listData, newFilters).every((section) => {
        return section.filterDef ? section.filterDef(item, newFilters) : true
      })
    })
    if (filterMutateDef) {
      const mutatedData = filterMutateDef(filteredData, newFilters)
      setVisibleListData(mutatedData)
    } else {
      setVisibleListData(filteredData)
    }
  }

  const getContent = () => {
    const showMainLoader = initialLoading && page === 1

    const getListDataView = () => {
      if (noData) {
        return <EmptyState {...emptyState} />
      } else {
        const renderScrollContent = () => {
          if (filteredListData.length === 0) {
            return (
              <EmptyState
                title={filterEmptyState?.title ?? `No ${title.id.toLowerCase()} found that match your search criteria`}
                subtitle={filterEmptyState?.subtitle ?? 'Try changing your filter settings.'}
                button={filterEmptyState?.button ?? { text: 'Reset Search', onClick: handleResetSearch }}
              />
            )
          } else {
            return (
              <Box
                sx={
                  layout === 'grid'
                    ? {
                        ...styles.listContainerGrid,
                        ...gridSx
                      }
                    : styles.listContainer
                }
              >
                {filteredListData.map((item, index) => (
                  <RenderListData
                    key={index}
                    item={item}
                    refreshData={fetchData}
                    index={index}
                    listLength={filteredListData?.length ?? 0}
                  />
                ))}
              </Box>
            )
          }
        }

        return (
          <InfiniteScroll
            dataLength={filteredListData.length}
            next={fetchMoreData}
            hasMore={hasMore}
            style={styles.infiniteScroll}
            loader={<Loader loading={loading} />}
            endMessage={
              <Typography variant="overline" sx={styles.endOfScrollMessage}>
                {filteredListData.length !== 0 && `End of ${title.id}`}
              </Typography>
            }
          >
            {renderScrollContent()}
          </InfiniteScroll>
        )
      }
    }

    if (showMainLoader) {
      return (
        <Box sx={styles.bottomContainer}>
          <Loader loading={initialLoading} text={`Fetching Your ${title.id}`} size="large" marginTop={10} />
        </Box>
      )
    } else {
      return (
        <Grid item sx={styles.bottomContainer}>
          {verticalFilters && !noData && !hideAllFilters && (
            <Grid item sx={styles.sidebar} md={2}>
              <UniversalFilterSideBar
                ref={filterSidebarRef}
                sections={builtVerticalFilters}
                onChange={handleFilterChange}
              />
            </Grid>
          )}

          <Grid item sx={styles.main} md={verticalFilters && !noData && !hideAllFilters ? 10 : 12}>
            <Box sx={styles.titleAndFilterContainer}>
              <Box sx={styles.titleContainer}>
                <Typography variant="h4" sx={styles.title}>
                  {title.text}
                </Typography>

                <Typography variant="subtitle1" sx={styles.subtitle}>
                  {subtitle}
                </Typography>
              </Box>

              {rightContainer && rightContainer}

              {horizontalFilterTabs && !noData && !hideAllFilters && (
                <Tabs
                  value={activeTab}
                  onChange={handleTabChange}
                  aria-label="filter tabs"
                  TabIndicatorProps={{ style: styles.tabIndicator }}
                  sx={styles.tabRoot}
                >
                  {horizontalFilterTabs.map((tab) => (
                    <Tab
                      key={tab.id}
                      label={`${tab.label} (${tab.filterDef(visibleListData).length})`}
                      value={tab.id}
                      sx={styles.tab}
                    />
                  ))}
                </Tabs>
              )}
            </Box>

            {getListDataView()}
          </Grid>
        </Grid>
      )
    }
  }

  return (
    <Grid container sx={styles.container}>
      {searchBar && (
        <Grid item sx={styles.searchBarContainer} md={12}>
          {!noData ? (
            <SearchBar
              ref={searchBarRef}
              onSearch={handleSearch}
              placeholder={searchBar.placeholder}
              isMobile={false}
            />
          ) : null}
        </Grid>
      )}

      {getContent()}
    </Grid>
  )
}

export default PaginatedPage
