import { useCallback, useEffect, useState } from 'react'

import { ArrowForwardRounded, ExpandMore } from '@mui/icons-material'
import { Box, Divider, StepLabel as MuiStepLabel, Step, Stepper, Typography } from '@mui/material'

import Accordion from '../../../../shared/components/Accordion/Accordion.component'
import Link from '../../../../shared/components/Link/Link.component'
// import Stepper from '../../../../shared/components/Stepper/Stepper.component'
import StepLabel from '../StepLabel/StepLabel.component'

import {
  FlightBooking,
  FlightLeg
} from '../../../../redux/states/flights/getAllFlightBookings/getAllFlightBookings.types'
import { RootState } from '../../../../redux/store/store.types'
import router from '../../../../router/functions/router.functions'
import routes from '../../../../router/routes.dictionary'
import { AccordionButtonProps, AccordionFooterProps } from '../../../../shared/components/Accordion/Accordion.types'
import { PaginatedRenderProps } from '../../../../shared/components/PaginatedPage/PaginatedPage.types'
import status from '../../../../shared/dictionaries/status.dictionaries'
import date, { dateTimeFormats, formatDuration } from '../../../../shared/functions/Date/date.functions'
import { joinStringWithBullet, joinStringWithDash } from '../../../../shared/functions/String/string.functions'
import { useAppSelector } from '../../../../shared/hooks/redux.hooks'
import {
  determineTripType,
  getFormattedCabinClass,
  getFormattedTravelerDateDetails,
  getFormattedTripDetails
} from '../../Trips.functions'
import styles from './TripDetailsAccordion.styles'

const TripDetailsAccordion = (props: PaginatedRenderProps) => {
  const flightBooking = props.item as FlightBooking
  const index = props.index
  const listLength = props.listLength
  const isCompletedTrip = date(
    flightBooking.flightLegs[flightBooking.flightLegs.length - 1].flightDetails.arrivalTime
  ).isBefore(date().currentDate)

  const [updatedFlightBooking, setUpdatedFlightBooking] = useState<FlightBooking>(flightBooking)
  const [expanded, setExpanded] = useState<{ [key: string]: boolean }>({})
  const [isFirstAccordionExpanded, setIsFirstAccordionExpanded] = useState(
    index === 0 && listLength === 1 && !isCompletedTrip
  )

  const { success: getAllFlightBookingsSuccess } = useAppSelector((state: RootState) => state.getAllFlightBookings)

  const navigate = router.navigate()

  useEffect(() => {
    if (index === 0 && listLength !== 1) {
      setIsFirstAccordionExpanded(false)
    }
  }, [index, listLength])

  const refreshFlightBookings = useCallback(() => {
    const relatedFlightBooking = getAllFlightBookingsSuccess?.data.results.find(
      (_flightBooking) => _flightBooking.id === flightBooking.id
    )

    setUpdatedFlightBooking(relatedFlightBooking ?? flightBooking)
  }, [getAllFlightBookingsSuccess, flightBooking])

  useEffect(() => {
    if (getAllFlightBookingsSuccess) {
      refreshFlightBookings()
    }
  }, [getAllFlightBookingsSuccess, refreshFlightBookings])

  const handleAccordionExpansion = (isExpanded: boolean) => {
    setExpanded((prev) => ({
      ...prev,
      [flightBooking.id]: isExpanded
    }))
  }

  const getAccordionHeader = () => {
    const locationsDetails = getFormattedTripDetails(flightBooking)
    const travelerDateDetails = getFormattedTravelerDateDetails(flightBooking)
    const tripType = determineTripType(flightBooking.flightLegs)
    const formattedTripType = tripType === 'roundTrip' ? 'Round Trip' : tripType === 'oneWay' ? 'One Way' : 'Multi-City'
    const tripPrice = parseFloat(updatedFlightBooking.price).toFixed(2)

    const accordionHeader = {
      mainContent: (
        <Box sx={styles.accordionHeaderMainContent}>
          <Box sx={styles.accordionHeaderMainContentTitleContainer}>
            <Typography sx={styles.accordionHeaderMainContentTitleText}>{locationsDetails}</Typography>

            <Box sx={styles.tripTypeContainer}>
              <Typography sx={styles.tripTypeText}>{formattedTripType}</Typography>
            </Box>
          </Box>

          <Typography sx={styles.accordionHeaderMainContentSubtitleText}>{travelerDateDetails}</Typography>
        </Box>
      ),
      secondaryContent: (
        <Box sx={styles.accordionHeaderSecondaryContent}>
          <Typography sx={styles.accordionHeaderSecondaryContentTitleText}>${tripPrice}</Typography>
          <Typography sx={styles.accordionHeaderSecondaryContentSubtitleText}>Trip Cost</Typography>
        </Box>
      )
    }

    return accordionHeader
  }

  const renderFlightLegs = (leg: FlightLeg) => {
    const steps = [
      {
        label: `${date(leg.flightDetails.departureTime).format(`${dateTimeFormats.date.long} ${dateTimeFormats.time.short}`)}`,
        description: `Depart from ${leg.flightDetails.origin.airport.name} (${leg.flightDetails.origin.airport.iataCode})`
      },
      {
        label: `${date(leg.flightDetails.arrivalTime).format(`${dateTimeFormats.date.long} ${dateTimeFormats.time.short}`)}`,
        description: `Arrive at ${leg.flightDetails.destination.airport.name} (${leg.flightDetails.destination.airport.iataCode})`
      }
    ]

    const additionalDetails = [
      {
        label: 'Type',
        description: getFormattedCabinClass(updatedFlightBooking.cabinClass)
      },
      {
        label: 'Airline',
        description: leg.flightDetails.airline.name
      },
      {
        label: 'Plane',
        description: leg.flightDetails.aircraftName ?? 'N/A'
      },
      {
        label: 'Flight Number',
        description: leg.flightDetails.flightNumber
      }
    ]

    const flightLegSummary = () => {
      const getDateSummary = () => {
        const departureDate = date(leg.flightDetails.departureTime).format(dateTimeFormats.date.medium)
        const arrivalDate = date(leg.flightDetails.arrivalTime).format(dateTimeFormats.date.medium)

        if (departureDate === arrivalDate) {
          return joinStringWithBullet([
            `${date(leg.flightDetails.departureTime).format(dateTimeFormats.date.long)}`,
            joinStringWithDash([
              `${date(leg.flightDetails.departureTime).format(dateTimeFormats.time.short)}`,
              `${date(leg.flightDetails.arrivalTime).format(dateTimeFormats.time.short)}`
            ])
          ])
        } else {
          const departureTime = joinStringWithBullet([
            `${date(leg.flightDetails.departureTime).format(dateTimeFormats.date.long)}`,
            `${date(leg.flightDetails.departureTime).format(dateTimeFormats.time.short)}`
          ])

          const arrivalTime = joinStringWithBullet([
            `${date(leg.flightDetails.arrivalTime).format(dateTimeFormats.date.long)}`,
            `${date(leg.flightDetails.arrivalTime).format(dateTimeFormats.time.short)}`
          ])

          return joinStringWithDash([departureTime, arrivalTime])
        }
      }

      const tripDuration = formatDuration(leg.flightDetails.duration)

      const tripRoute = `${leg.flightDetails.origin.airport.iataCode} → ${leg.flightDetails.destination.airport.iataCode}`

      return (
        <Box sx={styles.legSummaryContainer}>
          <Box sx={styles.airlineLogoContainer}>
            <img src={leg.flightDetails.airline.logo} alt={leg.flightDetails.airline.name} style={styles.airlineLogo} />
          </Box>

          <Box sx={styles.legSummaryContentContainer}>
            <Box sx={styles.legSummaryContent}>
              <Typography sx={styles.legSummaryContentTitleText}>{getDateSummary()}</Typography>
              <Typography sx={styles.legSummaryContentSubtitleText}>{leg.flightDetails.airline.name}</Typography>
            </Box>

            <Box sx={styles.legSummaryContent}>
              <Typography sx={styles.legSummaryContentTitleText}>{tripDuration}</Typography>
              <Typography sx={styles.legSummaryContentSubtitleText}>{tripRoute}</Typography>
            </Box>
          </Box>
        </Box>
      )
    }

    const flightLegSteps = () => {
      return (
        <Box sx={styles.legDetails}>
          {/* <Stepper activeStep={-1} orientation="vertical" steps={steps} noBackground /> */}
          <Stepper activeStep={-1} orientation="vertical">
            {steps.map((step) => (
              <Step key={step.label}>
                <MuiStepLabel
                  StepIconComponent={StepLabel}
                  optional={<Typography sx={styles.legStepDescriptionText}>{step.description}</Typography>}
                >
                  <Typography sx={styles.legStepLabelText}>{step.label}</Typography>
                </MuiStepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      )
    }

    const renderAdditionalFlightDetails = () => {
      return (
        <Box sx={styles.additionalDetailsContainer}>
          {additionalDetails.map((detail) => (
            <Box sx={styles.additionalDetailContainer}>
              <Typography sx={styles.additionalDetailLabelText}>{detail.label}</Typography>
              <Typography sx={styles.additionalDetailDescriptionText}>{detail.description}</Typography>
            </Box>
          ))}
        </Box>
      )
    }

    return (
      <Box sx={styles.legDetailsContainer}>
        {flightLegSummary()}
        {flightLegSteps()}
        {renderAdditionalFlightDetails()}
      </Box>
    )
  }

  const renderDetails = () => {
    return (
      <Box sx={styles.flightDetailsContainer}>
        {flightBooking.flightLegs.map((leg) => {
          const isLastLeg = flightBooking.flightLegs.indexOf(leg) === flightBooking.flightLegs.length - 1

          return (
            <Box>
              {renderFlightLegs(leg)}
              {!isLastLeg && <Divider sx={styles.divider} />}
            </Box>
          )
        })}
      </Box>
    )
  }

  const renderFooter = () => {
    const isBookFlightDisabled = updatedFlightBooking.flightLegs.some((leg) =>
      leg.offerRequestsDetails.some((offerRequest) =>
        offerRequest.itemRequestDetails.some(
          (detail) =>
            detail.status.name === status.itemRequestStatus.pendingAcceptanceByTraveler ||
            detail.status.name === status.itemRequestStatus.reassignedByShopper
        )
      )
    )

    const isCancelTripDisabled = () => {
      const lastLeg = updatedFlightBooking.flightLegs[updatedFlightBooking.flightLegs.length - 1]

      let isTripInCancellableState =
        updatedFlightBooking.status.name === status.flightStatus.interestedInFlight ||
        updatedFlightBooking.status.name === status.flightStatus.flightBooked

      if (date().isAfter(lastLeg.flightDetails.arrivalTime)) {
        isTripInCancellableState = false
      }

      return !isTripInCancellableState
    }

    const handleDetailsButtonPress = (id: number) => {
      setExpanded((prev) => ({
        ...prev,
        [id]: !prev[id]
      }))
    }

    const accordionFooterButtons: AccordionButtonProps[] = [
      {
        text: 'Details',
        icon: (
          <ExpandMore
            style={{
              transform: expanded[flightBooking.id] ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: 'transform 0.3s'
            }}
          />
        ),
        iconPosition: 'end',
        onClick: () => handleDetailsButtonPress(flightBooking.id),
        expandAccordion: true,
        buttonType: 'tertiary'
      },
      {
        text: 'Cancel Trip',
        onClick: () => {
          // TODO: Cancel Trip
        },
        buttonType: 'secondary',
        disabled: isCancelTripDisabled(),
        tooltipText: isCancelTripDisabled()
          ? 'Trip is not in a cancellable state.'
          : 'Cancelling trip will have the effect of cancelling all associated item requests.'
      },
      {
        text: 'Book Flight',
        onClick: () => {
          // TODO: Book Flight
        },
        buttonType: 'primary',
        disabled: isBookFlightDisabled,
        tooltipText: isBookFlightDisabled
          ? 'Must action all item requests before you can book the flight.'
          : 'Must book flight before you can buy the items on Amazon.'
      }
    ]

    const accordionFooterMainContent = () => {
      const numberOfOfferRequests = updatedFlightBooking.flightLegs.reduce((acc, leg) => {
        leg.offerRequestsDetails.forEach((offerRequest) => {
          acc += offerRequest.itemRequestDetails.length
        })

        return acc
      }, 0)

      return (
        <Box sx={styles.accordionFooterMainContent}>
          <Typography sx={styles.accordionFooterMainContentText}>
            You have {numberOfOfferRequests} Requests
            <Link href={routes.requests.path} showColor style={styles.accordionFooterMainContentLink}>
              Go to Requests
            </Link>
          </Typography>

          <ArrowForwardRounded sx={styles.goToRequestsArrowIcon} onClick={() => navigate(routes.requests.path)} />
        </Box>
      )
    }

    const accordionFooter: AccordionFooterProps = {
      mainContent: accordionFooterMainContent(),
      buttons: accordionFooterButtons
    }

    return accordionFooter
  }

  return (
    <Accordion
      header={getAccordionHeader()}
      body={renderDetails()}
      isExpanded={isFirstAccordionExpanded}
      noExpandIcon
      handleExpansion={handleAccordionExpansion}
      footer={renderFooter()}
      key={flightBooking.id}
    />
  )
}

export default TripDetailsAccordion
