import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react'

import CustomizedSnackbars from '../Snackbar.component'

import { setSnackbarContext } from '../Snackbar.functions'
import { SnackbarAction, SnackbarAutoHideDuration } from '../Snackbar.types'
import { ShowSnackbarProps, SnackbarContextProps, SnackbarState } from './Snackbar.context.types'

const SnackbarContext = createContext<SnackbarContextProps | undefined>(undefined)

export const SnackbarProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [snackbarState, setSnackbarState] = useState<SnackbarState>({
    message: '',
    severity: 'info',
    autoHideDuration: SnackbarAutoHideDuration.MEDIUM,
    open: false,
    action: undefined
  })
  const [snackbarAction, setSnackbarAction] = useState<SnackbarAction | undefined>(undefined)

  const showSnackbar = (props: ShowSnackbarProps) => {
    setSnackbarState({
      message: props.message,
      severity: props.severity,
      autoHideDuration: props.autoHideDuration ?? SnackbarAutoHideDuration.MEDIUM,
      open: true,
      action: props.action
    })
  }

  const handleClose = () => {
    setSnackbarState((prev) => ({ ...prev, open: false }))
  }

  useEffect(() => {
    setSnackbarContext({ showSnackbar })
  }, [])

  useEffect(() => {
    if (snackbarState.action) {
      const { onClick, ...rest } = snackbarState.action

      const wrappedOnClick = () => {
        if (onClick) {
          onClick()
        }

        handleClose()
      }

      setSnackbarAction({ ...rest, onClick: wrappedOnClick })
    } else {
      setSnackbarAction(undefined)
    }
  }, [snackbarState.action])

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      {children}

      <CustomizedSnackbars
        message={snackbarState.message}
        severity={snackbarState.severity}
        action={snackbarAction}
        autoHideDuration={snackbarState.autoHideDuration}
        open={snackbarState.open}
        onClose={handleClose}
      />
    </SnackbarContext.Provider>
  )
}

export const useSnackbar = (): SnackbarContextProps => {
  const context = useContext(SnackbarContext)

  if (!context) {
    throw new Error('useSnackbar must be used within a SnackbarProvider')
  }

  return context
}
