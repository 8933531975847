import React from 'react'

import { Box, Divider, Typography } from '@mui/material'

import date from '../../../../shared/functions/Date/date.functions'
import styles from './FlightSummaryCard.styles'
import { FlightOfferProps } from './FlightSummaryCard.types'

const FlightSummaryCard: React.FC<FlightOfferProps> = (props) => {
  const {
    departingAt,
    airlineLogo,
    originAirportName,
    departureTime,
    destinationAirportName,
    arrivalTime,
    duration,
    flightNumber
    // stopCount,
    // cost,
    // travelClass,
    // onSelect
  } = props

  return (
    <Box sx={styles.container}>
      <Box sx={styles.cardTop}>
        <Typography variant="h6" sx={styles.departingAt}>
          {departingAt}
        </Typography>

        {/* <Box sx={styles.stopDurationContainer}>
          <Box sx={styles.stopCount}>
            <Typography variant="body2" sx={styles.stopText}>
              {stopCount === 0 ? 'Non Stop' : `${stopCount} Stop${stopCount > 1 ? 's' : ''}`}
            </Typography>
          </Box>

          <Typography variant="body2" sx={styles.duration}>
            {duration}
          </Typography>
        </Box> */}

        <Box sx={styles.details}>
          <Box sx={styles.airlineInfo}>
            <img src={airlineLogo} alt="Airline Logo" style={styles.logo} />

            <Box sx={styles.departureContainer}>
              <Typography variant="h6" sx={styles.airportName}>
                {originAirportName}
              </Typography>

              <Typography variant="body2" sx={styles.time}>
                {date(departureTime).format('h:mm a')}
              </Typography>
            </Box>
          </Box>

          <Divider sx={styles.separator} />

          <Box sx={styles.arrivalContainer}>
            <Typography variant="h6" sx={styles.airportName}>
              {destinationAirportName}
            </Typography>

            <Typography variant="body2" sx={styles.time}>
              {date(arrivalTime).format('h:mm a')}
            </Typography>
          </Box>

          <Divider sx={styles.verticalSeparator} />

          <Box sx={styles.durationContainer}>
            <Typography variant="h6">{duration}</Typography>
            <Typography variant="body2">Flight {flightNumber}</Typography>
          </Box>

          {/* <Divider sx={styles.verticalSeparator} /> */}

          {/* <Box sx={styles.stopsContainer}>
            <Typography variant="h6">{stopCount === 0 ? 'Non Stop' : `Stops`}</Typography>
            <Typography variant="body2">
              {stopCount === 0 ? 'No layovers' : `${stopCount} Layover${stopCount > 1 ? 's' : ''}`}
            </Typography>
          </Box> */}
        </Box>
      </Box>

      {/* <Divider sx={styles.bottomDivider} /> */}

      {/* <Box sx={styles.actionContainer}>
        <Box sx={styles.priceInfo}>
          <Typography variant="h6" sx={styles.price}>
            ${cost}
            <Typography component="span" sx={styles.perPerson}>
              /person
            </Typography>
          </Typography>

          <Typography variant="body2" sx={styles.cabinClass}>
            {travelClass}
          </Typography>
        </Box>

        <Button text="Select" buttonType="primary" onClick={onSelect} />
      </Box> */}
    </Box>
  )
}

export default FlightSummaryCard
