import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../shared/types/api.types'
import { getFlightBookingShareLinkApi } from './getFlightBookingShareLink.api'
import getFlightBookingShareLinkInitialState from './getFlightBookingShareLink.initialState'
import {
  GetFlightBookingShareLinkErrorResponse,
  GetFlightBookingShareLinkRequestPayload
} from './getFlightBookingShareLink.types'

export const getFlightBookingShareLink = createAsyncThunk(
  urls.flights.getFlightBookingShareLink,
  async (payload: GetFlightBookingShareLinkRequestPayload, { rejectWithValue }) => {
    const { request, onSuccess } = payload

    try {
      const response = await getFlightBookingShareLinkApi(request)

      onSuccess && onSuccess()

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse)

      return rejectWithValue(error)
    }
  }
)

const getFlightBookingShareLinkSlice = createSlice({
  name: urls.flights.getFlightBookingShareLink,
  initialState: getFlightBookingShareLinkInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFlightBookingShareLink.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(getFlightBookingShareLink.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(getFlightBookingShareLink.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as GetFlightBookingShareLinkErrorResponse
        state.success = null
      })
  }
})

export const { actions: getFlightBookingShareLinkActions, reducer: getFlightBookingShareLinkReducer } =
  getFlightBookingShareLinkSlice
export default getFlightBookingShareLinkSlice.reducer
