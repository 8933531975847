import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../shared/types/api.types'
import { resetOfferRequestFlightBookingApi } from './resetOfferRequestFlightBooking.api'
import resetOfferRequestFlightBookingInitialState from './resetOfferRequestFlightBooking.initialState'
import {
  ResetOfferRequestFlightBookingErrorResponse,
  ResetOfferRequestFlightBookingRequestPayload
} from './resetOfferRequestFlightBooking.types'

export const resetOfferRequestFlightBooking = createAsyncThunk(
  urls.offers.resetOfferRequestFlightBooking,
  async (payload: ResetOfferRequestFlightBookingRequestPayload, { rejectWithValue }) => {
    const { request, onSuccess } = payload

    try {
      const response = await resetOfferRequestFlightBookingApi(request)

      onSuccess && onSuccess()

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse)

      return rejectWithValue(error)
    }
  }
)

const resetOfferRequestFlightBookingSlice = createSlice({
  name: urls.offers.resetOfferRequestFlightBooking,
  initialState: resetOfferRequestFlightBookingInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resetOfferRequestFlightBooking.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(resetOfferRequestFlightBooking.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(resetOfferRequestFlightBooking.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as ResetOfferRequestFlightBookingErrorResponse
        state.success = null
      })
  }
})

export const { actions: resetOfferRequestFlightBookingActions, reducer: resetOfferRequestFlightBookingReducer } =
  resetOfferRequestFlightBookingSlice
export default resetOfferRequestFlightBookingSlice.reducer
