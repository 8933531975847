import React, { useEffect, useState } from 'react'

import { InfoRounded } from '@mui/icons-material'
import { Box, Grid, Typography } from '@mui/material'

import TripSummary from '../TripSummary/TripSummary.container'

import ShareFlightBookingLink from '../../shared/components/ShareFlightBookingLink/ShareFlightBookingLink.component'
import Stepper from '../../shared/components/Stepper/Stepper.component'
import FlightSearchWidget from '../Travel/components/FlightSearchWidget/FlightSearchWidget.component'
import OffersMultiSelect from './Stepper/OffersMultiSelect/OffersMultiSelect.component'
import TripSelector from './components/TripSelector/TripSelector.component'

import { FareOffer } from '../../redux/states/flights/getFlightFares/types/FareDetailsResponse.types'
import { OfferRequest } from '../../redux/states/offerRequest/getPublicOffers/getPublicOffers.types'
import { RootState } from '../../redux/store/store.types'
import router from '../../router/functions/router.functions'
import date, { dateTimeFormats } from '../../shared/functions/Date/date.functions'
import { useAppSelector } from '../../shared/hooks/redux.hooks'
import { RequiredCountries } from '../Offers/components/OfferItem/OfferItem.types'
import { FlightSearchInitialValues } from '../Travel/components/FlightSearchWidget/FlightSearchWidget.types'
import { formatFlightPartialOffers } from './FlightSearchResults.functions'
import styles from './FlightSearchResults.styles'
import { FormattedPartialOffers, Route } from './types/FormattedSearchResults.types'

const steps = ['Choose Flights', 'Select Offers', 'Trip Summary']

const FlightSearchResults: React.FC = () => {
  const { success: response, loading: isLoadingInitialFlightSearch } = useAppSelector(
    (state: RootState) => state.searchFlights
  )
  const [offers, setOffers] = useState<FormattedPartialOffers[]>([])
  const [routes, setRoutes] = useState<Route[]>([])
  const [activeStep, setActiveStep] = useState(0)
  const [fare, setFare] = useState<FareOffer>()
  const [publicOffers, setPublicOffers] = useState<OfferRequest[]>([])
  const { requiredCountries, selectedOffer } = router.getNavigationProps().state as {
    requiredCountries: RequiredCountries[]
    selectedOffer: OfferRequest
  }

  const { flightSearchInitialValues } = router.getNavigationProps().state as {
    flightSearchInitialValues: FlightSearchInitialValues
  }

  const [selectedOffers, setSelectedOffers] = useState<OfferRequest[]>([selectedOffer])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (response) {
      const { offers, routes } = formatFlightPartialOffers(response)

      setOffers(offers)
      setRoutes(routes)
    }
  }, [response])

  const handleStepClick = (step: number) => {
    if (step === 0) {
      setFare(undefined)
    }
    setActiveStep(step)
  }
  const searchFlightConfig = React.useMemo(() => {
    if (!requiredCountries || requiredCountries.length < 2) return null

    const originCountry = requiredCountries.find((country) => !country.neededByDate)
    const destinationCountry = requiredCountries.find((country) => country.neededByDate)

    if (!originCountry || !destinationCountry) return null

    return {
      origin: originCountry.name,
      destination: `${destinationCountry.city || ''}, ${destinationCountry.name}`.trim(),
      neededByDate: destinationCountry.neededByDate
        ? date(destinationCountry.neededByDate).format(dateTimeFormats.date.medium)
        : null,
      neededByDateISO: destinationCountry.neededByDate
    }
  }, [requiredCountries])

  const onFetchedOffers = (offers: OfferRequest[]) => {
    setPublicOffers(offers)
  }

  const handleComplete = (fare: FareOffer) => {
    setFare(fare)
    if (publicOffers.length > 1) {
      setActiveStep(1)
    } else {
      setActiveStep(2)
    }
  }

  const handleGotoTripSummary = (selectedOffers: OfferRequest[]) => {
    setSelectedOffers(selectedOffers)
    setActiveStep(2)
  }

  if (!requiredCountries) {
    return (
      <Grid container sx={styles.container}>
        <FlightSearchWidget isCompact={true} initialValues={flightSearchInitialValues} />

        <Box sx={styles.tripSelectorContainer}>
          <Grid item xs={12} sm={9}>
            <TripSelector
              isLoadingInitialFlightSearch={isLoadingInitialFlightSearch}
              initialBaseOfferId={response?.data.id}
              offers={offers}
              routes={routes}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <ShareFlightBookingLink />
          </Grid>
        </Box>
      </Grid>
    )
  } else {
    return (
      <Grid container sx={styles.container}>
        {requiredCountries && activeStep === 0 && (
          <FlightSearchWidget
            searchFlightConfig={searchFlightConfig}
            isCompact={true}
            requiredCountries={requiredCountries}
          />
        )}

        <Stepper
          stepperWidth="50%"
          sx={styles.stepper}
          activeStep={activeStep}
          steps={steps.map((step) => ({ label: step }))}
          showStepper
          showLeftArrow={true}
          showRightArrow={activeStep === 0 ? fare !== undefined : true}
          onStepClick={handleStepClick}
        >
          <Box sx={{ display: activeStep === 0 ? 'block' : 'none' }}>
            {(response || isLoadingInitialFlightSearch) && (
              <Grid sx={styles.tripSelectorContainer} item xs={12} sm={12}>
                <TripSelector
                  isLoadingInitialFlightSearch={isLoadingInitialFlightSearch}
                  initialBaseOfferId={response?.data.id}
                  offers={offers}
                  routes={routes}
                  onComplete={handleComplete}
                />
              </Grid>
            )}

            {!response && !isLoadingInitialFlightSearch && (
              <Box sx={styles.requiredFlightsContainer}>
                <Box sx={styles.requiredFlightsItemsContainer}>
                  <Box sx={styles.iconContainer}>
                    <InfoRounded sx={styles.infoIcon} />
                  </Box>

                  <Typography variant="h5" sx={styles.requiredFlightsTitle}>
                    Search flights above
                  </Typography>

                  {searchFlightConfig && (
                    <Typography sx={styles.requiredFlightsSubtitle}>
                      You need to have a flight from <span style={styles.linkText}>{searchFlightConfig.origin}</span> to{' '}
                      <span style={styles.linkText}>{searchFlightConfig.destination}</span> arriving by{' '}
                      <span style={styles.linkText}>{searchFlightConfig.neededByDate}</span>.
                    </Typography>
                  )}
                </Box>
              </Box>
            )}
          </Box>

          <Box sx={{ display: activeStep === 1 ? 'block' : 'none' }}>
            <OffersMultiSelect
              initialSelectedOffers={selectedOffers}
              requiredCountries={requiredCountries}
              onGotoTripSummary={handleGotoTripSummary}
              onFetchedOffers={onFetchedOffers}
              maxDate={searchFlightConfig?.neededByDateISO}
            />
          </Box>

          {fare && (
            <Box sx={{ display: activeStep === 2 ? 'block' : 'none' }}>
              <TripSummary
                type="tripSummary"
                tripFare={fare}
                selectedOffers={selectedOffers}
                props={{}}
                key={fare.id}
              />
            </Box>
          )}
        </Stepper>
      </Grid>
    )
  }
}

export default FlightSearchResults
