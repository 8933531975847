import UploadIcon from '@mui/icons-material/Upload'

import images from '../../assets/images'

const { CursorIcon, CartIcon, ChecklistIcon } = images

const dictionary = {
  pageBanner: {
    title: 'Save 50% Instantly on Flights. Carry Items for Friends and Family.',
    subtitle: 'Book your flight for less by accepting to courrier items for family and friends of friends.',
    link: {
      text: 'How it works?',
      url: '#'
    }
  },

  howToTravelAndEarn: {
    title: 'How it works',
    subtitle: 'In 4 simple steps, you can have your packages at your door.',
    steps: [
      {
        icon: <CursorIcon />,
        title: 'Paste Amazon URL',
        description: 'Paste Amazon link in Hourrier'
      },

      {
        icon: <UploadIcon />,
        title: 'Submit Order',
        description: 'Submit your Order and pay securely.'
      },

      {
        icon: <ChecklistIcon />,
        title: 'Get Offer Accepted',
        description: 'Hourrier will match you with a Traveler'
      },

      {
        icon: <CartIcon />,
        title: 'Receive Item',
        description: 'Receive item from Traveler'
      }
    ]
  }
}

export default dictionary
