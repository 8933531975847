import React from 'react'

import StatusUpdatePage from '../../shared/components/StatusUpdatePage/StatusUpdatePage.component'

import { verifyEmail } from '../../redux/states/auth/verifyEmail/verifyEmail.slice'
import { VerifyEmailRequest } from '../../redux/states/auth/verifyEmail/verifyEmail.types'
import router from '../../router/functions/router.functions'
import routes from '../../router/routes.dictionary'
import reactHooks from '../../shared/hooks/react.hooks'
import { useAppDispatch } from '../../shared/hooks/redux.hooks'

const { useOnceEffect } = reactHooks

const SignUpVerification: React.FC = () => {
  const emailToken = router.getUrlParam('emailToken')
  const dispatch = useAppDispatch()
  const navigate = router.navigate()

  useOnceEffect(() => {
    if (emailToken) {
      const verifyEmailRequest: VerifyEmailRequest = { verificationToken: emailToken }

      const onSuccess = () => {
        navigate(routes.login.path, { replace: true })
      }

      dispatch(verifyEmail({ verifyEmailRequest, onSuccess }))
    }
  })

  return (
    <StatusUpdatePage
      title="Verifying your email..."
      subtitle="Please wait while we verify your email address."
      status="fetching"
    />
  )
}

export default SignUpVerification
