import { SxProps } from '@mui/material'

import theme from '../../styles/themes/default.theme'

const styles = {
  container: (size: number, isName: boolean) =>
    ({
      height: size,
      width: size,
      marginRight: isName ? theme.spacing(2) : 0,
      fontSize: size / 2
    }) as SxProps,

  personIcon: (size: number) =>
    ({
      fontSize: size / 1.5
    }) as SxProps
}

export default styles
