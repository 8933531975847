import React from 'react'

import { Box, Typography } from '@mui/material'

import { styles } from './MainPageSection.styles'
import { MainPageSectionProps } from './MainPageSection.types'

const MainPageSection: React.FC<MainPageSectionProps> = (props) => {
  const { title, subtitle, children, headerAlignment = 'left' } = props

  return (
    <Box sx={styles.container}>
      <Typography variant="h2" sx={styles.title(headerAlignment)}>
        {title
          .split(' ')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ')}
      </Typography>

      <Typography variant="h5" sx={styles.subtitle(headerAlignment)}>
        {subtitle}
      </Typography>

      <Box sx={styles.childrenContainer}>{children}</Box>
    </Box>
  )
}

export default MainPageSection
