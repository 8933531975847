import React from 'react'

import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { Box, Link, Typography } from '@mui/material'

import Button from '../../../../shared/components/Button/Button.component'
import PriceDisplay from '../Price/Price.component'

import date from '../../../../shared/functions/Date/date.functions'
import styles from './ItemDetails.styles'
import { ItemDetailsProps } from './ItemDetails.types'

const ItemDetails: React.FC<ItemDetailsProps> = ({ item, updateQuantity }) => {
  const handleIncrease = () => {
    updateQuantity(item.quantity + 1)
  }

  const handleDecrease = () => {
    if (item.quantity > 1) {
      updateQuantity(item.quantity - 1)
    }
  }

  const getItemQuantityButtons = () => {
    if (item.stockStatus === 'In Stock') {
      return (
        <Box sx={styles.quantityControl}>
          <Button
            onClick={handleDecrease}
            text=""
            iconPosition="center"
            icon={<RemoveIcon />}
            buttonType="primary"
            disabled={item.quantity === 1}
            style={styles.quantityButton}
          />

          <Typography sx={styles.quantityText}>{item.quantity}</Typography>

          <Button
            onClick={handleIncrease}
            text=""
            iconPosition="center"
            icon={<AddIcon />}
            buttonType="primary"
            style={styles.quantityButton}
          />
        </Box>
      )
    } else {
      return <Typography color="error">Out of Stock</Typography>
    }
  }

  return (
    <Box sx={styles.container}>
      <img src={item.images[0]} alt={item.title} style={styles.image} />

      <Box sx={styles.details}>
        <Typography variant="h6" sx={styles.title}>
          {item.title}
        </Typography>

        <Box sx={styles.priceQuantityContainer}>
          <Box sx={styles.priceContainer}>
            <PriceDisplay price={item.price} />

            <Typography variant="caption" sx={styles.priceValidText}>
              Amazon price: As of {date(item.priceAt).format('d/MM/yyyy')} -{' '}
              <Link sx={styles.priceValidLink} href={item.internalUrl} target="_blank">
                Details
              </Link>
            </Typography>
          </Box>

          {getItemQuantityButtons()}
        </Box>
      </Box>
    </Box>
  )
}

export default ItemDetails
