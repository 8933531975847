import React from 'react'

import Button from '../../../../shared/components/Button/Button.component'

import images from '../../../../assets/images'
import styles from './OrderSubmittedSuccessfully.styles'
import { OrderSubmittedSuccessfullyProps } from './OrderSubmittedSuccessfully.types'

const { OrderSubmittedCheckmarkIcon } = images

export const OrderSubmittedSuccessfully: React.FC<OrderSubmittedSuccessfullyProps> = ({ onViewOrders }) => {
  return (
    <div style={styles.container}>
      <div style={styles.iconContainer}>
        <OrderSubmittedCheckmarkIcon style={styles.icon} />
      </div>

      <h2 style={styles.title}>Order Submitted Successfully</h2>

      <p style={styles.description}>
        Your order was successfully submitted as a offer to our Hourriers. Once accepted, you will receive an email
        notification.
      </p>

      {onViewOrders && <Button buttonType="primary" text="View My Orders" onClick={onViewOrders} large={true} />}
    </div>
  )
}
