import { SxProps } from '@mui/material'

import theme from '../../shared/styles/themes/default.theme'

const styles = {
  container: {
    marginTop: theme.spacing(-30)
  } as SxProps
}

export default styles
