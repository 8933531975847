import theme from '../../styles/themes/default.theme'

const styles = {
  dialog: {
    '.MuiDialogContent-root': {
      padding: theme.spacing(5)
    },
    '.MuiDialogActions-root': {
      padding: theme.spacing(0),
      paddingTop: theme.spacing(5)
    }
  },

  title: {
    color: theme.palette.grey[800],
    textAlign: 'left',
    fontWeight: '500',
    marginBottom: theme.spacing(1)
  },

  subtitle: {
    color: theme.palette.grey[500],
    textAlign: 'left',
    fontWeight: '400'
  }
}

export default styles
