import { FlightBooking, FlightLeg } from '../../redux/states/flights/getAllFlightBookings/getAllFlightBookings.types'
import { TripType } from '../Travel/components/FlightSearchWidget/FlightSearchWidget.types'

export const determineTripType = (flightLegs: FlightLeg[]): TripType => {
  if (flightLegs.length === 1) {
    return 'oneWay'
  }

  const firstLeg = flightLegs[0]
  const lastLeg = flightLegs[flightLegs.length - 1]

  if (
    flightLegs.length === 2 &&
    firstLeg.flightDetails.destination.airport.iataCode === lastLeg.flightDetails.origin.airport.iataCode
  ) {
    return 'roundTrip'
  }

  return 'multiCity'
}

export const getFormattedTripDetails = (flightBooking: FlightBooking): string => {
  const firstLeg = flightBooking.flightLegs[0]

  const tripType = determineTripType(flightBooking.flightLegs)
  let tripDetails = ''

  switch (tripType) {
    case 'oneWay':
      tripDetails = `${firstLeg.flightDetails.origin.airport.iataCode} → ${firstLeg.flightDetails.destination.airport.iataCode}`
      break

    case 'roundTrip':
      tripDetails = `${firstLeg.flightDetails.origin.airport.iataCode} ⇌ ${firstLeg.flightDetails.destination.airport.iataCode}`
      break

    case 'multiCity':
      tripDetails = flightBooking.flightLegs
        .map(
          (leg) => `${leg.flightDetails.origin.airport.iataCode} → ${leg.flightDetails.destination.airport.iataCode}`
        )
        .join('\u00A0 • \u00A0')
      break
  }

  return tripDetails
}
