import theme from '../../../styles/themes/default.theme'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    borderRadius: '16px',
    boxShadow: theme.shadows[3],
    backgroundColor: theme.palette.background.paper,
    width: 'auto',
    height: 'auto',
    textAlign: 'center',
    position: 'relative'
  },
  userInfoPill: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    textAlign: 'left',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '50px',
    padding: theme.spacing(1),
    boxShadow: theme.shadows[2],
    width: '70%',
    [theme.breakpoints.down('sm')]: {
      width: '90%'
    },
    position: 'absolute',
    top: theme.spacing(3),
    left: theme.spacing(3),
    transform: 'scale(1)'
  },
  userImage: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    marginRight: theme.spacing(1)
  },
  userName: {
    fontWeight: 'bold'
  },
  location: {
    color: theme.palette.text.secondary
  },
  image: {
    width: '100%',
    minHeight: 'auto',
    maxWidth: '250px',
    maxHeight: '300px',
    borderRadius: '8px',
    marginBottom: theme.spacing(2)
  },
  carrigeInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
  itemsCount: {
    marginBottom: theme.spacing(1),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[800]
  },
  discount: {
    backgroundColor: '#FCD34D',
    color: theme.palette.common.black,
    fontWeight: 'bold',
    padding: theme.spacing(0.5, 1),
    borderRadius: '4px',
    marginBottom: theme.spacing(2)
  },

  spacer: {
    flex: 1
  },

  buttonContainer: {
    width: '100%'
  },
  bookButton: {
    backgroundColor: 'rgba(109, 40, 217, 0.04)',
    textTransform: 'none'
  }
}

export default styles
