import _ from 'lodash'

import { SearchFlightsSuccessResponse } from '../../redux/states/flights/searchFlights/searchFlights.types'
import { formatDuration, formatTime } from '../../shared/functions/Date/date.functions'
import { FormattedPartialOffers } from './types/FormattedSearchResults.types'
import { Route } from './types/FormattedSearchResults.types'

export const formatFlightPartialOffers = (
  flightSearchResults: SearchFlightsSuccessResponse
): { offers: FormattedPartialOffers[]; routes: Route[] } => {
  if (!flightSearchResults.data.offers) {
    return { offers: [], routes: [] }
  }

  const offers: FormattedPartialOffers[] = flightSearchResults.data.offers.map((offer) => {
    return {
      id: offer.id,
      slices: offer.slices.map((slice) => {
        return {
          id: slice.id,
          totalDuration: formatDuration(slice.duration),
          origin: slice.origin,
          destination: slice.destination,
          segments: slice.segments.map((segment) => ({
            id: segment.id,
            duration: formatDuration(segment.duration),
            distance: segment.distance,
            flightNumber: segment.operating_carrier_flight_number,
            origin: segment.origin,
            destination: segment.destination,
            departure: {
              at: segment.departing_at,
              time: formatTime(segment.departing_at)
            },
            arrival: {
              at: segment.arriving_at,
              time: formatTime(segment.arriving_at)
            }
          })),
          totalAmount: offer.total_amount,
          currency: offer.total_currency,
          airlineLogo: offer.owner.logo_symbol_url,
          travelClass: _.capitalize(flightSearchResults.data.cabin_class)
        }
      }),
      totalAmount: offer.total_amount,
      currency: offer.total_currency,
      airlineLogo: offer.owner.logo_symbol_url,
      airline: offer.owner.name,
      travelClass: _.capitalize(flightSearchResults.data.cabin_class)
    }
  })

  const routes = flightSearchResults.data.slices.map((slice) => ({
    origin: slice.origin,
    destination: slice.destination
  }))

  return { offers: offers, routes }
}
