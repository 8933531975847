import { JSX } from 'react'

import { Route, Routes, useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import RedirectHandler from '../components/RedirectHandler.component'

import { Route as HourrierRoute } from '../router.types'
import routes from '../routes.dictionary'

const generateRoutes = (customRoutes: HourrierRoute[]): JSX.Element => {
  const routes = customRoutes.map((route) => (
    <Route key={route.path} path={route.path} element={<RedirectHandler route={route} />} />
  ))

  return <Routes>{routes}</Routes>
}

const useCustomNavigate = () => {
  const navigate = useNavigate()

  const customNavigate = (path: string, options?: { replace?: boolean; state?: unknown }, isRelativePath?: boolean) => {
    const pathToNavigate = isRelativePath ? path : `/${path}`

    // TODO: Add custom logic here if needed

    if (path === '/') {
      navigate(routes.home.path, options)
    } else {
      navigate(pathToNavigate, options)
    }
  }

  return customNavigate
}

const useCurrentPage = (): HourrierRoute => {
  const location = useLocation()
  const currentRoute = Object.values(routes).find((route) => `/${route.path}` === location.pathname)

  return currentRoute ?? routes.home
}

const useSearchParamsHook = (searchQuery: string) => {
  const [params] = useSearchParams()
  const value = params.get(searchQuery)
  const sanitizedValue = value?.replace(/^\s+/, '')

  return sanitizedValue ?? ''
}

// New wrapper for useLocation
const useCustomLocation = () => {
  const location = useLocation()

  return location
}

const router = {
  navigate: useCustomNavigate,
  handleRedirects: null,
  generateRoutes,
  getCurrentPage: useCurrentPage,
  getUrlParam: useSearchParamsHook,
  getNavigationProps: useCustomLocation
}

export default router
