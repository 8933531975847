import * as React from 'react'

import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'

import Button from '../Button/Button.component'

import { CustomizedSnackbarProps, SnackbarAction } from './Snackbar.types'

const snackbarAction = (action: SnackbarAction) => (
  <React.Fragment>
    <Button buttonType="tertiary" text={action.label?.toUpperCase()} onClick={action.onClick} isNav />
  </React.Fragment>
)

const CustomizedSnackbar: React.FC<CustomizedSnackbarProps> = ({
  message,
  action,
  severity,
  autoHideDuration,
  open,
  onClose
}) => {
  return (
    <div>
      <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={onClose}>
        <Alert
          action={action && snackbarAction(action)}
          onClose={onClose}
          severity={severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default CustomizedSnackbar
