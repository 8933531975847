import React, { useEffect, useState } from 'react'

import { Box, Divider, Typography } from '@mui/material'

import Price from '../Price/Price.component'

import { CountryTaxMap } from '../../../../redux/states/firebaseRemoteConfig/firebaseRemoteConfig.types'
import { RootState } from '../../../../redux/store/store.types'
import { useAppSelector } from '../../../../shared/hooks/redux.hooks'
import styles from './Overview.styles'
import { OverviewProps } from './Overview.types'

const Overview: React.FC<OverviewProps> = ({ items, showTax = false, orderCountries }) => {
  const { values } = useAppSelector((state: RootState) => state.firebaseRemoteConfig)
  const hourrierFees = Number(values?.hourrierFee) || 0

  const countryTaxMap = useAppSelector(
    (state: RootState) => state.firebaseRemoteConfig.values.countryTaxMap as CountryTaxMap
  )
  const [taxRate, setTaxRate] = useState(0)

  const totalItems = items
    .filter((item) => item.stockStatus === 'In Stock')
    .reduce((sum, item) => sum + item.quantity, 0)

  const subtotal = items
    .filter((item) => item.stockStatus === 'In Stock')
    .reduce((sum, item) => sum + item.price * item.quantity, 0)

  const tax = showTax ? subtotal * (taxRate / 100) : 0

  const total = subtotal === 0 ? 0 : subtotal + hourrierFees + (showTax ? tax : 0)

  useEffect(() => {
    if (orderCountries && countryTaxMap && orderCountries.length > 0) {
      const taxInfo = countryTaxMap[orderCountries[0].iataCode]

      if (taxInfo && typeof taxInfo.standard_rate === 'number') {
        setTaxRate(taxInfo.standard_rate)
      } else {
        setTaxRate(0)
      }
    } else {
      setTaxRate(0)
    }
  }, [countryTaxMap, orderCountries])

  return (
    <Box sx={styles.container}>
      <Typography variant="h6" sx={styles.title}>
        Overview
      </Typography>

      <Divider sx={styles.divider} />

      <Box sx={styles.row}>
        <Typography variant="h6">Total</Typography>
      </Box>

      <Box sx={styles.row}>
        <Typography sx={styles.fieldText}>
          {totalItems} item{totalItems > 1 ? 's' : ''}
        </Typography>

        <Typography sx={styles.fieldText}>US${subtotal.toFixed(2)}</Typography>
      </Box>

      <Box sx={styles.row}>
        <Typography sx={styles.fieldText}>Hourrier Fees</Typography>
        <Typography sx={styles.fieldText}>US${hourrierFees.toFixed(2)}</Typography>
      </Box>

      {showTax && (
        <Box sx={styles.row}>
          <Typography sx={styles.fieldText}>Tax ({taxRate.toFixed(2)}%)</Typography>
          <Typography sx={styles.fieldText}>US${tax.toFixed(2)}</Typography>
        </Box>
      )}

      <Divider sx={styles.divider} />

      <Box sx={styles.row}>
        <Typography variant="h6" sx={styles.amountToPay}>
          Amount to pay
        </Typography>

        <Price showSeparator={true} showCurrency={true} superscript={false} price={total} />
      </Box>
    </Box>
  )
}

export default Overview
