import React from 'react'

import { Container, Typography } from '@mui/material'
import * as Yup from 'yup'

import Form from '../../shared/components/Form/Form.component'
import Link from '../../shared/components/Link/Link.component'

import { forgetPassword } from '../../redux/states/auth/forgetPassword/forgetPassword.slice'
import { ForgetPasswordRequest } from '../../redux/states/auth/forgetPassword/forgetPassword.types'
import router from '../../router/functions/router.functions'
import routes from '../../router/routes.dictionary'
import { FormField } from '../../shared/components/Form/Form.types'
import { logEvent } from '../../shared/functions/Logger/logger.functions'
import { useAppDispatch, useAppSelector } from '../../shared/hooks/redux.hooks'
import styles from './ForgetPassword.styles'
import { ForgetPasswordFormValues } from './ForgetPassword.types'

const ForgetPassword: React.FC = () => {
  const dispatch = useAppDispatch()
  const { loading } = useAppSelector((state) => state.forgetPassword)
  const navigate = router.navigate()

  const initialValues: ForgetPasswordFormValues = {
    email: ''
  }

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Required')
  })

  const onSubmit = (values: ForgetPasswordFormValues) => {
    const forgetPasswordRequest: ForgetPasswordRequest = {
      email: values.email
    }

    const onSuccess = () => {
      navigate(routes.forgetPasswordSuccess.path)
    }

    dispatch(forgetPassword({ forgetPasswordRequest, onSuccess }))
    logEvent('forget_password_button_clicked', {
      email: values.email
    })
  }

  const formFields: FormField[] = [
    {
      name: 'email',
      label: 'Email Address',
      type: 'email',
      autoComplete: 'email',
      placeholder: 'john.doe@example.com',
      grid: { xs: 12 },
      required: true,
      fullWidth: true
    }
  ]

  return (
    <Container component="main" maxWidth="sm" sx={styles.container}>
      <Form
        title="Forget Password"
        subtitle="No worries. Enter the email address you used to create the account to continue."
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values: unknown) => onSubmit(values as ForgetPasswordFormValues)}
        formFields={formFields}
        loading={loading}
        buttonText="Reset Password"
      />

      <Typography sx={styles.forgetPasswordTextContainer}>
        <Typography sx={styles.forgetPasswordText}>You remember your password?</Typography>

        <Typography sx={styles.forgetPasswordText}>
          <Link href={routes.signUp.path} ml={0.5} showColor>
            Sign In
          </Link>
        </Typography>
      </Typography>
    </Container>
  )
}

export default ForgetPassword
