const urls = {
  auth: {
    signUp: 'auth/sign-up',
    verifyEmail: 'auth/verify-email',
    login: 'auth/login',
    logout: 'auth/logout',
    changePassword: 'auth/change-password',
    forgetPassword: 'auth/forget-password',
    resetForgottenPassword: 'auth/forget-password/reset'
  },
  profile: {
    getProfile: 'profile',
    updatePersonalInfo: 'profile/personal-info/update',
    updateContactInfo: 'profile/contact-info/update',
    updateDeliveryAddress: 'profile/delivery-address/update',
    addDeliveryAddress: 'profile/delivery-address/add',
    deleteDeliveryAddress: 'profile/delivery-address/delete',
    addLoyaltyProgram: 'profile/loyalty-program/add',
    updateLoyaltyProgram: 'profile/loyalty-program/update',
    deleteLoyaltyProgram: 'profile/loyalty-program/delete',
    deliveryAddress: 'profile/deliveryAddress'
  },
  flights: {
    getFlightBooking: 'flights/booking/view',
    getFlightPlaces: 'flights/places',
    getCountriesAndCities: 'flights/places/countries-and-cities',
    search: 'flights/search',
    searchNext: 'flights/search/next',
    getFlightFares: 'flights/search/fare',
    getAllFlightBookings: 'flights/booking/view-all',
    bookFlight: 'flights/booking/create',
    getFlightBookingShareLink: 'flights/booking/share-link'
  },
  supportedLoyaltyPrograms: {
    getSupportedLoyaltyPrograms: 'supported-loyalty-program/view-all'
  },
  items: {
    getItemDetails: 'items/details',
    changes: 'items/changes',
    acceptItemPriceChange: 'items/price-change/accept'
  },
  publicOffers: {
    getPublicOffers: 'offers/offer-requests/view-all',
    acceptPublicOffer: 'offers/offer-requests/accept'
  },
  offers: {
    createOfferRequest: 'offers/offer-requests/create',
    viewOfferRequests: 'offers/offer-requests/view',
    acceptItemRequest: 'offers/item-requests/accept',
    denyItemRequest: 'offers/item-requests/deny',
    cancelItemRequest: 'offers/item-requests/cancel',
    resetOfferRequestFlightBooking: 'offers/offer-requests/reset-flight-booking',
    reassignItemRequest: 'offers/item-requests/reassign',
    getItemRequestTrackingDetails: 'offers/item-requests/tracking-details/get',
    updateItemRequestTrackingDetails: 'offers/item-requests/tracking-details/update',
    addItemTrackingNumber: 'offers/item-requests/external-tracking-details/add-tracking-number'
  }
}

export default urls
