import React from 'react'

import { Box, Divider, Modal, Typography } from '@mui/material'

import Button from '../../../../../shared/components/Button/Button.component'
import PassengerSelection from '../PassengerSelection/PassengerSelection.component'

import styles from './PassengerSelectionModal.styles'
import { PassengerSelectionModalProps } from './PassengerSelectionModal.types'

const PassengerSelectionModal: React.FC<PassengerSelectionModalProps> = ({
  open,
  onClose,
  adults,
  children,
  childrenAges,
  setAdults,
  setChildren,
  setChildrenAges
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={styles.modalContainer}>
        <Typography variant="h6">Select Passengers</Typography>

        <Box sx={styles.passengerSelectionContainer}>
          <PassengerSelection
            adults={adults}
            children={children}
            childrenAges={childrenAges}
            setAdults={setAdults}
            setChildren={setChildren}
            setChildrenAges={setChildrenAges}
          />
        </Box>

        <Divider sx={styles.divider} />

        <Button onClick={onClose} buttonType="tertiary" text="Close" sx={styles.closeButton} />
      </Box>
    </Modal>
  )
}

export default PassengerSelectionModal
