import React from 'react'

import { Box, Container, Divider, Typography } from '@mui/material'
import _ from 'lodash'
import * as Yup from 'yup'

import Form from '../../shared/components/Form/Form.component'
import Link from '../../shared/components/Link/Link.component'

import { login } from '../../redux/states/auth/login/login.slice'
import { LoginRequest } from '../../redux/states/auth/login/login.types'
import router from '../../router/functions/router.functions'
import routes from '../../router/routes.dictionary'
import { FormField } from '../../shared/components/Form/Form.types'
import localStorage from '../../shared/functions/LocalStorage/localStorage'
import { logEvent } from '../../shared/functions/Logger/logger.functions'
import { UserRole } from '../../shared/functions/UserRole/userRoleManagement.types'
import { getUserRole } from '../../shared/functions/UserRole/userRoleManagment.functions'
import { useAppDispatch, useAppSelector } from '../../shared/hooks/redux.hooks'
import { CART_STORAGE_KEY, FLIGHT_BOOKING_ID_STORAGE_KEY, NAVIGATE_TO_CART_DELIVERY_KEY } from '../Cart/Cart.dictionary'
import styles from './Login.styles'
import { LoginFormValues } from './Login.types'

const Login: React.FC = () => {
  const dispatch = useAppDispatch()
  const { loading } = useAppSelector((state) => state.login)
  const navigate = router.navigate()

  const initialValues: LoginFormValues = {
    email: '',
    password: ''
  }

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Required'),
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters long')
      .matches(/[A-Z]/, 'Password must contain one uppercase letter')
      .matches(/[a-z]/, 'Password must contain one lowercase letter')
      .matches(/[0-9]/, 'Password must contain one number')
      .required('Required')
  })

  const onSubmit = (values: LoginFormValues) => {
    const loginRequest: LoginRequest = {
      email: values.email,
      password: values.password
    }

    const onSuccess = () => {
      const userRole = getUserRole()
      const storedCart = localStorage.getItem(CART_STORAGE_KEY)
      const shouldRedirectToCart = !_.isEmpty(storedCart) && userRole === UserRole.shopper
      const shouldRedirectToTripSummary =
        localStorage.getItem('shouldRedirectToTripSummary') && userRole === UserRole.traveler

      if (shouldRedirectToCart) {
        const flightBookingId = localStorage.getItem(FLIGHT_BOOKING_ID_STORAGE_KEY)

        navigate(routes.cart.path, {
          state: {
            cart: storedCart,
            shouldNavigateToDelivery: localStorage.getItem(NAVIGATE_TO_CART_DELIVERY_KEY),
            flightBookingId
          }
        })

        localStorage.removeItem(CART_STORAGE_KEY)
        localStorage.removeItem(NAVIGATE_TO_CART_DELIVERY_KEY)
        localStorage.removeItem(FLIGHT_BOOKING_ID_STORAGE_KEY)
      } else if (shouldRedirectToTripSummary) {
        const fareOffer = localStorage.getItem('fareOffer') || '{}'

        localStorage.removeItem('shouldRedirectToTripSummary')
        localStorage.removeItem('fareOffer')

        navigate(routes.tripSummary.path, {
          state: { fare: fareOffer }
        })
      } else {
        switch (userRole) {
          case UserRole.shopper:
            navigate(routes.shop.path, { replace: true })
            break

          default:
            navigate(routes.home.path, { replace: true })
        }
      }
    }

    dispatch(login({ loginRequest, onSuccess }))

    logEvent('login_button_clicked', {
      email: values.email
    })
  }

  const formFields: FormField[] = [
    {
      name: 'email',
      label: 'Email Address',
      type: 'email',
      autoComplete: 'email',
      placeholder: 'john.doe@example.com',
      grid: { xs: 12 },
      required: true,
      fullWidth: true
    },
    {
      name: 'password',
      label: 'Password',
      type: 'password',
      autoComplete: 'current-password',
      grid: { xs: 12 },
      required: true,
      fullWidth: true
    }
  ]

  return (
    <Container component="main" maxWidth="sm" sx={styles.container}>
      <Form
        title="Start Booking Today"
        subtitle="You could earn big on your next trip"
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values: unknown) => onSubmit(values as LoginFormValues)}
        formFields={formFields}
        loading={loading}
        buttonText="Log In"
      />

      <Box sx={styles.alternativeActionsContainer}>
        <Typography sx={styles.loginTextContainer}>
          <Typography sx={styles.loginText}>Don't have an account already?</Typography>

          <Typography sx={styles.loginText}>
            <Link href={routes.signUp.path} ml={0.5} showColor>
              Sign Up
            </Link>
          </Typography>
        </Typography>

        <Typography sx={styles.loginText}>
          <Link href={routes.forgetPassword.path} ml={0.5} showColor>
            Forgot Password?
          </Link>
        </Typography>
      </Box>

      <Box sx={styles.termsContainer}>
        <Divider sx={styles.divider} />

        <Typography sx={styles.termsTextContainer}>
          <Typography sx={styles.termsText}>By logging in, you agree to our </Typography>

          <Typography sx={styles.termsText}>
            <Link href={routes.termsOfServices.path} ml={0.5} mr={0.5} showColor isUnderlined>
              Terms of Service
            </Link>
          </Typography>

          <Typography sx={styles.termsText}>and</Typography>

          <Typography sx={styles.termsText}>
            <Link href={routes.privacyPolicy.path} ml={0.5} mr={0.5} showColor isUnderlined>
              Privacy Policy.
            </Link>
          </Typography>
        </Typography>
      </Box>
    </Container>
  )
}

export default Login
