import _ from 'lodash'

import { logEvent } from '../Logger/logger.functions'

export default function wrapperLocalStorage() {
  return {
    /**
     * Stores data to local storage.
     * @param {string} key The key that is to be added to local storage
     * @param {string|object} value The value that is to be added to local storage
     * @param {function} onSuccess A function that is to be called on success
     */
    setItem: (key: string, value: unknown, onSuccess?: () => void) => {
      if (_.isEmpty(key)) {
        logEvent('Error_Setting_Empty_Local_Storage_Key')
      } else {
        try {
          localStorage.setItem(key, JSON.stringify(value))
          onSuccess && onSuccess()
        } catch (error: unknown) {
          logEvent(`Error_Setting_Local_Storage_Value_${key}`)
        }
      }
    },

    /**
     * Retrieves data from local storage.
     * @param {string} key The key that is to be searched for in local storage
     * @returns {string|object}
     */
    getItem: (key: string) => {
      try {
        const value = localStorage.getItem(key)
        let storageValue = null

        if (value !== null) {
          storageValue = JSON.parse(value)
        }

        return storageValue
      } catch (error: unknown) {
        logEvent(`Error_Getting_Local_Storage_Value_${key}`)
      }
    },

    /**
     * Removes data from local storage.
     * @param {string} key The key that is to be searched for in local storage
     */
    removeItem: (key: string) => {
      try {
        localStorage.removeItem(key)
      } catch (error: unknown) {
        logEvent(`Error_Deleting_Local_Storage_Value_${key}`)
      }
    }
  }
}
