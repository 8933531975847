import { CSSProperties } from 'react'

import theme from '../../../../shared/styles/themes/default.theme'

const styles = {
  emptyStateContainer: {
    padding: theme.spacing(1, 0)
  } as CSSProperties,

  accordionHeaderMainContent: {
    textAlign: 'left'
  } as CSSProperties,

  shopperDetailsContainer: {
    padding: theme.spacing(1, 2),
    borderRadius: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.grey[100],
    marginBottom: theme.spacing(2)
  } as CSSProperties,

  shopperDetailsTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  } as CSSProperties,

  shopperDetailsName: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.body1.fontWeight,
    textAlign: 'left'
  } as CSSProperties,

  shopperDetailsTag: {
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.caption.fontWeight,
    textAlign: 'left'
  } as CSSProperties
}

export default styles
