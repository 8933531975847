import { CSSProperties } from 'react'

import theme from '../../shared/styles/themes/default.theme'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: theme.palette.grey[50],
    width: '100%'
  },

  stepper: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(5)
  },

  tripSelectorContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: theme.palette.grey[50],
    padding: theme.spacing(5, 10),
    width: '100%'
  },

  requiredFlightsTitle: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[800]
  },

  requiredFlightsSubtitle: {
    color: theme.palette.grey[500],
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: '28px'
  },

  shareFlightBookingLinkContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%'
  },

  requiredFlightsContainer: {
    // paddingTop: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    height: 'calc(100vh - 64px - 56px)'
  },

  requiredFlightsItemsContainer: {
    marginTop: theme.spacing(20)
  },

  iconContainer: {
    marginBottom: theme.spacing(2)
  },

  infoIcon: {
    height: '80px',
    width: '80px',
    color: theme.palette.secondary.main
  },

  infoText: {
    textAlign: 'center',
    color: theme.palette.grey[700]
  } as CSSProperties,

  linkText: {
    color: theme.palette.primary.main,
    textDecoration: 'none'
  } as CSSProperties
}

export default styles
