import React from 'react'

import Person from '@mui/icons-material/Person2Rounded'
import { Avatar as MUIAvatar } from '@mui/material'

import styles from './Avatar.styles'
import { AvatarProps, AvatarSize } from './Avatar.types'

const Avatar: React.FC<AvatarProps> = (props) => {
  const { firstName, lastName, size = AvatarSize.medium } = props
  const isName = !!(firstName && lastName) || !!firstName
  const initials =
    firstName && lastName ? (
      `${firstName[0]}${lastName[0]}`
    ) : firstName ? (
      `${firstName[0]}`
    ) : (
      <Person sx={styles.personIcon(size)} />
    )

  return (
    <MUIAvatar {...props} sx={styles.container(size, !!isName)}>
      {initials}
    </MUIAvatar>
  )
}

export default Avatar
