import { SxProps } from '@mui/material'

import theme from '../../shared/styles/themes/default.theme'

export default {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    minHeight: '100%'
  },

  pageContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    paddingBottom: theme.spacing(5)
  },

  filterSidebar: {},

  loader: {
    display: 'flex',
    justifyContent: 'center',
    my: 2
  },

  emptyStateContainer: {
    paddingTop: theme.spacing(3)
  },

  flightBookingList: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    paddingTop: theme.spacing(5)
  } as SxProps,

  flightBookingAccordion: (isLast: boolean) => ({
    marginBottom: isLast ? 0 : theme.spacing(3)
  }),

  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    marginBottom: theme.spacing(2),
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  } as SxProps,

  title: {
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '32px',
    textAlign: 'left',
    marginBottom: theme.spacing(1)
  } as SxProps,

  subtitle: {
    color: theme.palette.grey[500],
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: '16px'
  } as SxProps
}
