import { FlightBooking, FlightLeg } from '../../redux/states/flights/getAllFlightBookings/getAllFlightBookings.types'
import date, { dateTimeFormats } from '../../shared/functions/Date/date.functions'
import { joinStringWithBullet } from '../../shared/functions/String/string.functions'
import {
  CabinClass,
  TripType,
  cabinClassMapping
} from '../Travel/components/FlightSearchWidget/FlightSearchWidget.types'

export const determineTripType = (flightLegs: FlightLeg[]): TripType => {
  if (flightLegs.length === 1) {
    return 'oneWay'
  }

  const firstLeg = flightLegs[0]
  const lastLeg = flightLegs[flightLegs.length - 1]

  if (
    flightLegs.length === 2 &&
    firstLeg.flightDetails.destination.airport.iataCode === lastLeg.flightDetails.origin.airport.iataCode
  ) {
    return 'roundTrip'
  }

  return 'multiCity'
}

export const getFormattedTripDetails = (flightBooking: FlightBooking): string => {
  const firstLeg = flightBooking.flightLegs[0]

  const tripType = determineTripType(flightBooking.flightLegs)
  let tripDetails = ''

  switch (tripType) {
    case 'oneWay':
      tripDetails = `${firstLeg.flightDetails.origin.city.name} (${firstLeg.flightDetails.origin.airport.iataCode}) → ${firstLeg.flightDetails.destination.city.name} (${firstLeg.flightDetails.destination.airport.iataCode})`
      break

    case 'roundTrip':
      tripDetails = `${firstLeg.flightDetails.origin.city.name} (${firstLeg.flightDetails.origin.airport.iataCode}) ⇌ ${firstLeg.flightDetails.destination.city.name} (${firstLeg.flightDetails.destination.airport.iataCode})`
      break

    case 'multiCity':
      tripDetails = flightBooking.flightLegs
        .map(
          (leg) =>
            `${leg.flightDetails.origin.city.name} (${leg.flightDetails.origin.airport.iataCode}) → ${leg.flightDetails.destination.city.name} (${leg.flightDetails.destination.airport.iataCode})`
        )
        .join('\u00A0 • \u00A0')
      break
  }

  return tripDetails
}

export const getFormattedTravelerDateDetails = (flightBooking: FlightBooking): string => {
  const firstLeg = flightBooking.flightLegs[0]
  const lastLeg = flightBooking.flightLegs[flightBooking.flightLegs.length - 1]
  let dateDetails = ''
  const tripType = determineTripType(flightBooking.flightLegs)

  if (tripType === 'oneWay') {
    dateDetails = joinStringWithBullet([
      `Departing: ${date(firstLeg.flightDetails.departureTime).format(dateTimeFormats.date.medium)}`
    ]) as string
  }

  if (tripType === 'roundTrip') {
    dateDetails = joinStringWithBullet([
      `Departing: ${date(firstLeg.flightDetails.departureTime).format(dateTimeFormats.date.medium)}`,
      `Returning: ${date(lastLeg.flightDetails.departureTime).format(dateTimeFormats.date.medium)}`
    ]) as string
  }

  if (tripType === 'multiCity') {
    dateDetails = joinStringWithBullet([
      `Departing: ${date(firstLeg.flightDetails.departureTime).format(dateTimeFormats.date.medium)}`
    ]) as string
  }

  return dateDetails
}

export const getFormattedCabinClass = (cabinClass: CabinClass): string => {
  return cabinClassMapping[cabinClass]
}
