import { CSSProperties } from 'react'

import theme from '../../../../shared/styles/themes/default.theme'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2rem',
    borderRadius: '8px',
    width: '100%',
    marginTop: theme.spacing(5)
  } as CSSProperties,

  iconContainer: {
    backgroundColor: theme.palette.success.main,
    borderRadius: '100%',
    padding: '1rem',
    width: '80px',
    height: '80px'
  },

  icon: {
    width: '48px',
    height: '48px',
    color: 'white'
  },

  title: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginBottom: '1rem',
    textAlign: 'center',
    color: theme.palette.grey[900]
  } as CSSProperties,

  description: {
    fontSize: '1rem',
    color: theme.palette.grey[500],
    marginBottom: '1.5rem',
    textAlign: 'center'
  } as CSSProperties
}

export default styles
